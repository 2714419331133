import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import { BLOODGROUP, SEX, FORMAT_DATE_UNIXTS } from '../../../constants';
import { STATE_IN } from '../../../statesIndia';
import { OPTIONS_NATIONALITY } from '../../../nationalities';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  Muli: {
    normal: 'https://chainworksbucket.s3.ap-south-1.amazonaws.com/MuliFont/Muli-Regular.woff',
    bold: 'https://chainworksbucket.s3.ap-south-1.amazonaws.com/MuliFont/Muli-Bold.woff',
  },
  Sintony: {
    bold: 'https://db.onlinewebfonts.com/t/0704722187831ae1fb775341c9842851.woff',
  },
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-Italic.ttf',
  },
};

class DonorFormPdf {
  formData = {};

  constructor(formData) {
    const {
      ztccZone,
      donorBasicInfoDetails,
      hospitalName,
      donorAttachmentsDetails,
      donorMedicalReportsDetails: {
        basicMedicalInformation,
        hemoDynamicParameters,
        viralMarkerStatus,
        latestInvestigations,
        renalFunction,
        liverFunction,
        heartFunction,
        medicalHistory,
        handAssessment,
        imaging,
      },
      donorOrganRetrievalDetails,
      retrievedOrgans,
      anyEcdOrgans,
      ecdOrgans,
      ecdInformation,
      // ecdInfoDetails,
      // ecd,
      // ecdDetails,
      // ecdInformation,
    } = formData;
    this.formData = formData;
    const _covidVaccinationFormatter = (value) => {
      if (value === 'FIRST') {
        return `First Dose Completed -  Date: ${
          basicMedicalInformation?.covidVaccination?.firstDose?.firstDoseDate
            ? moment(
                basicMedicalInformation?.covidVaccination?.firstDose?.firstDoseDate,
                FORMAT_DATE_UNIXTS
              ).format('DD/MM/YYYY')
            : '-'
        }, First Dose Certificate : ${basicMedicalInformation?.covidVaccination?.firstDose
          ?.firstDoseAttachment.name ? 'ATTACHED' : 'NOT ATTACHED'}`;
      }
      if (value === 'SECOND') {
        return `Second Dose Completed - First Date: ${
          basicMedicalInformation?.covidVaccination?.bothDose?.firstDoseDate
            ? moment(
                basicMedicalInformation?.covidVaccination?.bothDose?.firstDoseDate,
                FORMAT_DATE_UNIXTS
              ).format('DD/MM/YYYY')
            : '-'
        }, Second Date: ${
          basicMedicalInformation?.covidVaccination?.bothDose?.secondDoseDate
            ? moment(
                basicMedicalInformation?.covidVaccination?.bothDose?.secondDoseDate,
                FORMAT_DATE_UNIXTS
              ).format('DD/MM/YYYY')
            : '-'
        }, Fully Vaccinated Certificate : ${basicMedicalInformation?.covidVaccination?.bothDose
          ?.bothDoseAttachment.name ? 'ATTACHED' : 'NOT ATTACHED'}`;
      }
      if (value === 'NOTDONE') {
        return 'Not Done';
      }
      if (value === 'BOOSTER') {
        return `Booster Dose Completed - First Date: ${
          basicMedicalInformation?.covidVaccination?.boosterDose
                    ?.firstDoseDate
            ? moment(
                basicMedicalInformation?.covidVaccination?.boosterDose
                    ?.firstDoseDate,
                FORMAT_DATE_UNIXTS
              ).format('DD/MM/YYYY')
            : '-'
        }, Second Date: ${
          basicMedicalInformation?.covidVaccination?.boosterDose
                    ?.secondDoseDate
            ? moment(
                basicMedicalInformation?.covidVaccination?.boosterDose
                    ?.secondDoseDate,
                FORMAT_DATE_UNIXTS
              ).format('DD/MM/YYYY')
            : '-'
        },Booster Dose Date: ${
          basicMedicalInformation?.covidVaccination?.boosterDose
          ?.boosterDoseDate            ? moment(
            basicMedicalInformation?.covidVaccination?.boosterDose
            ?.boosterDoseDate,
                FORMAT_DATE_UNIXTS
              ).format('DD/MM/YYYY')
            : '-'
        }, Fully Vaccinated Certificate : ${basicMedicalInformation?.covidVaccination?.boosterDose
          ?.doseAttachment.name ? 'ATTACHED' : 'NOT ATTACHED'}`;      }
      return '-';
    };

    const _yesNoAndEmptyFormatter = (value) => {
      if (value === true) {
        return 'YES';
      }
      if (value === false) {
        return 'NO';
      }
      if (value === 'true') {
        return 'YES';
      }
      if (value === 'false') {
        return 'NO';
      }

      // return '-';
    };

    const evidenceOfSepsisByValue = (value) => {
      if (value === 'CRPT') {
        return 'CRP (C-reactive Protein)';
      }
      if (value === 'PCT') {
        return 'PCT (Procalcitonin)';
      }
      return '-';
    };

    const fatValues = (value) => {
      if (value === 'GRADEI') {
        return 'GRADE I';
      }
      if (value === 'GRADEII') {
        return 'GRADE II';
      }
      if (value === 'GRADEIII') {
        return 'GRADE III';
      }
      if (value === 'GRADEIV') {
        return 'GRADE IV';
      }
      return '-';
    };

    const causeOfDeathByValue = (value) => {
      if (value === 'HEAD_INJURY_EDH') {
        return 'Head injury - EDH';
      }
      if (value === 'HEAD_INJURY_SUBDURAL') {
        return 'Head injury - Subdural';
      }
      if (value === 'HEAD_INJURY_INTRAPARENCHYMAL') {
        return 'Head injury - intraparenchymal';
      }
      if (value === 'INFECTIONS') {
        return 'Infections';
      }
      if (value === 'BRAIN_TUMOR') {
        return 'Brain tumor';
      }
      if (value === 'CVA_ICBLEED') {
        return 'CVA - IC Bleed';
      }
      if (value === 'CVA_ISCHEMIC_STROKE') {
        return 'CVA - Ischemic Stroke';
      }
      if (value === 'HYPOXIAANOXIC') {
        return 'Hypoxia / anoxic Brain injury';
      }
      if (value === 'BACTERIAL_PYOGENIC') {
        return 'Meningitis - Bacterial Pyogenic';
      }
      if (value === 'BACTERIAL_STROKE_TB') {
        return 'Meningitis - Bacterial Stroke TB';
      }
      if (value === 'MENINGITIS_VIRAL') {
        return 'Meningitis - Viral';
      }
      if (value === 'ENCEPHALITIS_UNKNOWN') {
        return 'Encephalitis - Unknown';
      }
      if (value === 'ENCEPHALITIS_ORIGIN') {
        return 'Encephalitis - Origin';
      }
      if (value === 'OTHER') {
        return 'Other';
      }
      return '-';
    };

    const _yesNoAndDontKnowEmptyFormatter = (value) => {
      if (value === 'YES') {
        return 'YES';
      }
      if (value === 'NO') {
        return 'NO';
      }
      if (value === 'DONTKNOW') {
        return 'DONTKNOW';
      }
      return '-';
    };

    const _nationalityFormatter = (value) => {
      if (value === 'INDIAN_NATIONAL') {
        return 'Indian';
      }
      if (value === 'OCI_CARDHOLDER') {
        return 'Overseas Citizen of India (OCI) card holder';
      }
      if (value === 'FOREIGN_NATIONAL') {
        return 'Foreign national';
      }
      return '-';
    };
    const _getZtccZoneLabelById = (zone) => {
      if (zone === '7') {
        return 'ZTCC MUMBAI';
      }
      if (zone === '8') {
        return 'ZTCC PUNE';
      }
      if (zone === '9') {
        return 'ZTCC NAGPUR';
      }
      if (zone === '10') {
        return 'ZTCC AURANGABAD';
      }
      if (zone) {
        return zone;
      }

      return '';
    };

    // const _praFormatter = (value) => {
    //   if (value === 'CLASSONE') {
    //     return 'Class 1';
    //   }
    //   if (value === 'CLASSTWO') {
    //     return 'Class 2';
    //   }
    //   if (value === 'BOTHCLASSES') {
    //     return 'Both Class 1 and Class 2';
    //   }
    //   if (value === 'NOTAPPLICABLE') {
    //     return 'Not Applicable';
    //   }
    //   return '-';
    // };

    const _viralMarkerRemarkAndDateFormatter = (remark, date) => {
      if (remark && date) {
        return `${remark} - ${moment(date, FORMAT_DATE_UNIXTS).format('DD/MM/YYYY')}`;
      }
      return '-';
    };

    const _latestInvestigationRemarkAndReportFormatter = (remark, report) => {
      if (remark && report) {
        return `${remark} - ${report ? 'ATTACHED' : 'NOT ATTACHED'}`;
      }
      return '-';
    };

    const _getCurrentAgeByDateOfBirth = (value) => {
      const duration = moment.duration(moment().diff(moment(value, FORMAT_DATE_UNIXTS)));
      return duration?._data?.years;
    };

    const reportDocDefinition = {
      content: [
        {
          text: '',
        },

        {
          style: 'table',
          table: {
            widths: [250, 100, 150, 'auto', 'auto'],
            heights: [
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
            ],
            body: [
              [
                {
                  text: 'State Organ and Tissue Transplant Organisation (SOTTO),\n  Maharashtra',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'center',
                  bold: true,
                },
                {},
                {},
              ],

              [
                {
                  text: 'State Appropriate Authority of Maharashtra - DONOR FORM',
                  style: 'textStyle',
                  alignment: 'center',
                  colSpan: 3,
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: `Date: ${moment().format('DD MMMM YYYY')}`,
                  style: 'textStyle',
                  alignment: 'left',
                  colSpan: 3,
                },
                {},
                {},
              ],
              [
                {
                  text: `Name: ${donorBasicInfoDetails?.name ? donorBasicInfoDetails?.name : '-'}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                },
                {},
                {},
              ],
              [
                {
                  text: `ZTCC Zone : ${_getZtccZoneLabelById(ztccZone)}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                },
                {},
                {},
              ],
              [
                {
                  text: `Hospital Name: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                { text: `${hospitalName || '-'}`, colSpan: 2 },
                {},
              ],
              [
                {
                  text: `Has the Donor NOTTO ID been generated? `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${_yesNoAndEmptyFormatter(donorBasicInfoDetails?.isNottoId)}`,
                  colSpan: 2,
                },
                { text: '' },
                { text: '' },
              ],
              [
                {
                  text: `NOTTO ID : `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${donorBasicInfoDetails?.nottoId ? donorBasicInfoDetails?.nottoId : '-'}`,
                  colSpan: 2,
                },
                { text: '' },
                { text: '' },
              ],
              [
                {
                  text: `Date of Registration : `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    donorBasicInfoDetails?.dateOfRegistration
                      ? moment(
                          donorBasicInfoDetails?.dateOfRegistration,
                          FORMAT_DATE_UNIXTS
                        ).format('DD/MM/YYYY')
                      : moment(Math.floor(Date.now() / 1000), FORMAT_DATE_UNIXTS).format(
                          'DD/MM/YYYY'
                        )
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Date of Admission : `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    donorBasicInfoDetails?.dateOfAdmission
                      ? moment(donorBasicInfoDetails?.dateOfAdmission, FORMAT_DATE_UNIXTS).format(
                          'DD/MM/YYYY'
                        )
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Date of Death : `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    donorBasicInfoDetails?.dateOfDeath
                      ? moment(donorBasicInfoDetails?.dateOfDeath, FORMAT_DATE_UNIXTS).format(
                          'DD/MM/YYYY'
                        )
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Date of Birth : `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    donorBasicInfoDetails?.dateOfBirth
                      ? moment(donorBasicInfoDetails?.dateOfBirth, FORMAT_DATE_UNIXTS).format(
                          'DD/MM/YYYY'
                        )
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Age`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: ` ${_getCurrentAgeByDateOfBirth(donorBasicInfoDetails?.dateOfBirth)}`,

                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Blood Group `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    donorBasicInfoDetails?.bloodGroup
                      ? BLOODGROUP[donorBasicInfoDetails?.bloodGroup].label
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Gender`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${
                    donorBasicInfoDetails?.sex
                      ? Object.values(SEX).find((s) => s.value === donorBasicInfoDetails.sex)
                          ?.label || '-'
                      : '-'
                  }`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Height (in cm)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: ` ${
                    donorBasicInfoDetails?.heightCm ? donorBasicInfoDetails?.heightCm : '-'
                  }`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Height (in inch) `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: ` ${
                    donorBasicInfoDetails?.heightIn ? donorBasicInfoDetails?.heightIn : '-'
                  }`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Weight (in Kg)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: ` ${
                    donorBasicInfoDetails?.weightKg ? donorBasicInfoDetails?.weightKg : '-'
                  }`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Country `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    donorBasicInfoDetails?.countryOfResidence
                      ? OPTIONS_NATIONALITY.find(
                          (country) => country.value === donorBasicInfoDetails?.countryOfResidence
                        )?.label || '-'
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `State & District: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    donorBasicInfoDetails?.state
                      ? STATE_IN.find((state) => state.value === donorBasicInfoDetails?.state)
                          ?.label || '-'
                      : '-'
                  } - ${donorBasicInfoDetails?.district}`,
                  colSpan: 2,
                },

                {},
              ],
              [
                {
                  text: `Religion`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${
                    donorBasicInfoDetails?.religion ? donorBasicInfoDetails?.religion : '-'
                  }`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Nationality: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_nationalityFormatter(donorBasicInfoDetails?.nationality)}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Treating Doctor Name `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    donorBasicInfoDetails?.treatingDoctorName
                      ? donorBasicInfoDetails?.treatingDoctorName
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Treating Doctor Email `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    donorBasicInfoDetails?.treatingDoctorEmail
                      ? donorBasicInfoDetails?.treatingDoctorEmail
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Treating Contact Number `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    donorBasicInfoDetails?.treatingDoctorContact
                      ? donorBasicInfoDetails?.treatingDoctorContact
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `ICU Doctor Name `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    donorBasicInfoDetails?.icuDoctorName
                      ? donorBasicInfoDetails?.icuDoctorName
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `ICU Doctor Contact Number `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    donorBasicInfoDetails?.icuDoctorContact
                      ? donorBasicInfoDetails?.icuDoctorContact
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Donor Listing Type `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    donorBasicInfoDetails?.listingType ? donorBasicInfoDetails?.listingType : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              // [
              //   {
              //     text: `Is Donor eligible for Organ Donation?`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${donorOrganRetrievalDetails?.donorEligibleForDonation || '-'}`,
              //     colSpan: 3,
              //   },
              //   {},
              // ],
              // [
              //   {
              //     text: `If donor is not eligble, State Reason `,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${
              //       donorOrganRetrievalDetails?.notEligibleReason
              //         ? donorOrganRetrievalDetails?.notEligibleReason
              //         : '-'
              //     }`,
              //     colSpan: 3,
              //   },
              //   {},
              // ],
              [
                {
                  text: `List of Organs consented for Retrieval :- `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${retrievedOrgans ? (retrievedOrgans || '').join(' , ') : '-'}`,
                  colSpan: 3,
                },
                {},
              ],
              [
                {
                  text: `Is any donated organs under ECD ?`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(anyEcdOrgans)}`,

                  colSpan: 3,
                },
                {},
              ],
              [
                {
                  text: `List of Organs under ECD`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${ecdOrgans || '-'}`,
                  colSpan: 3,
                },
                {},
              ],

              // Basic Medical Information
              [
                {
                  text: 'Basic Medical Information',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'center',
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: `Chest Circumference (in cm): `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicMedicalInformation?.chestCircumCm
                      ? basicMedicalInformation?.chestCircumCm
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Chest Circumference (in inch): `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicMedicalInformation?.chestCircumIn
                      ? basicMedicalInformation?.chestCircumIn
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Abdomen Circumference (in cm): `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicMedicalInformation?.abdomenCircumCm
                      ? basicMedicalInformation?.abdomenCircumCm
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Abdomen Circumference (in inch): `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicMedicalInformation?.abdomenCircumIn
                      ? basicMedicalInformation?.abdomenCircumIn
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Cause of Death: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicMedicalInformation?.causeOfDeath
                      ? causeOfDeathByValue(basicMedicalInformation?.causeOfDeath)
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `First Apnea Date and Time: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${
                    basicMedicalInformation?.firstApneaTime
                      ? basicMedicalInformation?.firstApneaTime.replace('T', ', ')
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `First Apnea Baseline Report: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${
                    basicMedicalInformation?.firstApneaBaselineReport?.name ? 'ATTACHED' : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `First Apnea after 10 minutes Report: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${
                    basicMedicalInformation?.firstApneaAfterTenMinutesReport?.name
                      ? 'ATTACHED'
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Second Apnea Date and Time:  `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicMedicalInformation?.secondApneaTime
                      ? basicMedicalInformation?.secondApneaTime.replace('T', ', ')
                      : '-'
                  }`,
                  colSpan: 3,
                },
                {},
              ],
              [
                {
                  text: `Second Apnea Baseline Report: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${
                    basicMedicalInformation?.secondApneaBaselineReport?.name ? 'ATTACHED' : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Second Apnea after 10 minutes Report: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${
                    basicMedicalInformation?.secondApneaAfterTenMinutesReport?.name
                      ? 'ATTACHED'
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Consent Obtained from Family / Relatives `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(basicMedicalInformation?.consentObtained)}`,
                  colSpan: 3,
                },
                {},
              ],
              [
                {
                  text: 'If No, Specify Reason ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicMedicalInformation?.consentReason
                      ? basicMedicalInformation?.consentReason
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Date of Onset of Illness: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicMedicalInformation?.dateOfOnsetOfIllness
                      ? moment(
                          basicMedicalInformation?.dateOfOnsetOfIllness,
                          FORMAT_DATE_UNIXTS
                        ).format('DD/MM/YYYY')
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Abdominal/Chest Injuries: ',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    _yesNoAndEmptyFormatter(basicMedicalInformation?.abdominalChestInjuries)
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Abdominal/Chest Injury Details: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicMedicalInformation?.abdominalChestInjuriesDetails
                      ? basicMedicalInformation?.abdominalChestInjuriesDetails
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Abdominal/Chest Injury Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicMedicalInformation?.abdominalOrChestInjuryReport?.name ? 'ATTACHED' : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Operation on Donor',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                       _yesNoAndEmptyFormatter(basicMedicalInformation?.operationOnDonor)
                      
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Operation on Donor Details`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicMedicalInformation?.operationOnDonorDetails
                      ? basicMedicalInformation?.operationOnDonorDetails
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              // [
              //   {
              //     text: `Date of Hospitalization/ Admission `,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${
              //       basicMedicalInformation?.dateOfHospitalization
              //         ? moment(
              //             basicMedicalInformation?.dateOfHospitalization,
              //             FORMAT_DATE_UNIXTS
              //           ).format('DD/MM/YYYY')
              //         : '-'
              //     }`,
              //     colSpan: 3,
              //   },
              //   {},
              // ],

              [
                {
                  text: `Operation on Donor Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicMedicalInformation?.operationOnDonorReport?.name ? 'ATTACHED' : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Is Covid Vaccination Done?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${_covidVaccinationFormatter(
                    basicMedicalInformation?.covidVaccination?.dosesDone
                  )}`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 2,
                },
              ],

              [
                {
                  text: `Medico Legal Case(If yes provide Details)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(basicMedicalInformation?.medicoLegalCaseYesNo)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Police Noc Approved`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${basicMedicalInformation?.medicoLegalCase || ''}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `If Police Noc Approved, Police NoC Attachment`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${basicMedicalInformation?.medicoLegalCaseNoc?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `If Police Noc Not Required, Reason`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicMedicalInformation?.medicoLegalCaseNotRequiredReason
                      ? basicMedicalInformation?.medicoLegalCaseNotRequiredReason
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Covid History`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                       _yesNoAndEmptyFormatter(basicMedicalInformation?.covidHistory)
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Last Covid Positive Date`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicMedicalInformation?.covidPositiveDate
                      ? moment(
                          basicMedicalInformation?.covidPositiveDate,
                          FORMAT_DATE_UNIXTS
                        ).format('DD/MM/YYYY')
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Last Covid Cure Date`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicMedicalInformation?.covidCureDate
                      ? moment(basicMedicalInformation?.covidCureDate, FORMAT_DATE_UNIXTS).format(
                          'DD/MM/YYYY'
                        )
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Last Covid Clinical Symptoms`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicMedicalInformation?.covidSymptoms
                      ? basicMedicalInformation?.covidSymptoms
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Latest RTPCR Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${basicMedicalInformation?.latestRtpct?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Latest Medications Information `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${basicMedicalInformation?.anyOtherMedications?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Any Episode of Cardiac Arrest',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                       _yesNoAndEmptyFormatter(basicMedicalInformation?.episodeOfCardiacArrest)
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'How many times Cardiac Arrest occured?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicMedicalInformation?.durationOfCardiacArrest
                      ? basicMedicalInformation?.durationOfCardiacArrest
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              // [
              //   {
              //     text: 'Pressure Support',
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${
              //       hemoDynamicParameters?.pressureSupport
              //         ? hemoDynamicParameters?.pressureSupport
              //         : '-'
              //     }`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              // [
              //   {
              //     text: `Duration of Pressure Support (Minutes)`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${
              //       hemoDynamicParameters?.durationOfPressureSupport
              //         ? hemoDynamicParameters?.durationOfPressureSupport
              //         : '-'
              //     }`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              // [
              //   {
              //     text: `Current Drug and Dose`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${
              //       hemoDynamicParameters?.drugAndDoseCurrent
              //         ? hemoDynamicParameters?.drugAndDoseCurrent
              //         : '-'
              //     }`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              // [
              //   {
              //     text: `Previous Drug and Dose`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${
              //       hemoDynamicParameters?.drugAndDosePrevious
              //         ? hemoDynamicParameters?.drugAndDosePrevious
              //         : '-'
              //     }`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              // [
              //   {
              //     text: `Any Other Medication`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${
              //       hemoDynamicParameters?.anyOtherMedications
              //         ? hemoDynamicParameters?.anyOtherMedications
              //         : '-'
              //     }`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              [
                {
                  text: `H/O Alcoholism`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                       _yesNoAndEmptyFormatter(basicMedicalInformation?.alcoholism)
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Alcoholism History`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${basicMedicalInformation?.alcoholismHistory}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `History of Smoking`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicMedicalInformation?.smokingHistory
                      ? basicMedicalInformation?.smokingHistory
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Further Info if any (Not Mandatory)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${basicMedicalInformation?.smokingInfo}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Diabetes`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicMedicalInformation?.diabetics ? basicMedicalInformation?.diabetics : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Further Info if any (Not Mandatory)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${basicMedicalInformation?.diabeticsInfo}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Hypertension`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${basicMedicalInformation?.htn ? basicMedicalInformation?.htn : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Further Info if any (Not Mandatory)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${basicMedicalInformation?.htnInfo}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Ischemic Heart Disease`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${basicMedicalInformation?.ihd ? basicMedicalInformation?.ihd : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Further Info if any (Not Mandatory)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${basicMedicalInformation?.ihdInfo}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Asthma`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicMedicalInformation?.asthma ? basicMedicalInformation?.asthma : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Further Info if any (Not Mandatory)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${basicMedicalInformation?.asthmaInfo}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Lung Disease`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    basicMedicalInformation?.lungDisease
                      ? basicMedicalInformation?.lungDisease
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Further Info if any (Not Mandatory)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${basicMedicalInformation?.lungDiseaseInfo}`,
                  colSpan: 2,
                },
                {},
              ],
              // [
              //   {
              //     text: 'Donor Medical History',
              //     style: 'tableHeader',
              //     colSpan: 3,
              //     alignment: 'center',
              //     bold: true,
              //   },
              //   {},
              //   {},
              // ],
              // [
              //   {
              //     text: `Does the donor have any kind of Tumor?`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${medicalHistory?.tumor || '-'}`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              [
                {
                  text: `Infections in the current / recent addmission`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${medicalHistory?.infections ? medicalHistory?.infections : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Infections Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${medicalHistory?.infectionsReport?.name ? 'ATTACHED' : 'NOT ATTACHED'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Use of live vaccines (especially in paediatrics)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                       _yesNoAndEmptyFormatter(medicalHistory?.liveVaccines)
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Live Vaccine Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${medicalHistory?.liveVaccinesReport?.name ? 'ATTACHED' : 'NOT ATTACHED'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Occupational Exposures`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    medicalHistory?.occupationalExposure
                      ? medicalHistory?.occupationalExposure
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Details`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${medicalHistory?.occupationalExposureDetails}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Recent International or Domestic Travel History (within 4 weeks)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${medicalHistory?.travelHistory ? medicalHistory?.travelHistory : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Travel History Details`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${medicalHistory?.travelHistoryReport}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Recent Transfusions with blood or blood products`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    medicalHistory?.bloodTransfusion ? medicalHistory?.bloodTransfusion : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Details`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${medicalHistory?.bloodTransfusionDetails}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Any contact with people with HIV, HBV, HCV or other transmissible diseases`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    medicalHistory?.contactWithTransmissibleDiseases
                      ? _yesNoAndDontKnowEmptyFormatter(
                          medicalHistory?.contactWithTransmissibleDiseases
                        )
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Details`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${medicalHistory?.contactWithTransmissibleDiseasesDetails}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Use of illicit drugs`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    medicalHistory?.illicitDrugs
                      ? _yesNoAndDontKnowEmptyFormatter(medicalHistory?.illicitDrugs)
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Details`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${medicalHistory?.illicitDrugsDetails}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Tattooing, Ear Piercing or Body Piercing`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    medicalHistory?.tattoEarOrBodyPiercing
                      ? _yesNoAndDontKnowEmptyFormatter(medicalHistory?.tattoEarOrBodyPiercing)
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Details`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${medicalHistory?.tattoEarOrBodyPiercingDetails}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Unsafe sexual practices`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    medicalHistory?.unsafeSexPratices
                      ? _yesNoAndDontKnowEmptyFormatter(medicalHistory?.unsafeSexPratices)
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Details`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${medicalHistory?.unsafeSexPraticesDetails}`,
                  colSpan: 2,
                },
                {},
              ],

              // Hemodynamic
              [
                {
                  pageBreak: 'before',
                  text: 'Hemodynamic Parameter',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'center',
                  bold: true,
                },
                {},
                {},
              ],

              [
                {
                  text: `Pulse (beats/min)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${hemoDynamicParameters?.pulse ? hemoDynamicParameters?.pulse : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Blood Pressure (mmHg)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${hemoDynamicParameters?.bp ? hemoDynamicParameters?.bp : '-'}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Temperature (Degree Celcius)',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    hemoDynamicParameters?.temperature ? hemoDynamicParameters?.temperature : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Urine Output (ml)',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    hemoDynamicParameters?.hourlyUrineOutput
                      ? hemoDynamicParameters?.hourlyUrineOutput
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Adrenaline Dose (micro gram/ kg)',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    hemoDynamicParameters?.adrenalineDose
                      ? hemoDynamicParameters?.adrenalineDose
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Noradrenaline Dose (micro gram/ kg)',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    hemoDynamicParameters?.noradrenalineDose
                      ? hemoDynamicParameters?.noradrenalineDose
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Dopamine Dose (micro gram/ kg)',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    hemoDynamicParameters?.dopamineDose ? hemoDynamicParameters?.dopamineDose : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Vasopressin Dose (micro gram/ kg)',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    hemoDynamicParameters?.vasopressinDose
                      ? hemoDynamicParameters?.vasopressinDose
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Ventilator Setting',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'left',
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: 'Does the patient on Ventilator Support?',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(hemoDynamicParameters?.ventilatorSetting?.ventilationSupport)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Duration on Ventilators(in Days)',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    hemoDynamicParameters?.ventilatorSetting?.durationOnVentilator
                      ? hemoDynamicParameters?.ventilatorSetting?.durationOnVentilator
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              // [
              //   {
              //     text: 'Ventilator Setting',
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${
              //       hemoDynamicParameters?.ventilatorSetting?.ventilatorSettings
              //         ? hemoDynamicParameters?.ventilatorSetting?.ventilatorSettings
              //         : '-'
              //     }`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              [
                {
                  text: 'Mode of Ventilations',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    hemoDynamicParameters?.ventilatorSetting?.modeOfVentilation
                      ? hemoDynamicParameters?.ventilatorSetting?.modeOfVentilation
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'FIO2',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    hemoDynamicParameters?.ventilatorSetting?.fio2
                      ? hemoDynamicParameters?.ventilatorSetting?.fio2
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'PEEP',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    hemoDynamicParameters?.ventilatorSetting?.peep
                      ? hemoDynamicParameters?.ventilatorSetting?.peep
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              // MicroBiology
              [
                {
                  text: 'MicroBiology',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'center',
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: `HBsAg Report Date \n (Positive/Negative) `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_viralMarkerRemarkAndDateFormatter(
                    viralMarkerStatus?.hbsagRemark,
                    viralMarkerStatus?.hbsagReportDate
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `HBsAg Report `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${viralMarkerStatus?.hbSagReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `HBcAb Report Date \n (Positive/Negative) `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_viralMarkerRemarkAndDateFormatter(
                    viralMarkerStatus?.hbcabRemark,
                    viralMarkerStatus?.hbcabReportDate
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `HBcAb Report `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${viralMarkerStatus?.hbcabReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `HBV DNA`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${viralMarkerStatus?.hbvdna ? viralMarkerStatus?.hbvdna : '-'}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `HBV DNA Report `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${viralMarkerStatus?.hbvdnaReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Anti HCV (Hepatitis C) \n (Positive/Negative)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_viralMarkerRemarkAndDateFormatter(
                    viralMarkerStatus?.antiHcvRemark,
                    viralMarkerStatus?.antiHcvReportDate
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Anti HCV Report `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${viralMarkerStatus?.antiHcvReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `HCV RNA`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${viralMarkerStatus?.hcvrna ? viralMarkerStatus?.hcvrna : '-'}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `HCV RNA Report `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${viralMarkerStatus?.hcvrnaReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `HIV Report Date \n (Positive/Negative) `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_viralMarkerRemarkAndDateFormatter(
                    viralMarkerStatus?.hivRemark,
                    viralMarkerStatus?.hivReportDate
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `HIV Report `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${viralMarkerStatus?.hivReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              // Latest Investigations
              [
                {
                  text: 'Donor Latest Investigations',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'center',
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: 'Hematology',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'left',
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: `CBC Report `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${latestInvestigations?.cbcReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `WBC Count `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${latestInvestigations?.wbc ? latestInvestigations?.wbc : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Platelet Count `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${latestInvestigations?.platelet ? latestInvestigations?.platelet : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Hemoglobin `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    latestInvestigations?.hemoglobin ? latestInvestigations?.hemoglobin : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'ABG',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'left',
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: `ABG Report  `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${latestInvestigations?.abgReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `HbA1c Report  `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${latestInvestigations?.hba1cReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `ABG pH `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${latestInvestigations?.ph ? latestInvestigations?.ph : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `ABG pCO2 `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${latestInvestigations?.pco2 ? latestInvestigations?.pco2 : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `ABG pO2 `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${latestInvestigations?.po2 ? latestInvestigations?.po2 : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `ABG HCO3 `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${latestInvestigations?.hco3 ? latestInvestigations?.hco3 : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `ABG SaO2 `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${latestInvestigations?.sao2 ? latestInvestigations?.sao2 : '-'}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Blood Sugar `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    latestInvestigations?.bloodSugarLevel
                      ? latestInvestigations?.bloodSugarLevel
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `HbA1c `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${latestInvestigations?.hba1c ? latestInvestigations?.hba1c : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Urine Routine',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'left',
                  bold: true,
                },
                {},
                {},
              ],

              [
                {
                  text: `Urine Routine Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${latestInvestigations?.urineRoutineReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Physical Examination',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'left',
                  bold: true,
                },
                {},
                {},
              ],

              [
                {
                  text: `Quantity (in ml) `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    renalFunction?.urineRoutine?.physical?.quantity
                      ? renalFunction?.urineRoutine?.physical?.quantity
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Color `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    renalFunction?.urineRoutine?.physical?.color
                      ? renalFunction?.urineRoutine?.physical?.color
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Appearance  `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    renalFunction?.urineRoutine?.physical?.appearance
                      ? renalFunction?.urineRoutine?.physical?.appearance
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Deposit `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    renalFunction?.urineRoutine?.physical?.deposit
                      ? renalFunction?.urineRoutine?.physical?.deposit
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Reaction `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    renalFunction?.urineRoutine?.physical?.reaction
                      ? renalFunction?.urineRoutine?.physical?.reaction
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Specify Gravity `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    renalFunction?.urineRoutine?.physical?.specificGravity
                      ? renalFunction?.urineRoutine?.physical?.specificGravity
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Chemical Examination',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'left',
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: `Protein `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    renalFunction?.urineRoutine?.chemical?.protein
                      ? renalFunction?.urineRoutine?.chemical?.protein
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Glucose `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    renalFunction?.urineRoutine?.chemical?.glucose
                      ? renalFunction?.urineRoutine?.chemical?.glucose
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Occult Blood `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    renalFunction?.urineRoutine?.chemical?.occultBlood
                      ? renalFunction?.urineRoutine?.chemical?.occultBlood
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Ketones `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    renalFunction?.urineRoutine?.chemical?.ketones
                      ? renalFunction?.urineRoutine?.chemical?.ketones
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Bile Salt `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    renalFunction?.urineRoutine?.chemical?.bileSalt
                      ? renalFunction?.urineRoutine?.chemical?.bileSalt
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Pigments `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    renalFunction?.urineRoutine?.chemical?.pigments
                      ? renalFunction?.urineRoutine?.chemical?.pigments
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Microscopic Examination',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'left',
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: `Pus Cells  `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    renalFunction?.urineRoutine?.microscopic?.pusCells
                      ? renalFunction?.urineRoutine?.microscopic?.pusCells
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Red Blood Cells  `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    renalFunction?.urineRoutine?.microscopic?.rbc
                      ? renalFunction?.urineRoutine?.microscopic?.rbc
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Epithelial Cells  `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    renalFunction?.urineRoutine?.microscopic?.epithelialCells
                      ? renalFunction?.urineRoutine?.microscopic?.epithelialCells
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Casts `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    renalFunction?.urineRoutine?.microscopic?.casts
                      ? renalFunction?.urineRoutine?.microscopic?.casts
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Crystals `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    renalFunction?.urineRoutine?.microscopic?.crystals
                      ? renalFunction?.urineRoutine?.microscopic?.crystals
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'Donor Liver Function',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'center',
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: 'Liver Fuction Test (LFT))',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'left',
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: `Total Bilirubin (md/dL) (during admission)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    liverFunction?.totalBilirubinDuringAdmission
                      ? liverFunction?.totalBilirubinDuringAdmission
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Total Bilirubin (md/dL) (at present)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    liverFunction?.totalBilirubinAtPresent
                      ? liverFunction?.totalBilirubinAtPresent
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Direct Bilirubin (md/dL) (during admission)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    liverFunction?.directBilirubinDuringAdmission
                      ? liverFunction?.directBilirubinDuringAdmission
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Direct Bilirubin (md/dL) (at present)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    liverFunction?.directBilirubinAtPresent
                      ? liverFunction?.directBilirubinAtPresent
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Indirect Bilirubin (md/dL) (during admission)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    liverFunction?.indirectBilirubinDuringAdmission
                      ? liverFunction?.indirectBilirubinDuringAdmission
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Indirect Bilirubin (md/dL) (at present)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    liverFunction?.indirectBilirubinAtPresent
                      ? liverFunction?.indirectBilirubinAtPresent
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: ` SGOT (U/L) (during admission)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    liverFunction?.sgotDuringAdmission ? liverFunction?.sgotDuringAdmission : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `SGOT (U/L) (at present)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${liverFunction?.sgotAtPresent ? liverFunction?.sgotAtPresent : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `SGPT (U/L) (at present)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${liverFunction?.sgptPresent ? liverFunction?.sgptPresent : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `SGPT (U/L) (during admission)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    liverFunction?.sgptDuringAdmission ? liverFunction?.sgptDuringAdmission : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Serum Alkaline Phosphatase (U/L) (during admission)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    liverFunction?.alkalinePhosphataseProthrombinDuringAdmission
                      ? liverFunction?.alkalinePhosphataseProthrombinDuringAdmission
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Serum Alkaline Phosphatase (U/L) (at present)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    liverFunction?.alkalinePhosphataseProthrombinAtPresent
                      ? liverFunction?.alkalinePhosphataseProthrombinAtPresent
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              // [
              //   {
              //     text: `S. Bilirubin`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${liverFunction?.sBilirubin ? liverFunction?.sBilirubin : '-'}`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              [
                {
                  text: `Total Protein (gm/dL) (during admission)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    liverFunction?.totalProteinDuringAdmission
                      ? liverFunction?.totalProteinDuringAdmission
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Total Protein (gm/dL) (at present)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    liverFunction?.totalProteinAtPresent
                      ? liverFunction?.totalProteinAtPresent
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Serum Albumin (gm/dL) (during admission)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    liverFunction?.albuminDuringAdmission
                      ? liverFunction?.albuminDuringAdmission
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Serum Albumin (gm/dL) (at present)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    liverFunction?.albuminAtPresent ? liverFunction?.albuminAtPresent : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Prothrombin Time`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${liverFunction?.prothrombinTime ? liverFunction?.prothrombinTime : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `INR`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${liverFunction?.inr ? liverFunction?.inr : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `USG Liver for Fatty Liver or any other abnormlity`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${liverFunction?.usgLiver ? liverFunction?.usgLiver : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `USG Abdomin and Pelvis for any other abnormality`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${liverFunction?.usgAbdomin ? liverFunction?.usgAbdomin : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Evidence of Sepsis`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    evidenceOfSepsisByValue(liverFunction?.sepsis)
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              // [
              //   {
              //     text: `Any abnormality of RFT and LFT till that time to be notified`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${liverFunction?.rftLft ? liverFunction?.rftLft : '-'}`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              [
                {
                  text: `Size Liver Span (cm)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${liverFunction?.sizeLiverSpan ? liverFunction?.sizeLiverSpan : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Liver Ultrasound(LFT)',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'left',
                  bold: true,
                },
                {},
                {},
              ],

              [
                {
                  text: `Lingula Lobe`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(liverFunction?.lingularLobe)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Fat(%)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${fatValues(liverFunction?.fat)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `SO2`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(liverFunction?.so2)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Hemangioma`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(liverFunction?.hacmangioma)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `HCC`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(liverFunction?.noOneOrMoreHcc)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Surface Nodularity`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(liverFunction?.surfaceNodularity)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Rounding of Liver Margins`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(liverFunction?.roudingOfLiverMargins)}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Parenchymal Nodules`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(liverFunction?.parenchymalNodules)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Altered Echotexture`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(liverFunction?.alteredEchotexlere)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Volume Redistribution`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(liverFunction?.volumeRedistribution)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Caudate Hypertrophy`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(liverFunction?.caudateHypertorphy)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Obvious Cirrhotic Liver`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(liverFunction?.obviousCirrhoticLiver)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `PV Diameter(mm)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${liverFunction?.pvDiameter ? liverFunction?.pvDiameter : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `PV Flow`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${liverFunction?.pvFlow ? liverFunction?.pvFlow : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `SV Diameter(mm) `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${liverFunction?.svDiameter ? liverFunction?.svDiameter : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Collaterals`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(liverFunction?.collaterals)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Splenomegaly`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(liverFunction?.splenomegaly)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Ascites `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(liverFunction?.ascites)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Liver Ultrasound Report `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${liverFunction?.liverUltraSoundReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Any Report Attachments`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${liverFunction?.reports[0]?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],

              // Heart Function
              [
                {
                  text: 'Donor Heart Function',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'center',
                  bold: true,
                },
                {},
                {},
              ],

              [
                {
                  text: `2D-ECHO`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${heartFunction?.twoDEcho ? heartFunction?.twoDEcho : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `2D-ECHO Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${heartFunction?.twoDEchoReport?.name ? 'ATTACHED' : ''}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Chest X-ray`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${heartFunction?.chestXRay ? heartFunction?.chestXRay : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Chest X-ray Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${heartFunction?.chestXRayReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Angiography`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(heartFunction?.angiographyYesNo)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Angiography Details`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${heartFunction?.angiography ? heartFunction?.angiography : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Angiography Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${heartFunction?.angiographyReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Donor Blood Sample for cross match minimum 10-12 Heparin tubes of 5ml each`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(heartFunction?.bloodSampleForCrossMatchYesNo)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Donor Blood Sample for cross match minimum 10-12 Heparin tubes of 5ml each Details`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    heartFunction?.bloodSampleForCrossMatch
                      ? heartFunction?.bloodSampleForCrossMatch
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              // [
              //   {
              //     text: `Donor Blood Sample for cross match minimum 10-12 Heparin tubes of 5ml each Report`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${
              //       heartFunction?.bloodSampleForCrossMatchReport ? 'ATTACHED' : 'NOT ATTACHED'
              //     }`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              [
                {
                  text: `Blood Sample of Heart Cross Matching`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    heartFunction?.bloodSampleForHeartCrossMatchingYesNo
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Blood Sample of Heart Cross Matching Details`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${
                    heartFunction?.bloodSampleForHeartCrossMatching
                      ? heartFunction?.bloodSampleForHeartCrossMatching
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Blood Sample of Heart Cross Matching Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    heartFunction?.bloodSampleForHeartCrossMatchingReport?.name ? 'ATTACHED' : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `CT Thorax for Lung`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(heartFunction?.ctThoraxYesNo)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `CT Thorax for Lung Details`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${heartFunction?.ctThorax ? heartFunction?.ctThorax : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `CT Thorax for Lung Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${heartFunction?.ctThoraxReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Trop T (Troponin)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(heartFunction?.tropTYesNo)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Trop T (Troponin) Details`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${heartFunction?.tropT ? heartFunction?.tropT : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Trop T (Troponin) Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${heartFunction?.tropTReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],

              // Kidney Function
              [
                {
                  text: 'Kidney Function',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'center',
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: 'Renal Function Test (RFT)',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'left',
                  bold: true,
                },
                {},
                {},
              ],

              [
                {
                  text: `Creatinine (During Admission)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    renalFunction?.serumCreatinineDuringAdmission
                      ? renalFunction?.serumCreatinineDuringAdmission
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Creatinine (At Present)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    renalFunction?.serumCreatinineAtPresent
                      ? renalFunction?.serumCreatinineAtPresent
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              // [
              //   {
              //     text: `Electrolytes`,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${renalFunction?.electrolytes || '-'}`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              [
                {
                  text: `Sodium (Na) (During Admission)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    renalFunction?.naDuringAdmission ? renalFunction?.naDuringAdmission : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Sodium (Na) (At Present)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${renalFunction?.naAtPresent ? renalFunction?.naAtPresent : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Potassium (K)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${renalFunction?.potassium ? renalFunction?.potassium : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Chloride (Cl)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${renalFunction?.chloride ? renalFunction?.chloride : '-'}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `BUN (Blood Urea Nitrogen)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${renalFunction?.bun ? renalFunction?.bun : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `USG Kidney for any other abnormality`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    renalFunction?.usgKidneyForAbnormality
                      ? renalFunction?.usgKidneyForAbnormality
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Evidence of Sepsis`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    evidenceOfSepsisByValue(renalFunction?.sepsis)
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Kidney Size`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${renalFunction?.kidneySize ? renalFunction?.kidneySize : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Evidence of RCC`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                       _yesNoAndEmptyFormatter(renalFunction?.evidenceOfRcc)
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Any other cyst`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                       _yesNoAndEmptyFormatter(renalFunction?.anyOtherCyst)
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Loss of CMD`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                       _yesNoAndEmptyFormatter(renalFunction?.lossOfCmd)
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Any Report Attachments`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${renalFunction?.reports[0]?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],

              // Hand Assesment

              [
                {
                  text: 'Hand Assessment',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'center',
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: `Hand Assessment (Scars, Injuries, Vascularity)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${
                    handAssessment?.handAssessmentDamageType
                      ? handAssessment?.handAssessmentDamageType.join(', ')
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Hand X-Ray`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${handAssessment?.handXRay?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Rule out history of neurological illnesses like leprosy, stroke`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    handAssessment?.historyOfNeurologicalIllness
                      ? handAssessment?.historyOfNeurologicalIllness
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Rule out history of previous trauma like \n nerve injuries, tendon injuries, fractures etc`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    handAssessment?.historyOfPreviousTrauma
                      ? handAssessment?.historyOfPreviousTrauma
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `History s/o any arthritis / connective tissue disorders / degenerative disorders.`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    handAssessment?.historyOfOtherDisease
                      ? handAssessment?.historyOfOtherDisease
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              // Imaging

              [
                {
                  text: 'Imaging',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'center',
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: 'Imaging Reports/Videos',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'left',
                  bold: true,
                },
                {},
                {},
              ],

              [
                {
                  text: `CT Head Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${imaging?.ctHead?.ctHeadReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `MRI Head Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${imaging?.mriHead?.mriHeadReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `X-ray Chest Film`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${imaging?.xRayChest?.xRayChestReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `X-ray Abdomen Film`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${imaging?.xRayAbdomen?.xRayAbdomenReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `CT Chest Report `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${imaging?.ctChest?.ctChestReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `USG Abdomen and Pelvis Report `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    imaging?.usgAbdomenAndPelvis?.usgAbdomenAndPelvisReport?.name ? 'ATTACHED' : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'CT Head',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'left',
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: `SDH`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(imaging?.ctHead?.ctHeadSdh)}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `EDH`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(imaging?.ctHead?.ctHeadEdh)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Intra Ventricular Bleed`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(imaging?.ctHead?.ctHeadIntraVentricularBleed)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Mid Line Shift`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(imaging?.ctHead?.ctHeadMidLineShift)}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'MRI Head',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'left',
                  bold: true,
                },
                {},
                {},
              ],

              [
                {
                  text: `SDH`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(imaging?.mriHead?.mriHeadSdh)}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `EDH`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(imaging?.mriHead?.mriHeadEdh)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Intra Ventricular Bleed`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    imaging?.mriHead?.mriHeadIntraVentricularBleed
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Mid Line Shift`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(imaging?.mriHead?.mriHeadMidLineShift)}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'X-ray Abdomen',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'left',
                  bold: true,
                },
                {},
                {},
              ],

              [
                {
                  text: `X-ray Abdomen`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(imaging?.xRayAbdomen?.xRayAbdomenYesNo)}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'CT Chest',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'left',
                  bold: true,
                },
                {},
                {},
              ],

              [
                {
                  text: `CT Chest`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(imaging?.ctChest?.ctChestYesNo)}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Evidence of Pneumonia`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(imaging?.ctChest?.evidenceOfPneumonia)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Evidence of Lungs Fibrosis`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(imaging?.ctChest?.evidenceOfLungsFibrosis)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Evidence of COVID`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(imaging?.ctChest?.evidenceOfCovid)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Evidence of any malignancy`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(imaging?.ctChest?.evidenceOfAnyMalignancy)}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'CT Abdomen',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'left',
                  bold: true,
                },
                {},
                {},
              ],

              [
                {
                  text: `CT Abdomen`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(imaging?.ctAbdomen?.ctAbdomenYesNo)}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Liver Size`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${imaging?.ctAbdomen?.liverSize ? imaging?.ctAbdomen?.liverSize : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `PV Size`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${imaging?.ctAbdomen?.pvSize ? imaging?.ctAbdomen?.pvSize : '-'}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `PVT`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(imaging?.ctAbdomen?.pvt)}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Echotexture`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(imaging?.ctAbdomen?.ecotexture)}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Fatty Liver`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(imaging?.ctAbdomen?.fattyLiver)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Evidence of portal hypertension`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    imaging?.ctAbdomen?.evidenceOfPortalHypertension
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Splenomegaly`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(imaging?.ctAbdomen?.splenomegaly)}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Collaterals`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(imaging?.ctAbdomen?.collaterals)}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Ascites`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(imaging?.ctAbdomen?.ascites)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `SOL in Liver `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(imaging?.ctAbdomen?.solInLiver)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `SOL in liver details`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    imaging?.ctAbdomen?.solInLiverDetails
                      ? imaging?.ctAbdomen?.solInLiverDetails
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Loss of CMD`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(imaging?.ctAbdomen?.lossOfCmd)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Loss of CMD details `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    imaging?.ctAbdomen?.lossOfCmdDetails
                      ? imaging?.ctAbdomen?.lossOfCmdDetails
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Any lesion in Kidney`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(imaging?.ctAbdomen?.anyLesionInKidney)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Any lesion in kidney details`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    imaging?.ctAbdomen?.anyLesionInKidneyDetails
                      ? imaging?.ctAbdomen?.anyLesionInKidneyDetails
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Any other abnormal finding`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(imaging?.ctAbdomen?.anyOtherAbnormalFinding)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Any other abnormal finding details`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    imaging?.ctAbdomen?.anyOtherAbnormalFindingDetails
                      ? imaging?.ctAbdomen?.anyOtherAbnormalFindingDetails
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: 'MRI Abdomen',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'left',
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: `MRI Abdomen`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                  bold: true,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(imaging?.mriAbdomen?.mriAbdomenYesNo)}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Liver Size`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${imaging?.mriAbdomen?.liverSize ? imaging?.mriAbdomen?.liverSize : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `PV Size`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${imaging?.mriAbdomen?.pvSize ? imaging?.mriAbdomen?.pvSize : '-'}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `PVT`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(imaging?.mriAbdomen?.pvt)}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Echotexture`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(imaging?.mriAbdomen?.ecotexture)}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Fatty Liver`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(imaging?.mriAbdomen?.fattyLiver)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Evidence of portal hypertension`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(
                    imaging?.mriAbdomen?.evidenceOfPortalHypertension
                  )}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Splenomegaly`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(imaging?.mriAbdomen?.splenomegaly)}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Collaterals`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(imaging?.mriAbdomen?.collaterals)}`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Ascites`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(imaging?.mriAbdomen?.ascites)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `SOL in Liver`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(imaging?.mriAbdomen?.solInLiver)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `SOL in liver details`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    imaging?.mriAbdomen?.solInLiverDetails
                      ? imaging?.mriAbdomen?.solInLiverDetails
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Loss of CMD`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(imaging?.mriAbdomen?.lossOfCmd)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Loss of CMD details `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    imaging?.mriAbdomen?.lossOfCmdDetails
                      ? imaging?.mriAbdomen?.lossOfCmdDetails
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Any lesion in Kidney`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(imaging?.mriAbdomen?.anyLesionInKidney)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Any lesion in kidney details`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    imaging?.mriAbdomen?.anyLesionInKidneyDetails
                      ? imaging?.mriAbdomen?.anyLesionInKidneyDetails
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Any other abnormal finding`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(imaging?.mriAbdomen?.anyOtherAbnormalFinding)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Any other abnormal finding details`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    imaging?.mriAbdomen?.anyOtherAbnormalFindingDetails
                      ? imaging?.mriAbdomen?.anyOtherAbnormalFindingDetails
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Any Report Attachments`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${imaging?.reports[0]?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: 'Attachments',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'center',
                  bold: true,
                },
                {},
                {},
              ],

              [
                {
                  text: `Form 8 - Declaration cum Consent `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${donorAttachmentsDetails?.form8Report?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Form 10 - For Certification of Brain Stem Death`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${donorAttachmentsDetails?.form10Report?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Admission Notes`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${donorAttachmentsDetails?.admissionReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Clinical History and Medical Summary`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    donorAttachmentsDetails?.clinicalHistoryReport?.name ? 'ATTACHED' : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: ` ICU Chart (Images Clicked / Copies for all days)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${donorAttachmentsDetails?.icuChartReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Investigation Chart / Table`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    donorAttachmentsDetails?.investigationChartReport?.name ? 'ATTACHED' : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Histopathology and other reports relevant to the case`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    donorAttachmentsDetails?.imagingHistopathologyEndoscopiesReport?.name
                      ? 'ATTACHED'
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Bronchoscopy Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${donorAttachmentsDetails?.angiographyReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Culture Reports`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${_yesNoAndEmptyFormatter(donorAttachmentsDetails?.cultureReportYesNo)}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Types of Culture Report`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    donorAttachmentsDetails?.cultureReportType
                      ? donorAttachmentsDetails?.cultureReportType
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Culture Report Status`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    donorAttachmentsDetails?.cultureReportStatus
                      ? donorAttachmentsDetails?.cultureReportStatus
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Culture Reports`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${donorAttachmentsDetails?.cultureReport?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
            ],
          },
        },
        {
          text: 'Any Other Attachment Reports',
          style: 'subHeader',
        },
        {
          style: 'table',
          table: {
            headerRows: 1,
            widths: ['*', '*'],
            body: [
              [
                { text: 'Attachment Name', style: 'tableHeader', alignment: 'left' },
                { text: 'Report', style: 'tableHeader', alignment: 'left' },
              ],
              ...donorAttachmentsDetails?.anyReports?.map((e) => [
                {
                  text: `${e.attachmentName ? e.attachmentName : '-'}`,
                  style: 'tableCell',
                  alignment: 'left',
                },
                {
                  text: `${e.attachment?.name ? 'ATTACHED' : '-'}`,
                  style: 'tableCell',
                  alignment: 'left',
                },
              ]),
            ],
          },
        },
        {
          style: 'table',
          table: {
            widths: [250, 100, 150, 'auto', 'auto'],
            heights: [
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
            ],
            body: [
              [
                {
                  text: 'Organ Retrieval Information',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'center',
                  bold: true,
                },
                {},
                {},
              ],
              // [
              //   {
              //     text: `List of Organs consented for Retrieval :- `,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${retrievedOrgans ? (retrievedOrgans || '').join(' , ') : '-'}`,
              //     colSpan: 3,
              //   },
              //   {},
              // ],
              [
                {
                  text: `Any other Organs or tissues retrieved`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    donorOrganRetrievalDetails?.retrievedOtherOrgans
                      ? donorOrganRetrievalDetails?.retrievedOtherOrgans
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              ...(donorOrganRetrievalDetails?.retrievedOrgans.some(
                (organ) => organ.organType === 'LIVER'
              ) || retrievedOrgans.includes('LIVER')
              ? [
              [
                {
                  text: `Does the donor meet the liver splitting criteria`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    donorOrganRetrievalDetails?.liverSplittingCriteria?.meetSplittingCriteria
                      ? donorOrganRetrievalDetails?.liverSplittingCriteria?.meetSplittingCriteria
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `ICU stay ≤5 days`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    donorOrganRetrievalDetails?.liverSplittingCriteria?.icu
                      ? donorOrganRetrievalDetails?.liverSplittingCriteria?.icu
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `SGOT (AST) / SGPT (ALT) ≤ 3x ULN \n (upper limit of normal) `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    donorOrganRetrievalDetails?.liverSplittingCriteria?.sgot
                      ? donorOrganRetrievalDetails?.liverSplittingCriteria?.sgot
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `On a single or no vasopressor`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    donorOrganRetrievalDetails?.liverSplittingCriteria?.vasopresser
                      ? donorOrganRetrievalDetails?.liverSplittingCriteria?.vasopresser
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
            ]
              : []),
              ...(donorOrganRetrievalDetails?.retrievedOrgans.some(
                (organ) => organ.organType === 'KIDNEY' 
              ) || retrievedOrgans.includes('KIDNEY') 
              ? [
              [
                {
                  text: `Side of kidney compatible for donation`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    donorOrganRetrievalDetails?.sideOfKidneyCompatibleForDonation
                      ? donorOrganRetrievalDetails?.sideOfKidneyCompatibleForDonation
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
            ]: []),
            ...(donorOrganRetrievalDetails?.retrievedOrgans.some(
              (organ) => organ.organType === 'RIGHT HAND'
            ) || retrievedOrgans.includes('RIGHT HAND')
            ? [
              [
                {
                  text: `Level of Retrieval from Right Hand`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    donorOrganRetrievalDetails?.levelOfRetrivalFromRightHand
                      ? donorOrganRetrievalDetails?.levelOfRetrivalFromRightHand
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
            ]:[]),
            ...(donorOrganRetrievalDetails?.retrievedOrgans.some(
              (organ) => organ.organType === 'LEFT HAND'
            ) || retrievedOrgans.includes('LEFT HAND')
            ? [
              [
                {
                  text: `Level of Retrieval from Left Hand`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    donorOrganRetrievalDetails?.levelOfRetrivalFromLeftHand
                      ? donorOrganRetrievalDetails?.levelOfRetrivalFromLeftHand
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
            ]:[]),
            ...(retrievedOrgans.includes('INTESTINE')
            ? [
              [
                {
                  text: `Does the Patient Abdominal Wall Harvested?`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    donorOrganRetrievalDetails?.abdominalWallHarvested
                      ? donorOrganRetrievalDetails?.abdominalWallHarvested
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
            ]:[]),
              // [
              //   {
              //     text: `Is any donated organs under ECD (Extended Criteria Donor) ? `,
              //     style: 'tableHeader',
              //     alignment: 'left',
              //     colSpan: 1,
              //   },

              //   {
              //     text: `${
              //       donorOrganRetrievalDetails.anyEcdOrgans
              //         ? _yesNoAndEmptyFormatter(donorOrganRetrievalDetails.anyEcdOrgans)
              //         : '-'
              //     }`,
              //     colSpan: 2,
              //   },
              //   {},
              // ],
              [
                {
                  text: `List of Organs under ECD (Extended Criteria Donor)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    donorOrganRetrievalDetails.ecdOrgans
                      ? donorOrganRetrievalDetails.ecdOrgans
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Is Donor under DCD (Organ Donation after Circulatory Death) ?`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                       _yesNoAndEmptyFormatter(donorOrganRetrievalDetails.anyDcdOrgans)
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: ` Donor Death Certificate`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${donorOrganRetrievalDetails.deathCertificate?.name ? 'ATTACHED' : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Retrieval Organ Date and Time: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${
                    donorOrganRetrievalDetails?.retrievalOrganTime
                      ? donorOrganRetrievalDetails?.retrievalOrganTime.replace('T', ', ')
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Body Handover Date and Time: `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },
                {
                  text: `${
                    donorOrganRetrievalDetails?.bodyHandoverTime
                      ? donorOrganRetrievalDetails?.bodyHandoverTime.replace('T', ', ')
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  pageBreak: 'before',
                  text: 'Extended Criteria Donor Information',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'center',
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: 'Liver ECD (Extended Criteria Donor) Information',
                  style: 'tableHeader',
                  colSpan: 3,
                  alignment: 'left',
                  bold: true,
                },
                {},
                {},
              ],
              [
                {
                  text: `Macrosteatosis(in %)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    ecdInformation?.liverEcd?.macrosteatosis
                      ? ecdInformation?.liverEcd?.macrosteatosis
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `High Ionotropes `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                       _yesNoAndEmptyFormatter(ecdInformation?.liverEcd?.highInotrope)
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Dopamine(micrograms/kg/min)                  `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    ecdInformation?.liverEcd?.dopamine ? ecdInformation?.liverEcd?.dopamine : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],

              [
                {
                  text: `Noradrenaline (micrograms/kg/min)                  `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    ecdInformation?.liverEcd?.noradrenaline
                      ? ecdInformation?.liverEcd?.noradrenaline
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Adrenaline (micrograms/kg/min)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    ecdInformation?.liverEcd?.adrenaline
                      ? ecdInformation?.liverEcd?.adrenaline
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Vasopressin (units/hour)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    ecdInformation?.liverEcd?.vasopressin
                      ? ecdInformation?.liverEcd?.vasopressin
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Transaminitis `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                       _yesNoAndEmptyFormatter(ecdInformation?.liverEcd?.transaminitis)
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Transaminitis Options `,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    ecdInformation?.liverEcd?.transaminitisSelect
                      ? ecdInformation?.liverEcd?.transaminitisSelect
                      : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Cholestatic Liver: Bilirubin (mg/dL)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    ecdInformation?.liverEcd?.bilirubin ? ecdInformation?.liverEcd?.bilirubin : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Positive blood culture within last 5 days`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                       _yesNoAndEmptyFormatter(ecdInformation?.liverEcd?.positiveBloodCulture)
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Expected Cold Ischemia Time (CIT) (in Hours)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${ecdInformation?.liverEcd?.cit ? ecdInformation?.liverEcd?.cit : '-'}`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Partial Graft`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                       _yesNoAndEmptyFormatter(ecdInformation?.liverEcd?.partialGraft)
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Split Graft`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                       _yesNoAndEmptyFormatter(ecdInformation?.liverEcd?.splitGraft)
                  }`,
                  colSpan: 2,
                },
                {},
              ],
              [
                {
                  text: `Last pre-retrieval serum Sodium (mEq/L)`,
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 1,
                },

                {
                  text: `${
                    ecdInformation?.liverEcd?.sodium ? ecdInformation?.liverEcd?.sodium : '-'
                  }`,
                  colSpan: 2,
                },
                {},
              ],
            ],
          },
        },

        {
          style: 'table',
          table: {
            widths: [250, 100, 150, 'auto'],
            heights: [40, 50],
            body: [
              [
                {
                  text: 'Administrative Head of the Hospital:',
                  style: 'tableHeader',
                  alignment: 'left',
                  colSpan: 3,
                },

                // {
                //   text: 'Patient Sign:',
                //   colSpan: 2,
                // },
                {},
                {},
              ],
              [
                { text: 'Doctor Signature', colSpan: 3, alignment: 'left' },
                {},

                {
                  text: '',
                },
              ],
            ],
          },
        },
      ],
      defaultStyle: {
        fontSize: 10,
      },
      styles: {
        header: {
          fontSize: 16,
          // bold: true,
          margin: [0, 30, 0, 10],
          alignment: 'center',
        },
        table: {
          margin: [0, 5, 0, 15],
          // fontSize: 16,
        },
        textStyle: {
          alignment: 'center',
        },
      },
    };

    this.reportDocDefinition = reportDocDefinition;
  }

  docDefinition() {
    const documentDefinition = this.reportDocDefinition;
    return pdfMake.createPdf(documentDefinition);
  }

  download(file) {
    const fileName = file || `Donor Registration Form`;
    const documentDefinition = this.reportDocDefinition;
    pdfMake.createPdf(documentDefinition).download(fileName);
  }

  getBuffer(cb = () => {}) {
    const documentDefinition = this.reportDocDefinition;
    return pdfMake.createPdf(documentDefinition).getBuffer(cb);
  }
}

export default DonorFormPdf;
