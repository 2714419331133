import React, { useState, useEffect, memo } from 'react';
import { compose } from 'redux';
import {
  makeStyles,
  Paper,
  Box,
  Button,
  Typography,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControlLabel,
  Checkbox,
  IconButton,
} from '@material-ui/core';
import CloseIcon from 'mdi-react/CloseIcon';
import moment from 'moment';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Document, Page } from 'react-pdf';
import DownloadIcon from 'mdi-react/DownloadIcon';
import Joi from 'joi';
import PropTypes from 'prop-types';
import { withRouter, useRouteMatch } from 'react-router-dom';
import cloneDeep from 'lodash.clonedeep';
import { useSnackbar } from 'notistack';
import Skeleton from '@material-ui/lab/Skeleton';
import { ReactComponent as HeartSvg } from '../svgs/Heart.svg';
import { ReactComponent as KidneySvg } from '../svgs/Kidney.svg';
import { ReactComponent as IntestineSvg } from '../svgs/Intestine.svg';
import { ReactComponent as SkinSvg } from '../svgs/Skin.svg';
import { ReactComponent as LiverSvg } from '../svgs/Liver.svg';
import { ReactComponent as LungsSvg } from '../svgs/Lungs.svg';
import { ReactComponent as PancreasSvg } from '../svgs/Pancreas.svg';
import { ReactComponent as GovernmentSvg } from '../svgs/Government.svg';
import { ReactComponent as BoneSvg } from '../svgs/Bone.svg';
import { ReactComponent as CorneaSvg } from '../svgs/Cornea.svg';
import ValidatedInput from '../Custom/ValidatedInput';
import ValidatedSelect from '../Custom/ValidatedSelect';
import { ValidatedDate } from '../Custom';
import {
  VALIDATION,
  BLOODGROUP,
  IMGDIMENSIONS,
  REGEXP_MOBILE,
  REGEXP_AADHAAR,
  REGEXP_PINCODE,
  REGEXP_ONLYCHARS,
  SEX,
  NEAR_RELATION_TYPE,
} from '../../constants';
import { STATE_IN } from '../../statesIndia';
import { districts } from '../../districts/districts';
import { withValidation } from '../../hoc';
import { useCancellableApiCall } from '../../hooks';
import {
  PATH_PUBLIC_ADDDONORPLEDGEFORM,
  PATH_OTP_REQUESTOTP,
  PATH_EXP_GETPLEDGEDDONOR,
  PATH_PUBLIC_GETHOSPITALFORFORM7,
} from '../../appConstants';
import { DonorPledgePdf } from '../Reports/DocDefinition';
import PhotoUpload from '../Custom/PhotoUpload';
import LoadableButton from '../Custom/LoadableButton';
import StyledDialogTitle from '../common/StyledDialogTitle';

const EMPTY_DONORFORM = {
  referringHospital: '',
  organs: [],
  donorDetails: {
    firstName: '',
    middleName: '',
    lastName: '',
    sex: '',
    nearRelationType: '',
    nearRelativeName: '',
    dateOfBirth: null,
    bloodGroup: '',
    uid: '',
    telephone: '',
    address: {
      line1: '',
      district: '',
      state: '',
      pinCode: '',
    },
    email: '',
    signature: '',
    photo: '',
  },
  witness1Details: {
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: null,
    nearRelationType: '',
    nearRelativeName: '',
    relationWithDonor: '',
    uid: '',
    telephone: '',
    address: {
      line1: '',
      district: '',
      state: '',
      pinCode: '',
    },
    email: '',
    signature: '',
  },
  witness2Details: {
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: null,
    nearRelationType: '',
    nearRelativeName: '',
    relationWithDonor: '',
    uid: '',
    telephone: '',
    address: {
      line1: '',
      district: '',
      state: '',
      pinCode: '',
    },
    email: '',
    signature: '',
  },
  formStatus: 'NEW',
};
const EMPTY_REFERRINGHOSP = {
  _id: '',
  hospitalId: '',
  hospitalName: '',
};

const EMPTY_OTPDATA = {
  contact: '',
  hash: '',
  otp: '',
};
const MemoizedDocument = memo(function MemoizedDocument({ documentBuffer }) {
  const [numPages, setNumPages] = useState(1);
  if (!documentBuffer) {
    return null;
  }
  return (
    <Grid
      container
      item
      sm={12}
      justify="center"
      style={{ height: 500, overflowY: 'scroll', background: 'grey' }}
    >
      <Document
        file={{
          data: documentBuffer,
        }}
        onLoadSuccess={({ numPages: pdfNumPages }) => {
          if (numPages !== pdfNumPages) {
            setNumPages(pdfNumPages);
          }
        }}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document>
    </Grid>
  );
});
MemoizedDocument.propTypes = {
  documentBuffer: PropTypes.objectOf(PropTypes.any),
};
MemoizedDocument.defaultProps = {
  documentBuffer: '',
};

function Form7(props) {
  const [form7, setForm7] = useState(cloneDeep(EMPTY_DONORFORM));
  const [referringHospital, setReferringHosital] = useState(cloneDeep(EMPTY_REFERRINGHOSP));
  const [isHospitalLoading, setIsHospitalLoading] = useState(false);
  const [getOtpDialog, setGetOtpDialog] = useState(false);
  const [submitDialogOpenStatus, setSubmitDialogOpenStatus] = useState(false);
  const [otpData, setOtpData] = useState(cloneDeep(EMPTY_OTPDATA));
  const [form7PreviewBuffer, setForm7PreviewBuffer] = useState();
  const [form7PreviewDialog, setForm7PreviewDialog] = useState(false);
  const [isWitness1AddrSameAsDonor, setIsWitness1AddrSameAsDonor] = useState(false);
  const [isWitness2AddrSameAsDonor, setIsWitness2AddrSameAsDonor] = useState(false);
  const [check, setCheck] = useState({
    checkAgree: false,
  });

  const _isDisabled = () => {
    return form7?.formStatus === 'SUBMITTED';
  };

  const [selectOrganDialogStatus, setSelectOrganDialogStatus] = useState(false);

  const _renderNoOrganTypeAddedDialog = () => {
    return (
      <Dialog
        open={selectOrganDialogStatus}
        onClose={() => {
          setSelectOrganDialogStatus(false);
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle style={{ backgroundColor: 'red', color: '#F0FEF9' }}>
          <Grid container justify="space-between" alignItems="center">
            Organ not selected
            <IconButton
              edge="start"
              color="#F0FEF9"
              onClick={() => {
                setSelectOrganDialogStatus(false);
              }}
              aria-label="close"
            >
              <CloseIcon
                style={{
                  color: '#F0FEF9',
                }}
              />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ margin: 20 }}>
          <Grid container spacing={2}>
            <Typography>Please select organ to proceed further</Typography>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadableButton
            variant="contained"
            color="primary"
            onClick={() => {
              setSelectOrganDialogStatus(false);
            }}
            disabledButton
          >
            Okay
          </LoadableButton>
        </DialogActions>
      </Dialog>
    );
  };

 
  const handleChange = (event) => {
    setCheck({ ...check, [event.target.name]: event.target.checked });
  };

  const { apiPost, apiGet, apiCallStatuses } = useCancellableApiCall();
  const { enqueueSnackbar } = useSnackbar();

  const recordMatch = useRouteMatch('/form7/record/:form7Id');
  const paramsString = window.location.search;
  const params = new URLSearchParams(paramsString);
  const { validation, onValidation } = props;

  useEffect(() => {
    if (!recordMatch) {
      return;
    }
    const {
      params: { form7Id },
    } = recordMatch;
    apiGet(PATH_EXP_GETPLEDGEDDONOR, {
      form7Id,
    })
      .then((form7Record) => {
        setForm7({ ...form7Record });
      })
      .catch(() => {});
  }, [apiGet]);

  const isPublicForm = () => {
    return window.location.pathname === '/newdonor';
  };

  useEffect(() => {
    const hospitalId = params.get('hospitalId');
    if (!isPublicForm() || !hospitalId) {
      return;
    }
    setIsHospitalLoading(true);
    // TODO ADD HOSPITAL CODE HERE
    apiGet(
      PATH_PUBLIC_GETHOSPITALFORFORM7,
      {
        hospitalId,
      },
      { unAuthenticated: true }
    )
      .then((res) => {
        setReferringHosital(res);
        setForm7({ ...form7, referringHospital: res._id });
      })
      .finally(() => {
        setIsHospitalLoading(false);
      });
  }, [apiGet]);

  const useStyles = makeStyles(() => ({
    paperTransplantInfo: {
      background: '#F0FEF9',
      border: '1px solid #B1E7D3',
      borderRadius: '4px',
      marginTop: 20,
      marginBottom: 10,
    },
    gridTransplantDetails: {
      paddingLeft: 24,
      paddingTop: 30,
      paddingRight: 24,
      paddingBottom: 20,
    },
    paperTransplantSections: {
      background: '#F0FEF9',
      border: '1px solid #B1E7D3',
      borderRadius: '4px 0px 0px 4px',
      height: '100%',
    },
    transplantSectionsStyle: {
      fontWeight: 300,
      lineHeight: '15px',
      marginTop: 20,
      marginLeft: 20,
    },
    transplantSectionsHeadingStyle: {
      fontWeight: 600,
      fontSize: '14px',
      marginLeft: 20,
    },
    transplantDetailsHeading: {
      fontWeight: 600,
      fontSize: '16px',
      color: '#34495E',
      marginTop: '5px',
    },
    form12BoxStyle: {
      direction: 'row',
      ...(_isDisabled() ? { marginTop: 90 } : {}),
    },
  }));

  const classes = useStyles();
  const [getOtpApiId, setGetOtpApiId] = useState('');
  const getOtp = () => {
    apiGet(
      PATH_OTP_REQUESTOTP,
      { mobileNo: form7.donorDetails.telephone },
      {
        unAuthenticated: true,
        apiIdCb: (apiId) => {
          setGetOtpApiId(apiId);
        },
      }
    )
      .then((fullHash) => {
        setOtpData({
          ...otpData,
          hash: fullHash,
          contact: form7.donorDetails.telephone,
        });
        setForm7PreviewDialog(false);
        setGetOtpDialog(true);
      })
      .catch(() => {});
  };

  const _renderForm7PreviewDialog = () => {
    return (
      <Dialog
        open={form7PreviewDialog}
        fullWidth={true}
        maxWidth="md"
        onClose={() => {
          setForm7PreviewDialog(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Donor Form Preview</DialogTitle>
        <DialogContent>
          <Grid container item xs={12}>
            <Typography variant="body1">
              Please check the &apos;I agree...&apos; box below the form after verifying your
              details then click on &apos;Get OTP and Submit&apos; button. After clicking the
              button, we will send a confirmation OTP on the Donor Email provided in the form
            </Typography>
          </Grid>
          <Grid container item xs={12} justify="center" style={{ backgroundColor: '#D3D3D3' }}>
            {form7PreviewBuffer ? (
              <MemoizedDocument documentBuffer={form7PreviewBuffer} />
            ) : (
              <Typography variant="body1">LOADING</Typography>
            )}
          </Grid>
          <Grid container item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={check.checkAgree}
                  onChange={handleChange}
                  name="checkAgree"
                  color="primary"
                />
              }
              label="I agree with the Information provided in the form and acknowledge it. Also above depicted is my valid signature."
            />
          </Grid>
        </DialogContent>

        <DialogActions style={{ marginTop: 40 }}>
          <LoadableButton
            apiCallId={getOtpApiId}
            apiCallStatuses={apiCallStatuses}
            onClick={() => setForm7PreviewDialog(false)}
            disabledButton
          >
            Edit Form
          </LoadableButton>
          <LoadableButton
            apiCallId={getOtpApiId}
            apiCallStatuses={apiCallStatuses}
            loadingText="Requesting..."
            color="primary"
            disabled={!check.checkAgree}
            onClick={() => {
              if (check.checkAgree) {
                getOtp();
              }
            }}
          >
            Get OTP and Submit
          </LoadableButton>
        </DialogActions>
      </Dialog>
    );
  };

  const openForm7PreviewDialog = () => {
    const tissues = ['CORNEA', 'SKIN', 'BONES','HEARTVALVES' , 'BLOODVESSELS']
    const otherOrgans = ['HEART', 'KIDNEY', 'LIVER', 'LUNGS', 'PANCREA']

    const { validate } = props;
    validate(VALIDATION.ALL | VALIDATION.INVALIDATE_EMPTY, (valid) => {
      let isOrgan = form7.organs.some( o => otherOrgans.includes(o))

    if(!isOrgan){
        setSelectOrganDialogStatus(true)
      }
      else if (!valid) {
        enqueueSnackbar('Please check the red fields', { variant: 'error' });
        return;
      }else{
        new DonorPledgePdf(form7).getBuffer((buffer) => {
          setForm7PreviewBuffer(buffer);
        });
        setForm7PreviewDialog(true);
      }
      
    });
  };

  const [donorProfileUrl, setDonorProfileUrl] = useState('');
  const [submitForm7ApiId, setSubmitForm7ApiId] = useState('');
  
  const _submitForm7 = () => {
    const { hospitalName, transplantCoordinators } = form7;
    const { validate } = props;
    validate(VALIDATION.ALL | VALIDATION.INVALIDATE_EMPTY, (valid) => {
      if (!valid) {
        return;
      }
      apiPost(
        PATH_PUBLIC_ADDDONORPLEDGEFORM,
        {
          formData: {
            ...form7,
            hospitalName,
            transplantCoordinators,
          },
          otpData,
        },
        {
          unAuthenticated: true,
          apiIdCb: (apiId) => {
            setSubmitForm7ApiId(apiId);
          },
        }
      )
        .then((res) => {
          validate(VALIDATION.OFF, () => {
            setDonorProfileUrl(res);
            setForm7(cloneDeep(EMPTY_DONORFORM));
            setSubmitDialogOpenStatus(true);
            setGetOtpDialog(false);
            setOtpData(cloneDeep(EMPTY_OTPDATA));
          });
        })
        .catch(() => {});
    });
  };

  const _renderGetOtpDialog = () => {
    return (
      <Dialog
        open={getOtpDialog}
        fullWidth={true}
        maxWidth="sm"
        onClose={() => {
          setGetOtpDialog(false);
          setOtpData(cloneDeep(EMPTY_OTPDATA));
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Submission OTP</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} style={{ marginBottom: '20px' }}>
              <TextField
                required
                id="otp"
                name="otp"
                label="OTP received on donor mobile"
                helperText="Please check donor mobile for OTP"
                fullWidth
                value={otpData.otp}
                onChange={(e) => {
                  setOtpData({
                    ...otpData,
                    otp: e.target.value,
                  });
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions style={{ marginTop: 40 }}>
          <LoadableButton
            apiCallId={submitForm7ApiId}
            apiCallStatuses={apiCallStatuses}
            onClick={() => {
              setGetOtpDialog(false);
            }}
            disabledButton
          >
            Cancel
          </LoadableButton>
          <LoadableButton
            apiCallId={submitForm7ApiId}
            apiCallStatuses={apiCallStatuses}
            loadingText="Submitting..."
            color="primary"
            onClick={() => {
              _submitForm7();
            }}
          >
            Submit
          </LoadableButton>
        </DialogActions>
      </Dialog>
    );
  };

  const _renderSubmitDialog = () => {
    return (
      <Dialog
        open={submitDialogOpenStatus}
        onClose={() => {
          setSubmitDialogOpenStatus(false);
        }}
        maxWidth="sm"
        fullWidth
      >
        <StyledDialogTitle>Thank you!</StyledDialogTitle>
        <DialogContent style={{ margin: 20 }}>
          <Grid container item sm={12}>
            <Typography variant="body1">
              The Donation of your organs to someone in need truly makes you a hero. Thank you for
              your pledge to donate the organs.
            </Typography>
            <Typography variant="body1">
              You can download your donor card anytime at your donor profile page at{' '}
              <a href={donorProfileUrl}>{donorProfileUrl}</a>
            </Typography>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setSubmitDialogOpenStatus(false);
              window.location.reload();
            }}
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const _renderForm7FooterButtons = () => {
    if (_isDisabled()) {
      return null;
    }

    return (
      <Box>
        <Grid container justify="center" style={{ paddingBottom: 20 }} spacing={1}>
          <Grid item>
            <LoadableButton
              color="primary"
              variant="contained"
              onClick={() => {
                openForm7PreviewDialog();
              }}
            >
              Preview and Submit
            </LoadableButton>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const _renderForm7 = () => {
    const {
      organs,
      donorDetails,
      donorDetails: { address: donorAddress },
      witness1Details,
      witness1Details: { address: witness1Address },
      witness2Details,
      witness2Details: { address: witness2Address },
    } = form7;
    return (
      <Grid item xs={12} style={{ marginTop: 10 }}>
        <Paper>
          <Grid container>
            <Grid container item xs={12} direction="row" className={classes.gridTransplantDetails}>
              <Grid container item xs={12} justify="space-between" style={{ marginBottom: 5, marginTop: 40 }}>
                <Grid item sm={12} style={{ borderLeft: '5px solid #4EA95E' }}>
                  <Typography variant="body1" className={classes.transplantDetailsHeading}>
                    Donor Pledge (Form 7){' '}
                    {_isDisabled() && (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          new DonorPledgePdf(form7).download();
                        }}
                      >
                        <DownloadIcon /> Download Form
                      </Button>
                    )}
                  </Typography>
                  {isPublicForm() ? (
                    <Typography variant="body1">
                      {isHospitalLoading ? (
                        <Skeleton />
                      ) : (
                        <strong>{referringHospital?.hospitalName}</strong>
                      )}
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>
              <Grid item style={{ marginTop: 20 }}>
                <Typography variant="body1" className={classes.transplantDetailsHeading}>
                  Organs
                </Typography>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: 20 }}>
                <Grid item sm={2} xs={6}>
                  <Button
                    startIcon={<HeartSvg />}
                    variant={organs.includes('HEART') ? 'contained' : 'outlined'}
                    color="primary"
                    fullWidth
                    style={{
                      height: 40,
                    }}
                    disabled={_isDisabled()}
                    onClick={() => {
                      let updatedOrgans;
                      if (organs.includes('HEART')) {
                        updatedOrgans = organs.filter((o) => o !== 'HEART');
                        setForm7({ ...form7, organs: [...updatedOrgans] });
                      } else {
                        setForm7({ ...form7, organs: [...organs, 'HEART'] });
                      }
                    }}
                  >
                    Heart
                  </Button>
                </Grid>
                <Grid item sm={2} xs={6}>
                  <Button
                    startIcon={<KidneySvg />}
                    variant={organs.includes('KIDNEY') ? 'contained' : 'outlined'}
                    color="primary"
                    fullWidth
                    style={{
                      height: 40,
                    }}
                    disabled={_isDisabled()}
                    onClick={() => {
                      let updatedOrgans;
                      if (organs.includes('KIDNEY')) {
                        updatedOrgans = organs.filter((o) => o !== 'KIDNEY');
                        setForm7({ ...form7, organs: [...updatedOrgans] });
                      } else {
                        setForm7({ ...form7, organs: [...organs, 'KIDNEY'] });
                      }
                    }}
                  >
                    Kidney
                  </Button>
                </Grid>
                <Grid item sm={2} xs={6}>
                  <Button
                    startIcon={<LiverSvg />}
                    variant={organs.includes('LIVER') ? 'contained' : 'outlined'}
                    color="primary"
                    fullWidth
                    style={{
                      height: 40,
                    }}
                    disabled={_isDisabled()}
                    onClick={() => {
                      let updatedOrgans;
                      if (organs.includes('LIVER')) {
                        updatedOrgans = organs.filter((o) => o !== 'LIVER');
                        setForm7({ ...form7, organs: [...updatedOrgans] });
                      } else {
                        setForm7({ ...form7, organs: [...organs, 'LIVER'] });
                      }
                    }}
                  >
                    Liver
                  </Button>
                </Grid>
                <Grid item sm={2} xs={6}>
                  <Button
                    startIcon={<LungsSvg />}
                    variant={organs.includes('LUNGS') ? 'contained' : 'outlined'}
                    color="primary"
                    fullWidth
                    style={{
                      height: 40,
                    }}
                    disabled={_isDisabled()}
                    onClick={() => {
                      let updatedOrgans;
                      if (organs.includes('LUNGS')) {
                        updatedOrgans = organs.filter((o) => o !== 'LUNGS');
                        setForm7({ ...form7, organs: [...updatedOrgans] });
                      } else {
                        setForm7({ ...form7, organs: [...organs, 'LUNGS'] });
                      }
                    }}
                  >
                    Lungs
                  </Button>
                </Grid>
                <Grid item sm={2} xs={6}>
                  <Button
                    startIcon={<PancreasSvg />}
                    variant={organs.includes('PANCREA') ? 'contained' : 'outlined'}
                    color="primary"
                    fullWidth
                    style={{
                      height: 40,
                    }}
                    disabled={_isDisabled()}
                    onClick={() => {
                      let updatedOrgans;
                      if (organs.includes('PANCREA')) {
                        updatedOrgans = organs.filter((o) => o !== 'PANCREA');
                        setForm7({ ...form7, organs: [...updatedOrgans] });
                      } else {
                        setForm7({ ...form7, organs: [...organs, 'PANCREA'] });
                      }
                    }}
                  >
                    Pancrea
                  </Button>
                </Grid>
                <Grid item sm={2} xs={6}>
                  <Button
                    startIcon={<HeartSvg />}
                    variant={
                      organs.includes('HEART') &&
                      organs.includes('KIDNEY') &&
                      organs.includes('LIVER') &&
                      organs.includes('LUNGS') &&
                      organs.includes('PANCREA')
                        ? 'contained'
                        : 'outlined'
                    }
                    color="primary"
                    style={{
                      height: 40,
                    }}
                    disabled={_isDisabled()}
                    onClick={() => {
                      if (
                        organs.includes('HEART') &&
                        organs.includes('KIDNEY') &&
                        organs.includes('LIVER') &&
                        organs.includes('LUNGS') &&
                        organs.includes('PANCREA')
                      ) {
                        const updatedOrgans = [];

                        organs.forEach((o) => {
                          if (
                            o !== 'HEART' &&
                            o !== 'KIDNEY' &&
                            o !== 'LIVER' &&
                            o !== 'LUNGS' &&
                            o !== 'PANCREA'
                          ) {
                            updatedOrgans.push(o);
                          }
                        });
                        setForm7({
                          ...form7,
                          organs: [...updatedOrgans],
                        });
                      } else {
                        const updatedOrgans = [];
                        ['HEART', 'KIDNEY', 'LIVER', 'LUNGS', 'PANCREA'].forEach((o) => {
                          if (!organs.includes(o)) {
                            updatedOrgans.push(o);
                          }
                        });
                        setForm7({
                          ...form7,
                          organs: [...organs, ...updatedOrgans],
                        });
                      }
                    }}
                  >
                    All Organs
                  </Button>
                </Grid>
              </Grid>
              <Grid item style={{ marginTop: 20 }}>
                <Typography variant="body1" className={classes.transplantDetailsHeading}>
                  Tissues
                </Typography>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: 10 }}>
                <Grid item sm={2} xs={6}>
                  <Button
                    startIcon={<CorneaSvg />}
                    variant={organs.includes('CORNEA') ? 'contained' : 'outlined'}
                    color="primary"
                    fullWidth
                    style={{
                      height: 40,
                    }}
                    disabled={_isDisabled()}
                    onClick={() => {
                      let updatedOrgans;
                      if (organs.includes('CORNEA')) {
                        updatedOrgans = organs.filter((o) => o !== 'CORNEA');
                        setForm7({ ...form7, organs: [...updatedOrgans] });
                      } else {
                        setForm7({ ...form7, organs: [...organs, 'CORNEA'] });
                      }
                    }}
                  >
                    Cornea
                  </Button>
                </Grid>
                <Grid item sm={2} xs={6}>
                  <Button
                    startIcon={<SkinSvg />}
                    variant={organs.includes('SKIN') ? 'contained' : 'outlined'}
                    color="primary"
                    fullWidth
                    style={{
                      height: 40,
                    }}
                    disabled={_isDisabled()}
                    onClick={() => {
                      let updatedOrgans;
                      if (organs.includes('SKIN')) {
                        updatedOrgans = organs.filter((o) => o !== 'SKIN');
                        setForm7({ ...form7, organs: [...updatedOrgans] });
                      } else {
                        setForm7({ ...form7, organs: [...organs, 'SKIN'] });
                      }
                    }}
                  >
                    Skin
                  </Button>
                </Grid>
                <Grid item sm={2} xs={6}>
                  <Button
                    startIcon={<BoneSvg />}
                    variant={organs.includes('BONES') ? 'contained' : 'outlined'}
                    color="primary"
                    fullWidth
                    style={{
                      height: 40,
                    }}
                    disabled={_isDisabled()}
                    onClick={() => {
                      let updatedOrgans;
                      if (organs.includes('BONES')) {
                        updatedOrgans = organs.filter((o) => o !== 'BONES');
                        setForm7({ ...form7, organs: [...updatedOrgans] });
                      } else {
                        setForm7({ ...form7, organs: [...organs, 'BONES'] });
                      }
                    }}
                  >
                    Bones
                  </Button>
                </Grid>
                <Grid item sm={2} xs={6}>
                  <Button
                    startIcon={<HeartSvg />}
                    variant={organs.includes('HEARTVALVES') ? 'contained' : 'outlined'}
                    color="primary"
                    fullWidth
                    style={{
                      height: 40,
                    }}
                    disabled={_isDisabled()}
                    onClick={() => {
                      let updatedOrgans;
                      if (organs.includes('HEARTVALVES')) {
                        updatedOrgans = organs.filter((o) => o !== 'HEARTVALVES');
                        setForm7({ ...form7, organs: [...updatedOrgans] });
                      } else {
                        setForm7({ ...form7, organs: [...organs, 'HEARTVALVES'] });
                      }
                    }}
                  >
                    Heart Valves
                  </Button>
                </Grid>
                <Grid item sm={2} xs={6}>
                  <Button
                    startIcon={<IntestineSvg />}
                    variant={organs.includes('BLOODVESSELS') ? 'contained' : 'outlined'}
                    color="primary"
                    fullWidth
                    style={{
                      height: 40,
                      // backgroundColor: transplant.organ.organType === 'INTESTINE' ? '#25B67D' : '',
                    }}
                    disabled={_isDisabled()}
                    onClick={() => {
                      let updatedOrgans;
                      if (organs.includes('BLOODVESSELS')) {
                        updatedOrgans = organs.filter((o) => o !== 'BLOODVESSELS');
                        setForm7({ ...form7, organs: [...updatedOrgans] });
                      } else {
                        setForm7({ ...form7, organs: [...organs, 'BLOODVESSELS'] });
                      }
                    }}
                  >
                    Blood Vessels
                  </Button>
                </Grid>
                <Grid item sm={2} xs={6}>
                  <Button
                    startIcon={<HeartSvg />}
                    variant={
                      organs.includes('CORNEA') &&
                      organs.includes('SKIN') &&
                      organs.includes('BONES') &&
                      organs.includes('HEARTVALVES') &&
                      organs.includes('BLOODVESSELS')
                        ? 'contained'
                        : 'outlined'
                    }
                    color="primary"
                    style={{
                      height: 40,
                    }}
                    disabled={_isDisabled()}
                    onClick={() => {
                      if (
                        organs.includes('CORNEA') &&
                        organs.includes('SKIN') &&
                        organs.includes('BONES') &&
                        organs.includes('HEARTVALVES') &&
                        organs.includes('BLOODVESSELS')
                      ) {
                        const updatedOrgans = [];

                        organs.forEach((o) => {
                          if (
                            o !== 'CORNEA' &&
                            o !== 'SKIN' &&
                            o !== 'BONES' &&
                            o !== 'HEARTVALVES' &&
                            o !== 'BLOODVESSELS'
                          ) {
                            updatedOrgans.push(o);
                          }
                        });
                        setForm7({
                          ...form7,
                          organs: [...updatedOrgans],
                        });
                      } else {
                        const updatedOrgans = [];
                        ['CORNEA', 'SKIN', 'BONES', 'HEARTVALVES', 'BLOODVESSELS'].forEach((o) => {
                          if (!organs.includes(o)) {
                            updatedOrgans.push(o);
                          }
                        });
                        setForm7({
                          ...form7,
                          organs: [...organs, ...updatedOrgans],
                        });
                      }
                    }}
                  >
                    All Tissues
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: 10, marginBottom: 10, padding: 10 }}>
                <Grid item sm={12} style={{ borderLeft: '5px solid #4EA95E' }}>
                  <Typography variant="body1" className={classes.transplantDetailsHeading}>
                    Donor Pledger Details
                  </Typography>
                </Grid>
                {referringHospital?.hospitalName ? (
                  <>
                    <Grid item sm={4} xs={12}>
                      <ValidatedInput
                        required
                        value={referringHospital.hospitalName}
                        label="Hospital Name"
                        disabled={true}
                      />
                    </Grid>
                    <Grid item sm={8} xs={12}>
                      {referringHospital.transplantCoordinators.map((t, index) => {
                        return (
                          <ValidatedInput
                            required
                            value={t.name}
                            label={`Coordinator ${index + 1} Name`}
                            disabled={true}
                          />
                        );
                      })}
                    </Grid>
                  </>
                ) : null}

                <Grid item sm={4} xs={12} >
                  <ValidatedInput
                    required
                    value={donorDetails.firstName}
                    label="First Name"
                    onChange={(e) => {
                      setForm7({
                        ...form7,
                        donorDetails: {
                          ...donorDetails,
                          firstName: e.target.value,
                        },
                      });
                    }}
                    disabled={_isDisabled()}
                    validation={validation}
                    onValidation={onValidation}
                    ignoreChangeSchemaMode="ONLYCHARS"
                    schema={Joi.string()
                      .pattern(REGEXP_ONLYCHARS)
                      .message({ 'string.pattern.base': 'Cannot contain numbers' })}
                    fullWidth
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <ValidatedInput
                    value={donorDetails.middleName}
                    label="Middle Name"
                    onChange={(e) => {
                      setForm7({
                        ...form7,
                        donorDetails: {
                          ...donorDetails,
                          middleName: e.target.value,
                        },
                      });
                    }}
                    disabled={_isDisabled()}
                    ignoreChangeSchemaMode="ONLYCHARS"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <ValidatedInput
                    required
                    value={donorDetails.lastName}
                    label="Last Name"
                    onChange={(e) => {
                      setForm7({
                        ...form7,
                        donorDetails: {
                          ...donorDetails,
                          lastName: e.target.value,
                        },
                      });
                    }}
                    disabled={_isDisabled()}
                    validation={validation}
                    onValidation={onValidation}
                    ignoreChangeSchemaMode="ONLYCHARS"
                    schema={Joi.string()
                      .pattern(REGEXP_ONLYCHARS)
                      .message({ 'string.pattern.base': 'Cannot contain numbers' })}
                    fullWidth
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <ValidatedSelect
                    required
                    label="Gender"
                    value={donorDetails.sex}
                    options={Object.values(SEX)}
                    disabled={_isDisabled()}
                    onChange={(e) => {
                      setForm7({
                        ...form7,
                        donorDetails: {
                          ...donorDetails,
                          sex: e.value,
                        },
                      });
                    }}
                    validation={validation}
                    onValidation={onValidation}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <ValidatedDate
                    required
                    value={donorDetails.dateOfBirth}
                    label="Date of birth"
                    type="date"
                    onChange={(e) => {
                      setForm7({
                        ...form7,
                        donorDetails: {
                          ...donorDetails,
                          dateOfBirth: e,
                        },
                      });
                    }}
                    disabled={_isDisabled()}
                    onValidation={onValidation}
                    validation={validation}
                    schema={Joi.date()
                      .max(moment())
                      .message({ 'date.max': 'Cannot be in the future' })}
                    fullWidth
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <ValidatedSelect
                    required
                    label="Blood Group"
                    value={donorDetails.bloodGroup}
                    options={Object.values(BLOODGROUP)}
                    onChange={(e) => {
                      setForm7({
                        ...form7,
                        donorDetails: {
                          ...donorDetails,
                          bloodGroup: e.value,
                        },
                      });
                    }}
                    disabled={_isDisabled()}
                    validation={validation}
                    onValidation={onValidation}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <ValidatedInput
                    required
                    type="number"
                    value={donorDetails.uid}
                    label="Aadhar Number"
                    onChange={(e) => {
                      setForm7({
                        ...form7,
                        donorDetails: {
                          ...donorDetails,
                          uid: e.target.value,
                        },
                      });
                    }}
                    disabled={_isDisabled()}
                    validation={validation}
                    onValidation={onValidation}
                    schema={Joi.string()
                      .pattern(REGEXP_AADHAAR)
                      .message({ 'string.pattern.base': 'Should be a 12 digit number' })}
                    fullWidth
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <ValidatedSelect
                    required
                    label="Near Relation Type"
                    value={donorDetails.nearRelationType}
                    options={Object.values(NEAR_RELATION_TYPE)}
                    disabled={_isDisabled()}
                    onChange={(e) => {
                      setForm7({
                        ...form7,
                        donorDetails: {
                          ...donorDetails,
                          nearRelationType: e.value,
                        },
                      });
                    }}
                    validation={validation}
                    onValidation={onValidation}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <ValidatedInput
                    required
                    value={donorDetails.nearRelativeName}
                    label="Near Relative Name"
                    onChange={(e) => {
                      setForm7({
                        ...form7,
                        donorDetails: {
                          ...donorDetails,
                          nearRelativeName: e.target.value,
                        },
                      });
                    }}
                    disabled={_isDisabled()}
                    ignoreChangeSchemaMode="ONLYCHARS"
                    fullWidth
                    schema={Joi.string()
                      .pattern(REGEXP_ONLYCHARS)
                      .message({ 'string.pattern.base': 'Cannot contain numbers' })}
                    validation={validation}
                    onValidation={onValidation}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <ValidatedInput
                    required
                    value={donorDetails.telephone}
                    label="Mobile Number"
                    onChange={(e) => {
                      setForm7({
                        ...form7,
                        donorDetails: {
                          ...donorDetails,
                          telephone: e.target.value,
                        },
                      });
                    }}
                    disabled={_isDisabled()}
                    validation={validation}
                    onValidation={onValidation}
                    schema={Joi.string()
                      .pattern(REGEXP_MOBILE)
                      .message({ 'string.pattern.base': 'Should be a 10 digit number' })}
                    fullWidth
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <ValidatedInput
                    required
                    value={donorDetails.email}
                    label="Email"
                    onChange={(e) => {
                      setForm7({
                        ...form7,
                        donorDetails: {
                          ...donorDetails,
                          email: e.target.value,
                        },
                      });
                    }}
                    disabled={_isDisabled()}
                    validation={validation}
                    onValidation={onValidation}
                    schema={Joi.string().email({ tlds: { allow: false } })}
                    fullWidth
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <ValidatedInput
                    multiline
                    rows={4}
                    required
                    value={donorDetails.address ? donorDetails.address.line1 : ' '}
                    label="Address"
                    fullWidth
                    onChange={(e) => {
                      setForm7({
                        ...form7,
                        donorDetails: {
                          ...donorDetails,
                          address: {
                            ...donorAddress,
                            line1: e.target.value,
                          },
                        },
                        ...(isWitness1AddrSameAsDonor
                          ? {
                              witness1Details: {
                                ...witness1Details,
                                address: {
                                  ...witness1Address,
                                  line1: e.target.value,
                                },
                              },
                            }
                          : {}),
                        ...(isWitness2AddrSameAsDonor
                          ? {
                              witness2Details: {
                                ...witness2Details,
                                address: {
                                  ...witness2Address,
                                  line1: e.target.value,
                                },
                              },
                            }
                          : {}),
                      });
                    }}
                    disabled={_isDisabled()}
                    validation={validation}
                    onValidation={onValidation}
                    schema={Joi.string()}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <ValidatedSelect
                    required
                    label="State"
                    value={donorDetails.address ? donorDetails.address.state : ''}
                    options={Object.values(STATE_IN)}
                    onChange={(e) => {
                      setForm7({
                        ...form7,
                        donorDetails: {
                          ...donorDetails,
                          address: {
                            ...donorAddress,
                            state: e.value,
                          },
                        },
                        ...(isWitness1AddrSameAsDonor
                          ? {
                              witness1Details: {
                                ...witness1Details,
                                address: {
                                  ...witness1Address,
                                  state: e.value,
                                },
                              },
                            }
                          : {}),
                        ...(isWitness2AddrSameAsDonor
                          ? {
                              witness2Details: {
                                ...witness2Details,
                                address: {
                                  ...witness2Address,
                                  state: e.value,
                                },
                              },
                            }
                          : {}),
                      });
                    }}
                    disabled={_isDisabled()}
                    validation={validation}
                    onValidation={onValidation}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <ValidatedSelect
                    label="District"
                    value={donorDetails.address ? donorDetails.address.district : ''}
                    options={Object.values(
                      districts(donorDetails.address ? donorDetails.address.state : '')
                    )}
                    onChange={(e) => {
                      setForm7({
                        ...form7,
                        donorDetails: {
                          ...donorDetails,
                          address: {
                            ...donorAddress,
                            district: e.value,
                          },
                        },
                        ...(isWitness1AddrSameAsDonor
                          ? {
                              witness1Details: {
                                ...witness1Details,
                                address: {
                                  ...witness1Address,
                                  district: e.value,
                                },
                              },
                            }
                          : {}),
                        ...(isWitness2AddrSameAsDonor
                          ? {
                              witness2Details: {
                                ...witness2Details,
                                address: {
                                  ...witness2Address,
                                  district: e.value,
                                },
                              },
                            }
                          : {}),
                      });
                    }}
                    disabled={_isDisabled()}
                    validation={validation}
                    onValidation={onValidation}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <ValidatedInput
                    required
                    type="number"
                    id="pin"
                    value={donorDetails.address ? donorDetails.address.pinCode : ''}
                    name="pin"
                    label="PIN Code"
                    fullWidth
                    onChange={(e) => {
                      setForm7({
                        ...form7,
                        donorDetails: {
                          ...donorDetails,
                          address: {
                            ...donorAddress,
                            pinCode: e.target.value,
                          },
                        },
                        ...(isWitness1AddrSameAsDonor
                          ? {
                              witness1Details: {
                                ...witness1Details,
                                address: {
                                  ...witness1Address,
                                  pinCode: e.target.value,
                                },
                              },
                            }
                          : {}),
                        ...(isWitness2AddrSameAsDonor
                          ? {
                              witness2Details: {
                                ...witness2Details,
                                address: {
                                  ...witness2Address,
                                  pinCode: e.target.value,
                                },
                              },
                            }
                          : {}),
                      });
                    }}
                    disabled={_isDisabled()}
                    validation={validation}
                    onValidation={onValidation}
                    schema={Joi.string()
                      .pattern(REGEXP_PINCODE)
                      .message({ 'string.pattern.base': 'Should be a 6 digit number' })}
                  />
                </Grid>
                <Grid
                  container
                  item
                  direction="column"
                  xs={12}
                  sm={4}
                  justify="center"
                  alignItems="center"
                >
                  <PhotoUpload
                    imgDimensions={IMGDIMENSIONS.PROFILE}
                    label="Donor Pledger Photo"
                    elementId="Donor Pledger Photo"
                    photoBase64={donorDetails.photo}
                    uploadPhoto={(photoBase64) => {
                      setForm7({
                        ...form7,
                        donorDetails: {
                          ...donorDetails,
                          photo: photoBase64,
                        },
                      });
                    }}
                    disabled={_isDisabled()}
                  />
                </Grid>
                <Grid
                  container
                  item
                  direction="column"
                  xs={12}
                  sm={4}
                  justify="center"
                  alignItems="center"
                >
                  <PhotoUpload
                    imgDimensions={IMGDIMENSIONS.SIGN}
                    label="Donor Pledger Sign"
                    elementId="Donor Pledger Sign"
                    photoBase64={donorDetails.signature}
                    uploadPhoto={(photoBase64) => {
                      setForm7({
                        ...form7,
                        donorDetails: {
                          ...donorDetails,
                          signature: photoBase64,
                        },
                      });
                    }}
                    disabled={_isDisabled()}
                  />
                </Grid>
              </Grid>
              <Grid>
                <hr />
              </Grid>

              <Grid container spacing={3} style={{ marginTop: 10, marginBottom: 10, padding: 10 }}>
                <Grid item xs={12} style={{ borderLeft: '5px solid #4EA95E' }}>
                  <Typography variant="body1" className={classes.transplantDetailsHeading}>
                    Witness One Details
                  </Typography>
                </Grid>
                
                <Grid item sm={4} xs={12}>
                  <ValidatedInput
                    required
                    value={witness1Details.firstName}
                    label="First Name"
                    onChange={(e) => {
                      setForm7({
                        ...form7,
                        witness1Details: {
                          ...witness1Details,
                          firstName: e.target.value,
                        },
                      });
                    }}
                    disabled={_isDisabled()}
                    validation={validation}
                    onValidation={onValidation}
                    ignoreChangeSchemaMode="ONLYCHARS"
                    schema={Joi.string()
                      .pattern(REGEXP_ONLYCHARS)
                      .message({ 'string.pattern.base': 'Cannot contain numbers' })}
                    fullWidth
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <ValidatedInput
                    value={witness1Details.middleName}
                    label="Middle Name"
                    onChange={(e) => {
                      setForm7({
                        ...form7,
                        witness1Details: {
                          ...witness1Details,
                          middleName: e.target.value,
                        },
                      });
                    }}
                    disabled={_isDisabled()}
                    ignoreChangeSchemaMode="ONLYCHARS"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <ValidatedInput
                    required
                    value={witness1Details.lastName}
                    label="Last Name"
                    onChange={(e) => {
                      setForm7({
                        ...form7,
                        witness1Details: {
                          ...witness1Details,
                          lastName: e.target.value,
                        },
                      });
                    }}
                    disabled={_isDisabled()}
                    validation={validation}
                    onValidation={onValidation}
                    ignoreChangeSchemaMode="ONLYCHARS"
                    schema={Joi.string()
                      .pattern(REGEXP_ONLYCHARS)
                      .message({ 'string.pattern.base': 'Cannot contain numbers' })}
                    fullWidth
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <ValidatedDate
                    required
                    value={witness1Details.dateOfBirth}
                    label="Date of birth"
                    type="date"
                    onChange={(e) => {
                      setForm7({
                        ...form7,
                        witness1Details: {
                          ...witness1Details,
                          dateOfBirth: e,
                        },
                      });
                    }}
                    disabled={_isDisabled()}
                    onValidation={onValidation}
                    validation={validation}
                    schema={Joi.date()
                      .max(moment())
                      .message({ 'date.max': 'Cannot be in the future' })}
                    fullWidth
                  />
                </Grid>

                <Grid item sm={4} xs={12}>
                  <ValidatedSelect
                    required
                    value={witness1Details.relationWithDonor}
                    label="Relation with donor"
                    options={[
                      {
                        label: 'Father',
                        value: 'FATHER',
                      },
                      {
                        label: 'Mother',
                        value: 'MOTHER',
                      },
                      {
                        label: 'Brother',
                        value: 'BROTHER',
                      },
                      {
                        label: 'Sister',
                        value: 'SISTER',
                      },
                      {
                        label: 'Aunty',
                        value: 'AUNTY',
                      },
                      {
                        label: 'Uncle',
                        value: 'Uncle',
                      },
                      {
                        label: 'Son',
                        value: 'SON',
                      },
                      {
                        label: 'Daughter',
                        value: 'DAUGHTER',
                      },
                      {
                        label: 'Nephew',
                        value: 'NEPHEW',
                      },
                      {
                        label: 'Niece',
                        value: 'NIECE',
                      },
                      {
                        label: 'Husband',
                        value: 'HUSBAND',
                      },
                      {
                        label: 'Wife',
                        value: 'WIFE',
                      },
                      {
                        label: 'Grand-Father',
                        value: 'GRANDFATHER',
                      },
                      {
                        label: 'Grand-Mother',
                        value: 'GRANDMOTHER',
                      },
                      {
                        label: 'Grand-Son',
                        value: 'GRANDSON',
                      },
                      {
                        label: 'Grand-Daugther',
                        value: 'GRANDDAUGTHER',
                      },
                      {
                        label: 'Father-In-Law',
                        value: 'FATHERINLAW',
                      },
                      {
                        label: 'Mother-In-Law',
                        value: 'MOTHERINLAW',
                      },
                      {
                        label: 'Spouse',
                        value: 'SPOUSE',
                      },
                      {
                        label: 'Parent',
                        value: 'PARENT',
                      },
                      {
                        label: 'Cousin',
                        value: 'COUSIN',
                      },
                    ]}
                    onChange={(e) => {
                      setForm7({
                        ...form7,
                        witness1Details: {
                          ...witness1Details,
                          relationWithDonor: e.value,
                        },
                      });
                    }}
                    disabled={_isDisabled()}
                    fullWidth
                    onValidation={onValidation}
                    validation={validation}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <ValidatedInput
                    required
                    type="number"
                    value={witness1Details.uid}
                    label="Aadhar Number"
                    onChange={(e) => {
                      setForm7({
                        ...form7,
                        witness1Details: {
                          ...witness1Details,
                          uid: e.target.value,
                        },
                      });
                    }}
                    disabled={_isDisabled()}
                    validation={validation}
                    onValidation={onValidation}
                    schema={Joi.string()
                      .pattern(REGEXP_AADHAAR)
                      .message({ 'string.pattern.base': 'Should be a 12 digit number' })
                      .pattern(RegExp(`^${form7?.donorDetails?.uid}$`), { invert: true })
                      .message({
                        'string.pattern.invert.base': 'Should be different from donor aadhar',
                      })}
                    fullWidth
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <ValidatedInput
                    required
                    value={witness1Details.telephone}
                    label="Mobile Number"
                    onChange={(e) => {
                      setForm7({
                        ...form7,
                        witness1Details: {
                          ...witness1Details,
                          telephone: e.target.value,
                        },
                      });
                    }}
                    disabled={_isDisabled()}
                    validation={validation}
                    onValidation={onValidation}
                    schema={Joi.string()
                      .pattern(REGEXP_MOBILE)
                      .message({ 'string.pattern.base': 'Should be a 10 digit number' })}
                    fullWidth
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <ValidatedInput
                    required
                    value={witness1Details.email}
                    label="Email"
                    onChange={(e) => {
                      setForm7({
                        ...form7,
                        witness1Details: {
                          ...witness1Details,
                          email: e.target.value,
                        },
                      });
                    }}
                    disabled={_isDisabled()}
                    validation={validation}
                    onValidation={onValidation}
                    schema={Joi.string().email({ tlds: { allow: false } })}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isWitness1AddrSameAsDonor}
                        onChange={(e) => {
                          setIsWitness1AddrSameAsDonor(e.target.checked);
                          if (e.target.checked) {
                            setForm7({
                              ...form7,
                              witness1Details: {
                                ...witness1Details,
                                address: {
                                  ...witness1Address,
                                  line1: donorDetails.address.line1,
                                  pinCode: donorDetails.address.pinCode,
                                  district: donorDetails.address.district,
                                  state: donorDetails.address.state,
                                },
                              },
                            });
                          }
                          else if(!e.target.checked){
                            setForm7({
                              ...form7,
                              witness1Details: {
                                ...witness1Details,
                                address: {
                                  ...witness1Details,
                                  line1: '',
                                  pinCode: '',
                                  district: '',
                                  state: '',
                                },
                              },
                            });
                          }
                        }}
                        disabled={_isDisabled()}
                        name="isWitness1AddrSameAsDonor"
                        color="primary"
                      />
                    }
                    label="Witness address same as donor address"
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <ValidatedInput
                    multiline
                    rows={3}
                    value={witness1Details?.address?.line1 || ' '}
                    label="Address"
                    fullWidth
                    onChange={(e) => {
                      setForm7({
                        ...form7,
                        witness1Details: {
                          ...witness1Details,
                          address: {
                            ...witness1Address,
                            line1: e.target.value,
                          },
                        },
                      });
                    }}
                    disabled={isWitness1AddrSameAsDonor || _isDisabled()}
                    validation={validation}
                    onValidation={onValidation}
                    schema={Joi.string()}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <ValidatedSelect
                    required
                    label="State"
                    value={witness1Details?.address?.state || ''}
                    options={Object.values(STATE_IN)}
                    onChange={(e) => {
                      setForm7({
                        ...form7,
                        witness1Details: {
                          ...witness1Details,
                          address: {
                            ...witness1Address,
                            state: e.value,
                          },
                        },
                      });
                    }}
                    disabled={isWitness1AddrSameAsDonor || _isDisabled()}
                    validation={validation}
                    onValidation={onValidation}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <ValidatedSelect
                    label="District"
                    value={witness1Details?.address?.district || ''}
                    options={Object.values(districts(witness1Details?.address?.state || ''))}
                    onChange={(e) => {
                      setForm7({
                        ...form7,
                        witness1Details: {
                          ...witness1Details,
                          address: {
                            ...witness1Address,
                            district: e.value,
                          },
                        },
                      });
                    }}
                    disabled={isWitness1AddrSameAsDonor || _isDisabled()}
                    validation={validation}
                    onValidation={onValidation}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <ValidatedInput
                    required
                    type="number"
                    id="pin"
                    value={witness1Details?.address?.pinCode || ''}
                    name="pin"
                    label="PIN Code"
                    fullWidth
                    onChange={(e) => {
                      setForm7({
                        ...form7,
                        witness1Details: {
                          ...witness1Details,
                          address: {
                            ...witness1Address,
                            pinCode: e.target.value,
                          },
                        },
                      });
                    }}
                    disabled={isWitness1AddrSameAsDonor || _isDisabled()}
                    validation={validation}
                    onValidation={onValidation}
                    schema={Joi.string()
                      .pattern(REGEXP_PINCODE)
                      .message({ 'string.pattern.base': 'Should be a 6 digit number' })}
                  />
                </Grid>
                <Grid
                  container
                  item
                  direction="column"
                  xs={12}
                  sm={4}
                  justify="center"
                  alignItems="center"
                >
                  <PhotoUpload
                    imgDimensions={IMGDIMENSIONS.SIGN}
                    label="Witness One Signature"
                    elementId="Witness One Signature"
                    photoBase64={witness1Details.signature}
                    uploadPhoto={(photoBase64) => {
                      setForm7({
                        ...form7,
                        witness1Details: {
                          ...witness1Details,
                          signature: photoBase64,
                        },
                      });
                    }}
                    disabled={_isDisabled()}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginTop: 10, marginBottom: 10, padding: 10 }}>
                <Grid item sm={12} style={{ borderLeft: '5px solid #4EA95E' }}>
                  <Typography variant="body1" className={classes.transplantDetailsHeading}>
                    Witness Two Details
                  </Typography>
                </Grid>
                
                <Grid item sm={4} xs={12}>
                  <ValidatedInput
                    required
                    value={witness2Details.firstName}
                    label="First Name"
                    onChange={(e) => {
                      setForm7({
                        ...form7,
                        witness2Details: {
                          ...witness2Details,
                          firstName: e.target.value,
                        },
                      });
                    }}
                    disabled={_isDisabled()}
                    validation={validation}
                    onValidation={onValidation}
                    ignoreChangeSchemaMode="ONLYCHARS"
                    schema={Joi.string()
                      .pattern(REGEXP_ONLYCHARS)
                      .message({ 'string.pattern.base': 'Cannot contain numbers' })}
                    fullWidth
                  />
                </Grid>

                <Grid item sm={4} xs={12}>
                  <ValidatedInput
                    value={witness2Details.middleName}
                    label="Middle Name"
                    onChange={(e) => {
                      setForm7({
                        ...form7,
                        witness2Details: {
                          ...witness2Details,
                          middleName: e.target.value,
                        },
                      });
                    }}
                    disabled={_isDisabled()}
                    ignoreChangeSchemaMode="ONLYCHARS"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <ValidatedInput
                    required
                    value={witness2Details.lastName}
                    label="Last Name"
                    onChange={(e) => {
                      setForm7({
                        ...form7,
                        witness2Details: {
                          ...witness2Details,
                          lastName: e.target.value,
                        },
                      });
                    }}
                    disabled={_isDisabled()}
                    validation={validation}
                    onValidation={onValidation}
                    ignoreChangeSchemaMode="ONLYCHARS"
                    schema={Joi.string()
                      .pattern(REGEXP_ONLYCHARS)
                      .message({ 'string.pattern.base': 'Cannot contain numbers' })}
                    fullWidth
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <ValidatedDate
                    required
                    value={witness2Details.dateOfBirth}
                    label="Date of birth"
                    type="date"
                    onChange={(e) => {
                      setForm7({
                        ...form7,
                        witness2Details: {
                          ...witness2Details,
                          dateOfBirth: e,
                        },
                      });
                    }}
                    disabled={_isDisabled()}
                    onValidation={onValidation}
                    validation={validation}
                    schema={Joi.date()
                      .max(moment())
                      .message({ 'date.max': 'Cannot be in the future' })}
                    fullWidth
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <ValidatedSelect
                    required
                    value={witness2Details.relationWithDonor}
                    label="Relation with donor"
                    options={[
                      {
                        label: 'Father',
                        value: 'FATHER',
                      },
                      {
                        label: 'Mother',
                        value: 'MOTHER',
                      },
                      {
                        label: 'Brother',
                        value: 'BROTHER',
                      },
                      {
                        label: 'Sister',
                        value: 'SISTER',
                      },
                      {
                        label: 'Aunty',
                        value: 'AUNTY',
                      },
                      {
                        label: 'Uncle',
                        value: 'Uncle',
                      },
                      {
                        label: 'Son',
                        value: 'SON',
                      },
                      {
                        label: 'Daughter',
                        value: 'DAUGHTER',
                      },
                      {
                        label: 'Nephew',
                        value: 'NEPHEW',
                      },
                      {
                        label: 'Niece',
                        value: 'NIECE',
                      },
                      {
                        label: 'Husband',
                        value: 'HUSBAND',
                      },
                      {
                        label: 'Wife',
                        value: 'WIFE',
                      },
                      {
                        label: 'Grand-Father',
                        value: 'GRANDFATHER',
                      },
                      {
                        label: 'Grand-Mother',
                        value: 'GRANDMOTHER',
                      },
                      {
                        label: 'Grand-Son',
                        value: 'GRANDSON',
                      },
                      {
                        label: 'Grand-Daugther',
                        value: 'GRANDDAUGTHER',
                      },
                      {
                        label: 'Father-In-Law',
                        value: 'FATHERINLAW',
                      },
                      {
                        label: 'Mother-In-Law',
                        value: 'MOTHERINLAW',
                      },
                      {
                        label: 'Spouse',
                        value: 'SPOUSE',
                      },
                      {
                        label: 'Parent',
                        value: 'PARENT',
                      },
                      {
                        label: 'Cousin',
                        value: 'COUSIN',
                      },
                    ]}
                    onChange={(e) => {
                      setForm7({
                        ...form7,
                        witness2Details: {
                          ...witness2Details,
                          relationWithDonor: e.value,
                        },
                      });
                    }}
                    disabled={_isDisabled()}
                    fullWidth
                    onValidation={onValidation}
                    validation={validation}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <ValidatedInput
                    required
                    type="number"
                    value={witness2Details.uid}
                    label="Aadhar Number"
                    onChange={(e) => {
                      setForm7({
                        ...form7,
                        witness2Details: {
                          ...witness2Details,
                          uid: e.target.value,
                        },
                      });
                    }}
                    disabled={_isDisabled()}
                    validation={validation}
                    onValidation={onValidation}
                    schema={Joi.string()
                      .pattern(REGEXP_AADHAAR)
                      .message({ 'string.pattern.base': 'Should be a 12 digit number' })
                      .pattern(RegExp(`^${form7?.donorDetails?.uid}$`), { invert: true })
                      .message({
                        'string.pattern.invert.base': 'Should be different from donor aadhar',
                      })}
                    fullWidth
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <ValidatedInput
                    required
                    value={witness2Details.telephone}
                    label="Mobile Number"
                    onChange={(e) => {
                      setForm7({
                        ...form7,
                        witness2Details: {
                          ...witness2Details,
                          telephone: e.target.value,
                        },
                      });
                    }}
                    disabled={_isDisabled()}
                    validation={validation}
                    onValidation={onValidation}
                    schema={Joi.string()
                      .pattern(REGEXP_MOBILE)
                      .message({ 'string.pattern.base': 'Should be a 10 digit number' })}
                    fullWidth
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <ValidatedInput
                    required
                    value={witness2Details.email}
                    label="Email"
                    onChange={(e) => {
                      setForm7({
                        ...form7,
                        witness2Details: {
                          ...witness2Details,
                          email: e.target.value,
                        },
                      });
                    }}
                    disabled={_isDisabled()}
                    validation={validation}
                    onValidation={onValidation}
                    schema={Joi.string().email({ tlds: { allow: false } })}
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isWitness2AddrSameAsDonor}
                        onChange={(e) => {
                          setIsWitness2AddrSameAsDonor(e.target.checked);
                          if (e.target.checked) {
                            setForm7({
                              ...form7,
                              witness2Details: {
                                ...witness2Details,
                                address: {
                                  ...witness2Details,
                                  line1: donorDetails.address.line1,
                                  pinCode: donorDetails.address.pinCode,
                                  district: donorDetails.address.district,
                                  state: donorDetails.address.state,
                                },
                              },
                            });
                          } else if(!e.target.checked){
                            setForm7({
                              ...form7,
                              witness2Details: {
                                ...witness2Details,
                                address: {
                                  ...witness2Details,
                                  line1: '',
                                  pinCode: '',
                                  district: '',
                                  state: '',
                                },
                              },
                            });
                          }
                        }}
                        disabled={_isDisabled()}
                        name="isWitness2AddrSameAsDonor"
                        color="primary"
                      />
                    }
                    label="Witness address same as donor address"
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <ValidatedInput
                    multiline
                    rows={4}
                    value={witness2Details?.address?.line1 || ' '}
                    label="Address"
                    fullWidth
                    onChange={(e) => {
                      setForm7({
                        ...form7,
                        witness2Details: {
                          ...witness2Details,
                          address: {
                            ...witness2Address,
                            line1: e.target.value,
                          },
                        },
                      });
                    }}
                    disabled={isWitness2AddrSameAsDonor || _isDisabled()}
                    validation={validation}
                    onValidation={onValidation}
                    schema={Joi.string()}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <ValidatedSelect
                    required
                    label="State"
                    value={witness2Details?.address?.state || ''}
                    options={Object.values(STATE_IN)}
                    onChange={(e) => {
                      setForm7({
                        ...form7,
                        witness2Details: {
                          ...witness2Details,
                          address: {
                            ...witness2Address,
                            state: e.value,
                          },
                        },
                      });
                    }}
                    disabled={isWitness2AddrSameAsDonor || _isDisabled()}
                    validation={validation}
                    onValidation={onValidation}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <ValidatedSelect
                    label="District"
                    value={witness2Details?.address?.district || ''}
                    options={Object.values(districts(witness2Details?.address?.state || ''))}
                    onChange={(e) => {
                      setForm7({
                        ...form7,
                        witness2Details: {
                          ...witness2Details,
                          address: {
                            ...witness2Address,
                            district: e.value,
                          },
                        },
                      });
                    }}
                    disabled={isWitness2AddrSameAsDonor || _isDisabled()}
                    validation={validation}
                    onValidation={onValidation}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <ValidatedInput
                    required
                    type="number"
                    id="pin"
                    value={witness2Details?.address?.pinCode || ''}
                    name="pin"
                    label="PIN Code"
                    fullWidth
                    onChange={(e) => {
                      setForm7({
                        ...form7,
                        witness2Details: {
                          ...witness2Details,
                          address: {
                            ...witness2Address,
                            pinCode: e.target.value,
                          },
                        },
                      });
                    }}
                    disabled={isWitness2AddrSameAsDonor || _isDisabled()}
                    validation={validation}
                    onValidation={onValidation}
                    schema={Joi.string()
                      .pattern(REGEXP_PINCODE)
                      .message({ 'string.pattern.base': 'Should be a 6 digit number' })}
                  />
                </Grid>
                <Grid
                  container
                  item
                  direction="column"
                  xs={12}
                  sm={4}
                  justify="center"
                  alignItems="center"
                >
                  <PhotoUpload
                    imgDimensions={IMGDIMENSIONS.SIGN}
                    label="Witness Two Signature"
                    elementId="Witness Two Signature"
                    photoBase64={witness2Details.signature}
                    uploadPhoto={(photoBase64) => {
                      setForm7({
                        ...form7,
                        witness2Details: {
                          ...witness2Details,
                          signature: photoBase64,
                        },
                      });
                    }}
                    disabled={_isDisabled()}
                  />
                </Grid>
              </Grid>
              <Grid>
                <hr />
              </Grid>
            </Grid>
          </Grid>
          {form7.formStatus === 'NEW' ? _renderForm7FooterButtons() : null}
        </Paper>
      </Grid>
    );
  };

  return (
    <Box className={classes.form12BoxStyle} marginLeft="15px" marginRight="15px">
      {form7.formStatus === 'NEW' ? (
        <Paper className={classes.paperTransplantInfo}>
          <Box justify="space-between" display="flex" flexDirection="row">
            <Grid container style={{ padding: 15, flexDirection: 'row' }}>
              <Grid item sm={12} xs={12}>
                <Typography
                  variant="h3"
                  style={{ fontSize: '16px', marginBottom: '10px', padding: 10 }}
                >
                  Donor Pledge (Form 7)
                </Typography>
                <Typography
                  style={{
                    fontWeight: 300,
                    fontSize: '14px',
                    lineHeight: '15px',
                  }}
                >
                  Application for pledging organ donation.
                </Typography>
              </Grid>
            </Grid>

            {!_isDisabled() ? (
              <Grid container style={{ padding: 15, flexDirection: 'row-reverse' }} spacing={1}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      height: '36px',
                    }}
                    startIcon={<KeyboardBackspaceIcon />}
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    Back
                  </Button>
                </Grid>
              </Grid>
            ) : null}
          </Box>
        </Paper>
      ) : null}
      <Box display="flex" flexDirection="row" marginTop="20">
        <Grid container>
          {/* <Grid item sm={2}>
            <Paper style={{ marginTop: 10 }} className={classes.paperTransplantSections}>
              <Grid container style={{ padding: 20, marginTop: 10 }}>
                <Grid item sm={12} xs={12}>
                  <Typography variant="body1" className={classes.transplantSectionsHeadingStyle}>
                    Form-7 Details
                  </Typography>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Typography variant="body1" className={classes.transplantSectionsStyle}>
                    Organs
                  </Typography>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Typography variant="body1" className={classes.transplantSectionsStyle}>
                    Donor Pledger Details
                  </Typography>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Typography variant="body1" className={classes.transplantSectionsStyle}>
                    Witness Details
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid> */}
          {_renderForm7()}
          {_renderSubmitDialog()}
          {_renderGetOtpDialog()}
          {_renderForm7PreviewDialog()}
          {_renderNoOrganTypeAddedDialog()}
        </Grid>
      </Box>
    </Box>
  );
}

Form7.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      draftId: PropTypes.string,
    }),
  }),
  validate: PropTypes.func.isRequired,
  validation: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
  onValidation: PropTypes.func.isRequired,
};
Form7.defaultProps = {
  match: {
    params: {
      draftId: undefined,
    },
  },
};

export default compose(withRouter, withValidation)(Form7);
