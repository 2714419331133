import { useState, useEffect, useCallback } from 'react';
import qs from 'qs';
import { Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import winston from '../winston';
import { makeCancellable } from '../utils';

const APICALLSTATUS = {
  UNSET: 'UNSET',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

export default () => {
  const { enqueueSnackbar } = useSnackbar();

  const [apiCallStatuses, setApiCallStatuses] = useState({});
  const [, setPendingApiPromises] = useState({});
  useEffect(
    () => () => {
      winston.silly('Component unmounting');
      setPendingApiPromises((pendingPromises) => {
        Object.values(pendingPromises).forEach((p) => {
          p.cancel();
        });
        return pendingPromises;
      });
    },
    []
  );

  // https://codesandbox.io/s/cancellableapicall-vyo6e
  const callApi = useCallback(
    (url, fetchOptions, opts = { hideNotification: false }) => {
      const apiCallId = `${Math.random().toString(36).substr(2, 5)}`;

      setApiCallStatuses((oldStatuses) => ({ ...oldStatuses, [apiCallId]: APICALLSTATUS.LOADING }));

      let apiFetchOptions = {};
      if (fetchOptions && fetchOptions.headers) {
        apiFetchOptions = {
          credentials: 'include',
          ...fetchOptions,
          headers: {
            ...fetchOptions.headers,
            'X-Chainworks-Id': apiCallId,
          },
        };
      } else {
        apiFetchOptions = {
          credentials: 'include',
          ...(fetchOptions && fetchOptions),
          headers: {
            'X-Chainworks-Id': apiCallId,
          },
        };
      }

      const apiPromise = makeCancellable(fetch(url, apiFetchOptions));
      setPendingApiPromises((oldPendingPromises) => ({
        ...oldPendingPromises,
        [apiCallId]: apiPromise,
      }));
      const promise = apiPromise.promise
        .then((result) => {
          setPendingApiPromises((oldPendingPromises) => {
            const { [apiCallId]: apiCallPromise, ...otherPromises } = oldPendingPromises;
            return otherPromises;
          });

          setApiCallStatuses((oldStatuses) => ({
            ...oldStatuses,
            [apiCallId]: APICALLSTATUS.SUCCESS,
          }));

          return result;
        })
        .catch((err) => {
          setPendingApiPromises((oldPendingPromises) => {
            const { [apiCallId]: apiCallPromise, ...otherPromises } = oldPendingPromises;
            return otherPromises;
          });

          if (err.isCancelled) {
            winston.debug(`API cancelled: ${apiCallId}`);
            setApiCallStatuses((oldStatuses) => ({
              ...oldStatuses,
              [apiCallId]: APICALLSTATUS.UNSET,
            }));

            return true;
          }

          setApiCallStatuses((oldStatuses) => ({
            ...oldStatuses,
            [apiCallId]: APICALLSTATUS.ERROR,
          }));

          if (!opts.hideNotification) {
            enqueueSnackbar('Please try again', { variant: 'error' });
          }
          winston.error(err);
          throw err;
        });

      return { promise, apiCallId };
    },
    [enqueueSnackbar]
  );

  const apiGet = useCallback(
    async (
      urlString,
      query,
      options = {
        unAuthenticated: false,
        apiIdCb: () => {},
      }
    ) => {
      const url = new URL(urlString);
      if (query) {
        url.search = qs.stringify(query, { arrayFormat: 'brackets' });
      }

      let response;

      winston.debug(`GET request URL: ${url}`);

      const callApiInstance = callApi(url, {
        method: 'GET',
        headers: {
          // Authorization: (await Auth.currentSession()).idToken.jwtToken,
          ...(!options.unAuthenticated && {
            Authorization: (await Auth.currentSession()).idToken.jwtToken,
          }),
        },
      });

      if (options.apiIdCb) {
        options.apiIdCb(callApiInstance.apiCallId);
      }
      try {
        const rawResponse = await callApiInstance.promise;
        if (!rawResponse.ok) {
          throw Error(rawResponse.statusText);
        }
        if (options.isText) {
          response = await rawResponse.text();
        } else if (options.isBlob) {
          response = await rawResponse.blob();
        } else {
          response = await rawResponse.json();
        }
      } catch (err) {
        winston.error(`Error GET url: ${url}`, err);
        const errMessage = 'Service Error';
        throw new Error(errMessage);
      }
      winston.silly('Response', { apiResponse: response });

      if (options && (options.showSuccess || options.successMsg)) {
        enqueueSnackbar(options.successMsg || 'Success!', { variant: 'success' });
      }
      if (options && (options.isText || options.isBlob)) {
        return response;
      }
      return response.result;
    },
    [callApi, enqueueSnackbar]
  );

  const apiPost = useCallback(
    async (
      url,
      postBody,
      options = {
        unAuthenticated: false,
        chainworksApiKey: '',
        apiIdCb: () => {},
      }
    ) => {
      winston.debug(`POST request URL: ${url}`);

      let response;

      const callApiInstance = callApi(url, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          ...(!options.unAuthenticated && {
            Authorization: (await Auth.currentSession()).idToken.jwtToken,
          }),
        },
        body: JSON.stringify(postBody),
      });

      if (options.apiIdCb) {
        options.apiIdCb(callApiInstance.apiCallId);
      }
      try {
        const rawResponse = await callApiInstance.promise;
        if (!rawResponse.ok) {
          const res = await rawResponse.json();
          enqueueSnackbar(res.message, { variant: 'error' });
          throw Error(rawResponse.statusText);
        }
        if (options.isText) {
          response = await rawResponse.text();
        } else if (options.isBlob) {
          response = await rawResponse.blob();
        } else {
          response = await rawResponse.json();
        }
      } catch (err) {
        winston.error(`Error POST url: ${url}`, err);
        const errMessage = 'Service Error';
        throw new Error(errMessage);
      }
      winston.silly('Response', { apiResponse: response });

      if (options && (options.showSuccess || options.successMsg)) {
        enqueueSnackbar(options.successMsg || 'Success!', { variant: 'success' });
      }
      if (options && (options.isText || options.isBlob)) {
        return response;
      }
      return response.result;
    },
    [callApi, enqueueSnackbar]
  );

  const apiPut = useCallback(
    async (
      url,
      putBody,
      options = {
        apiIdCb: () => {},
      }
    ) => {
      winston.debug(`PUT request URL: ${url}`);

      let response;

      const callApiInstance = callApi(url, {
        method: 'PUT',
        mode: 'cors',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: (await Auth.currentSession()).idToken.jwtToken,
        },
        body: JSON.stringify(putBody),
      });

      if (options.apiIdCb) {
        options.apiIdCb(callApiInstance.apiCallId);
      }
      try {
        const rawResponse = await callApiInstance.promise;
        if (!rawResponse.ok) {
          const res = await rawResponse.json();
          enqueueSnackbar(res.message, { variant: 'error' });
          throw Error(rawResponse.statusText);
        }
        if (options.isText) {
          response = await rawResponse.text();
        } else if (options.isBlob) {
          response = await rawResponse.blob();
        } else {
          response = await rawResponse.json();
        }
      } catch (err) {
        winston.error(`Error PUT url: ${url}`, err);
        const errMessage = 'Service Error';
        throw new Error(errMessage);
      }
      winston.silly('Response', { apiResponse: response });

      if (options && (options.showSuccess || options.successMsg)) {
        enqueueSnackbar(options.successMsg || 'Success!', { variant: 'success' });
      }
      if (options && (options.isText || options.isBlob)) {
        return response;
      }
      return response.result;
    },
    [callApi, enqueueSnackbar]
  );

  const apiPatch = useCallback(
    async (
      url,
      patchBody,
      options = {
        apiIdCb: () => {},
      }
    ) => {
      winston.debug(`PATCH request URL: ${url}`);

      let response;

      const callApiInstance = callApi(url, {
        method: 'PATCH',
        mode: 'cors',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: (await Auth.currentSession()).idToken.jwtToken,
        },
        body: JSON.stringify(patchBody),
      });

      if (options.apiIdCb) {
        options.apiIdCb(callApiInstance.apiCallId);
      }
      try {
        const rawResponse = await callApiInstance.promise;
        if (!rawResponse.ok) {
          const res = await rawResponse.json();
          enqueueSnackbar(res.message, { variant: 'error' });
          throw Error(rawResponse.statusText);
        }
        if (options.isText) {
          response = await rawResponse.text();
        } else if (options.isBlob) {
          response = await rawResponse.blob();
        } else {
          response = await rawResponse.json();
        }
      } catch (err) {
        winston.error(`Error PUT url: ${url}`, err);
        const errMessage = 'Service Error';
        throw new Error(errMessage);
      }
      winston.silly('Response', { apiResponse: response });

      if (options && (options.showSuccess || options.successMsg)) {
        enqueueSnackbar(options.successMsg || 'Success!', { variant: 'success' });
      }
      if (options && (options.isText || options.isBlob)) {
        return response;
      }
      return response.result;
    },
    [callApi, enqueueSnackbar]
  );

  return {
    callApi,
    apiCallStatuses,
    apiGet,
    apiPost,
    apiPut,
    apiPatch,
  };
};
