import { env, ENV } from './constants';

let SERVER_HOST;

if (env === ENV.DEV) {
  SERVER_HOST = `https://localhost:10443`;
} else if (env === ENV.TEST) {
  SERVER_HOST = `https://apitest.mahaayudaan.in`;
} else {
  SERVER_HOST = `https://api.mahaayudaan.in`;
}

export const PATH_GETWAITINGLISTRANK = `${SERVER_HOST}/waitingList/waitingList`;
export const PATH_GETHOSPITALS = `${SERVER_HOST}/waitingList/hospitals`;

export const PATH_PUBLIC_ADDDONORPLEDGEFORM = `${SERVER_HOST}/public/donorPledge`;
export const PATH_PUBLIC_GETDONORPROFILE = `${SERVER_HOST}/public/donorprofile`;
export const PATH_PUBLIC_GETHOSPITALFORFORM7 = `${SERVER_HOST}/public/hospitalDetailsForForm7`;
export const PATH_PUBLIC_DOWNLOADDONORCARD = `${SERVER_HOST}/public/donorCard`;
export const PATH_TRANSFER_OUT_REGISTRATION_REQUEST = `${SERVER_HOST}/public/transferOutRegistration`;
export const PATH_DISTRICTWISE_DATA = `${SERVER_HOST}/public/loginDistrictWiseData`;
export const PATH_GETLOGINSTATISTICSDATA = `${SERVER_HOST}/public/loginContents`;

export const PATH_FORM12_GETSUMMARIES = `${SERVER_HOST}/hota/form12/form12Summaries`;
export const PATH_FORM12_SUBMITDRAFTFORM12 = `${SERVER_HOST}/hota/form12/draftForm12`;
export const PATH_FORM12_GETDRAFTFORM12 = `${SERVER_HOST}/hota/form12/draftForm12/%s`;
export const PATH_FORM12_GETCOPYOFEXISTINGFORM12DETAILS = `${SERVER_HOST}/hota/form12/copyOfExistingForm12Details`;
export const PATH_FORM12_DELETEDRAFTFORM12 = `${SERVER_HOST}/hota/form12/draftForm12`;
export const PATH_FORM12_SUBMITFORM12EDITS = `${SERVER_HOST}/hota/form12/form12Edits`;
export const PATH_FORM12_UPDATEFORM12STATUS = `${SERVER_HOST}/hota/form12/form12Status`;
export const PATH_FORM12_SUBMITUNDERTAKING = `${SERVER_HOST}/hota/form12/form12Undertaking`;
export const PATH_FORM12_GETFORM12 = `${SERVER_HOST}/hota/form12/form12/%s`;
/* Why is this not used anywhere?
export const PATH_FORM12_APPROVEFORM12 = `${SERVER_HOST}/hota/form12/approval`;
export const PATH_FORM12_GETAPPROVAL = `${SERVER_HOST}/hota/form12/approval/%s`;
*/
export const PATH_FORM12_GETSPECIALISTCHANGEREQUESTSUMMARIES = `${SERVER_HOST}/hota/form12/form12SpecialistChangeRequestSummaries`;
export const PATH_FORM12_SUBMITSPECIALISTCHANGEREQUEST = `${SERVER_HOST}/hota/form12/specialistChangeRequests`;
export const PATH_FORM12_GETSPECIALISTCHANGEREQUEST = `${SERVER_HOST}/hota/form12/specialistChangeRequests/%s`;
export const PATH_FORM12_UPDATESPECIALISTCHANGEREQUEST = `${SERVER_HOST}/hota/form12/specialistChangeRequests/%s`;
export const PATH_FORM12_SIGNFORM12 = `${SERVER_HOST}/hota/form12/signature`;
export const PATH_FORM12_ADDNOTING = `${SERVER_HOST}/hota/form12/noting`;
export const PATH_FORM12_REVERTFORM12 = `${SERVER_HOST}/hota/form12/revertForm12`;
export const PATH_FORM12_ADDISSUE = `${SERVER_HOST}/hota/form12/issue`;
export const PATH_FORM12_UPDATEISSUESTATUS = `${SERVER_HOST}/hota/form12/issueStatus`;
export const PATH_FORM12_GETISSUES = `${SERVER_HOST}/hota/form12/issues/%s`;
export const PATH_FORM12_ADDINSPECTIONPERMISSION = `${SERVER_HOST}/hota/form12/inspectionPermission`;
export const PATH_FORM12_ADDREGISTRATIONCERTIFICATE = `${SERVER_HOST}/hota/form12/registrationCertificate`;
export const PATH_FORM12_ADDDHSLETTER = `${SERVER_HOST}/hota/form12/dhsCertificate`;
export const PATH_FORM12_ADDINSPECTIONREPORT = `${SERVER_HOST}/hota/form12/inspectionReport`;
export const PATH_FORM12_GENERATECERTIFICATE = `${SERVER_HOST}/hota/form12/certificate`;

export const PATH_EXP_GETHOSPITALSBYZTCCZONE = `${SERVER_HOST}/broadcast/hospitalsByZtccZone`;
export const PATH_EXP_BROADCASTNEWMESSAGE = `${SERVER_HOST}/broadcast/forumBroadcast`;
export const PATH_EXP_BROADCASTSUMMARIES = `${SERVER_HOST}/broadcast/forumBroadcastSummaries`;
export const PATH_EXP_SUBMITBROADCASTRESPONSE = `${SERVER_HOST}/broadcast/forumBroadcastResponse`;
export const PATH_EXP_GETBROADCASTRESPONSE = `${SERVER_HOST}/broadcast/forumBroadcastResponse`;
export const PATH_EXP_SUBMITBROADCASTDISCUSSIONMESSAGE = `${SERVER_HOST}/broadcast/forumBroadcastDiscussionMessage`;
export const PATH_EXP_GETBROADCASTDISCUSSIONMESSAGES = `${SERVER_HOST}/broadcast/forumBroadcastDiscussionMessages`;
export const PATH_EXP_GETHOSPITALRESPONSESTATUS = `${SERVER_HOST}/broadcast/forumBroadcastHospitalResponseStatus`;
export const PATH_EXP_UPDATEBROADCASTRESPONSESTATUS = `${SERVER_HOST}/broadcast/forumBroadcastResponseApproval`;
export const PATH_EXP_SENDBROADCASTREMINDER = `${SERVER_HOST}/broadcast/forumBroadcastReminder`;

export const PATH_FORM7_PLEDGEREQUEST = `${SERVER_HOST}/hota/form7/pledgeRequest`;

// legacyForm12 urls

export const PATH_LEGACYFORM12_GETSUMMARIES = `${SERVER_HOST}/hota/legacyForm12/legacyForm12Summaries`;
export const PATH_LEGACYFORM12_GETLEGACYFORM12 = `${SERVER_HOST}/hota/legacyForm12/legacyForm12/%s`;
export const PATH_LEGACYFORM12_SUBMITDRAFTLEGACYFORM12 = `${SERVER_HOST}/hota/legacyForm12/draftLegacyForm12`;
export const PATH_LEGACYFORM12_GETDRAFTLEGACYFORM12 = `${SERVER_HOST}/hota/legacyForm12/draftLegacyForm12/%s`;
export const PATH_LEGACYFORM12_DELETEDRAFTLEGACYFORM12 = `${SERVER_HOST}/hota/legacyForm12/draftLegacyForm12`;
export const PATH_LEGACYFORM12_SUBMITLEGACYFORM12EDITS = `${SERVER_HOST}/hota/legacyForm12/legacyForm12Edits`;
export const PATH_LEGACYFORM12_UPDATELEGACYFORM12STATUS = `${SERVER_HOST}/hota/legacyForm12/legacyForm12Status`;
export const PATH_LEGACYFORM12_REVERTLEGACYFORM12 = `${SERVER_HOST}/hota/legacyForm12/revertLegacyForm12`;
export const PATH_LEGACYFORM12_SUBMITSPECIALISTCHANGEREQUEST = `${SERVER_HOST}/hota/legacyForm12/specialistChangeRequests`;
export const PATH_LEGACYFORM12_UPDATESPECIALISTCHANGEREQUEST = `${SERVER_HOST}/hota/legacyForm12/specialistChangeRequests/%s`;
export const PATH_LEGACYFORM12_GETSPECIALISTCHANGEREQUEST = `${SERVER_HOST}/hota/legacyForm12/specialistChangeRequests/%s`;
export const PATH_LEGACYFORM12_GETSPECIALISTCHANGEREQUESTSUMMARIES = `${SERVER_HOST}/hota/legacyForm12/legacyForm12SpecialistChangeRequestSummaries`;
/* Why are these not used anywhere?
 */

export const PATH_LEGACYFORM12_ADDISSUE = `${SERVER_HOST}/hota/legacyForm12/issue`;
export const PATH_LEGACYFORM12_UPDATEISSUESTATUS = `${SERVER_HOST}/hota/legacyForm12/issueStatus`;
export const PATH_LEGACYFORM12_GETISSUES = `${SERVER_HOST}/hota/legacyForm12/issues/%s`;
export const PATH_LEGACYFORM12_APPROVELEGACYFORM12 = `${SERVER_HOST}/hota/legacyForm12/approval/legacyForm12`;

// Transplant and Report urls

export const PATH_TX_GETTRANSPLANTSUMMARIES = `${SERVER_HOST}/tx/transplantSummaries`;
export const PATH_TX_SUBMITDRAFTTRANSPLANT = `${SERVER_HOST}/tx/draftTransplant`;
export const PATH_TX_SUBMITTRANSPLANTEDITS = `${SERVER_HOST}/tx/transplantEdits`;
export const PATH_TX_REGISTERAPPROVEDTRANSPLANT = `${SERVER_HOST}/tx/registerApprovedTransplant`;
export const PATH_TX_CANCELAPPROVEDTRANSPLANT = `${SERVER_HOST}/tx/cancelApprovedTransplant`;
export const PATH_TX_REGISTERTRANSPLANTEDTRANSPLANT = `${SERVER_HOST}/tx/registerTransplantedTransplant`;
export const PATH_TX_GETDRAFTTRANSPLANT = `${SERVER_HOST}/tx/draftTransplant/%s`;
export const PATH_TX_GETTRANSPLANT = `${SERVER_HOST}/tx/transplant/%s`;

export const PATH_TX_GETANNEXUREADATA = `${SERVER_HOST}/tx/annexureAData`;
export const PATH_TX_GETANNEXUREZDATA = `${SERVER_HOST}/tx/annexureZData`;
export const PATH_TX_GETANNEXUREBSUMMARIES = `${SERVER_HOST}/tx/annexureBSummaries`;
export const PATH_TX_GETANNEXUREB = `${SERVER_HOST}/tx/annexureB`;
export const PATH_TX_GETANNEXURECDATA = `${SERVER_HOST}/tx/annexureCData`;
export const PATH_TX_GETBSDREPORTDATA = `${SERVER_HOST}/tx/bsdReportData`;
export const PATH_TX_GETKIDNEYSPOUSALTRANSPLANTATIONDATA = `${SERVER_HOST}/tx/kidneySpousalTransplantationData`;
export const PATH_TX_GETPROFORMAREPORTOFDONOROFTISSUEDATA = `${SERVER_HOST}/tx/proformaReportofDonorofTissueData`;
export const PATH_TX_GETPROFORMAREPORTOFTRANSPLANTOFTISSUEDATA = `${SERVER_HOST}/tx/proformaReportofTransplantofTissueData`;
export const PATH_TX_GETDONORDATA = `${SERVER_HOST}/tx/donorData`;
export const PATH_TX_GETRECIPIENTDATA = `${SERVER_HOST}/tx/recipientData`;
export const PATH_TX_GETORGANTRANSPLANTRECORD = `${SERVER_HOST}/tx/organTransplantRecord`;
export const PATH_TX_GETBSDDONORDETAILS = `${SERVER_HOST}/tx/bsdDonorDetails`;
export const PATH_TX_GETBSDDONORTISSUEDETAILS = `${SERVER_HOST}/tx/bsdDonorTissueDetails`;
export const PATH_TX_GETROTTOSOTTODATA = `${SERVER_HOST}/tx/rottoSottoData`;
export const PATH_TX_TRANSPLANTABROADANDNONRESIDENTSDATA = `${SERVER_HOST}/tx/transplantAbroadAndNonResidentsData`;
export const PATH_TX_PROFORMAFORMONTHREPORTDATA = `${SERVER_HOST}/tx/proformaMnthRptData`;
export const PATH_TX_UPDATERECIPIENTSURVIVALREPORT = `${SERVER_HOST}/tx/recipientSurvivalReport`;
export const PATH_TX_DELETEDRAFTTRANSPLANT = `${SERVER_HOST}/tx/isDeleted`;
export const PATH_TX_UPDATEDONORSURVIVALREPORT = `${SERVER_HOST}/tx/donorSurvivalReport`;
export const PATH_TX_GETANNEXUREB2DATA = `${SERVER_HOST}/tx/annexureB2Data`;
export const PATH_TX_GETTISSUEANDCORNEADONATIONANDTX = `${SERVER_HOST}/tx/tissueDonationAndTxIncludingCompositeVascularTissueAndCorneaData`;
export const PATH_TX_GETRECIPIENTEPIDEMIOLOGYBYAGEDATA = `${SERVER_HOST}/tx/recipientEpidemiologyByAgeData`;
export const PATH_TX_GETRECIPIENTEPIDEMIOLOGYBYSEXRATIODATA = `${SERVER_HOST}/tx/recipientEpidemiologyBySexRatioData`;
export const PATH_TX_GETORGANWISEWAITINGLIST = `${SERVER_HOST}/tx/organWiseWaitingList`;
export const PATH_TX_GETDEATHSDUETOPRIMARYGRAFTFAILUREDATA = `${SERVER_HOST}/tx/deathsDueToPrimaryGraftFailureData`;
export const PATH_TX_GETGRAFTREJECTIONDATA = `${SERVER_HOST}/tx/graftRejectionData`;
export const PATH_TX_GETRECIPIENTEPIDEMIOLOGYBYGOVTPVTSECTORWISEDATA = `${SERVER_HOST}/tx/recipientEpidemiologyByGovtPvtSectorWiseData`;
export const PATH_TX_GETRELIGIONWISERECIPIENTEDPIDEMIOLOGYDATA = `${SERVER_HOST}/tx/religionWiseRecipientEpidemiologyData`;
export const PATH_TX_GETCOMPLICATIONSINORGANTXDATA = `${SERVER_HOST}/tx/complicationsInOrganTxData`;
export const PATH_TX_GETCONSTITUTIONANDRISKFACTOR = `${SERVER_HOST}/tx/constitutionAndRiskFactorData`;
export const PATH_TX_GETDEATHSDUEOORGANTXDATA = `${SERVER_HOST}/tx/deathsDueToOrganTx`;
export const PATH_TX_GETCAUSEOFENDORGANFAILURENECESSITATINGTRANSPLANT = `${SERVER_HOST}/tx/causeOfEndOrganFailureNecessitatingTransplant`;
export const PATH_TX_GETWHODESKSURVEYREPORTSDATA = `${SERVER_HOST}/tx/whoDeskSurveyReportsData`;
export const PATH_TX_UPDATERECIPIENTDISCHARGESTATUS = `${SERVER_HOST}/tx/recipientDischargeStatus`;
export const PATH_TX_UPDATEDONORDISCHARGESTATUS = `${SERVER_HOST}/tx/donorDischargeStatus`;
export const PATH_TX_SENDMONTHLYREPORT = `${SERVER_HOST}/tx/monthlyReport/sendMonthlyReport`;
export const PATH_TX_GETREPORTPDFBUFFERFORDOWNLOAD = `${SERVER_HOST}/tx/reportPdfBufferForDownload`;
export const PATH_TX_GETDAILYTRANSPLANTSREPORT = `${SERVER_HOST}/tx/dailyTransplantsReport`;
export const PATH_TX_GETLIVINGDONORDATA = `${SERVER_HOST}/tx/livingDonorData`;
export const PATH_TX_GETPOSTTRANSPLANTOUTCOMEDATA = `${SERVER_HOST}/tx/postTransplantOutcomeDataOfOrgantransplanted`;
export const PATH_EXP_GENERATE_REPORTPDFBUFFER = `${SERVER_HOST}/tx/generateReportPdfBuffer`;
export const PATH_TX_GETANNEXUREB1REPORT = `${SERVER_HOST}/tx/annexutreB1Report`;
export const PATH_EXP_SENDREPORTEMAIL = `${SERVER_HOST}/tx/sendReportEmail`;

// form13 urls

export const PATH_FORM13_GETSUMMARIES = `${SERVER_HOST}/hota/form13/form13Summaries`;
export const PATH_FORM13_SUBMITDRAFTFORM13 = `${SERVER_HOST}/hota/form13/draftForm13`;
export const PATH_FORM13_GETDRAFTFORM13 = `${SERVER_HOST}/hota/form13/draftForm13/%s`;
export const PATH_FORM13_GETCOPYOFEXISTINGFORM13DETAILS = `${SERVER_HOST}/hota/form13/copyOfExistingForm13Details`;
export const PATH_FORM13_DELETEDRAFTFORM13 = `${SERVER_HOST}/hota/form13/draftForm13`;
export const PATH_FORM13_SUBMITFORM13EDITS = `${SERVER_HOST}/hota/form13/form13Edits`;
export const PATH_FORM13_UPDATEFORM13STATUS = `${SERVER_HOST}/hota/form13/form13Status`;
export const PATH_FORM13_SUBMITUNDERTAKING = `${SERVER_HOST}/hota/form13/form13Undertaking`;
export const PATH_FORM13_GETFORM13 = `${SERVER_HOST}/hota/form13/form13/%s`;
/* Why are these not used anywhere ?
export const PATH_FORM13_APPROVEFORM13 = `${SERVER_HOST}/hota/form13/approval`;
export const PATH_FORM13_GETAPPROVAL = `${SERVER_HOST}/hota/form13/approval/%s`;
*/
export const PATH_FORM13_SUBMITSPECIALISTCHANGEREQUEST = `${SERVER_HOST}/hota/form13/specialistChangeRequests`;
export const PATH_FORM13_UPDATESPECIALISTCHANGEREQUEST = `${SERVER_HOST}/hota/form13/specialistChangeRequests/%s`;
export const PATH_FORM13_GETSPECIALISTCHANGEREQUEST = `${SERVER_HOST}/hota/form13/specialistChangeRequests/%s`;
export const PATH_FORM13_SIGNFORM13 = `${SERVER_HOST}/hota/form13/signature`;
export const PATH_FORM13_ADDNOTING = `${SERVER_HOST}/hota/form13/noting`;
export const PATH_FORM13_ADDINSPECTIONPERMISSION = `${SERVER_HOST}/hota/form13/inspectionPermission`;
export const PATH_FORM13_ADDREGISTRATIONCERTIFICATE = `${SERVER_HOST}/hota/form13/registrationCertificate`;
export const PATH_FORM13_ADDDHSLETTER = `${SERVER_HOST}/hota/form13/dhsCertificate`;
export const PATH_FORM13_ADDINSPECTIONREPORT = `${SERVER_HOST}/hota/form13/inspectionReport`;

export const PATH_FORM13_REVERTFORM13 = `${SERVER_HOST}/hota/form13/revertForm13`;
export const PATH_FORM13_ADDISSUE = `${SERVER_HOST}/hota/form13/issue`;
export const PATH_FORM13_UPDATEISSUESTATUS = `${SERVER_HOST}/hota/form13/issueStatus`;
export const PATH_FORM13_GETISSUES = `${SERVER_HOST}/hota/form13/issues/%s`;

// legacyForm13 urls

export const PATH_LEGACYFORM13_GETSUMMARIES = `${SERVER_HOST}/hota/legacyForm13/legacyForm13Summaries`;
export const PATH_LEGACYFORM13_GETLEGACYFORM13 = `${SERVER_HOST}/hota/legacyForm13/legacyForm13/%s`;
export const PATH_LEGACYFORM13_SUBMITDRAFTLEGACYFORM13 = `${SERVER_HOST}/hota/legacyForm13/draftLegacyForm13`;
export const PATH_LEGACYFORM13_GETDRAFTLEGACYFORM13 = `${SERVER_HOST}/hota/legacyForm13/draftLegacyForm13/%s`;
export const PATH_LEGACYFORM13_DELETEDRAFTLEGACYFORM13 = `${SERVER_HOST}/hota/legacyForm13/draftLegacyForm13`;
export const PATH_LEGACYFORM13_SUBMITLEGACYFORM13EDITS = `${SERVER_HOST}/hota/legacyForm13/legacyForm13Edits`;
export const PATH_LEGACYFORM13_UPDATELEGACYFORM13STATUS = `${SERVER_HOST}/hota/legacyForm13/legacyForm13Status`;
export const PATH_LEGACYFORM13_REVERTLEGACYFORM13 = `${SERVER_HOST}/hota/legacyForm13/revertLegacyForm13`;
export const PATH_LEGACYFORM13_SUBMITSPECIALISTCHANGEREQUEST = `${SERVER_HOST}/hota/legacyForm13/specialistChangeRequests`;
export const PATH_LEGACYFORM13_UPDATESPECIALISTCHANGEREQUEST = `${SERVER_HOST}/hota/legacyForm13/specialistChangeRequests/%s`;
export const PATH_LEGACYFORM13_GETSPECIALISTCHANGEREQUEST = `${SERVER_HOST}/hota/legacyForm13/specialistChangeRequests/%s`;
/* Not used
export const PATH_LEGACYFORM13_GETAPPROVAL = `${SERVER_HOST}/hota/legacyForm13/approval/%s`;
*/
export const PATH_LEGACYFORM13_ADDISSUE = `${SERVER_HOST}/hota/legacyForm13/issue`;
export const PATH_LEGACYFORM13_UPDATEISSUESTATUS = `${SERVER_HOST}/hota/legacyForm13/issueStatus`;
export const PATH_LEGACYFORM13_GETISSUES = `${SERVER_HOST}/hota/legacyForm13/issues/%s`;
export const PATH_LEGACYFORM13_APPROVELEGACYFORM13 = `${SERVER_HOST}/hota/legacyForm13/approval`;

// form14 urls

export const PATH_FORM14_GETSUMMARIES = `${SERVER_HOST}/hota/form14/form14Summaries`;
export const PATH_FORM14_SUBMITDRAFTFORM14 = `${SERVER_HOST}/hota/form14/draftForm14`;
export const PATH_FORM14_GETDRAFTFORM14 = `${SERVER_HOST}/hota/form14/draftForm14/%s`;
export const PATH_FORM14_DELETEDRAFTFORM14 = `${SERVER_HOST}/hota/form14/draftForm14`;
export const PATH_FORM14_SUBMITFORM14EDITS = `${SERVER_HOST}/hota/form14/form14Edits`;
export const PATH_FORM14_UPDATEFORM14STATUS = `${SERVER_HOST}/hota/form14/form14Status`;
export const PATH_FORM14_SUBMITUNDERTAKING = `${SERVER_HOST}/hota/form14/form14Undertaking`;
export const PATH_FORM14_GETFORM14 = `${SERVER_HOST}/hota/form14/form14/%s`;
export const PATH_FORM14_SUBMITSPECIALISTCHANGEREQUEST = `${SERVER_HOST}/hota/form14/specialistChangeRequests`;
export const PATH_FORM14_UPDATESPECIALISTCHANGEREQUEST = `${SERVER_HOST}/hota/form14/specialistChangeRequests/%s`;
export const PATH_FORM14_GETSPECIALISTCHANGEREQUEST = `${SERVER_HOST}/hota/form14/specialistChangeRequests/%s`;
/*
export const PATH_FORM14_APPROVEFORM14 = `${SERVER_HOST}/hota/form14/approval/form14`;
export const PATH_FORM14_GETAPPROVAL = `${SERVER_HOST}/hota/form14/approval/%s`;
export const PATH_FORM14_ADDCERTIFICATE = `${SERVER_HOST}/hota/form14/certificate`;
export const PATH_FORM14_ADDISSUE = `${SERVER_HOST}/hota/form14/issue`;
*/
export const PATH_FORM14_SIGNFORM14 = `${SERVER_HOST}/hota/form14/signature/form14`;
export const PATH_FORM14_ADDNOTING = `${SERVER_HOST}/hota/form14/noting`;
export const PATH_FORM14_REVERTFORM14 = `${SERVER_HOST}/hota/form14/revertForm14`;
export const PATH_FORM14_UPDATEISSUESTATUS = `${SERVER_HOST}/hota/form14/issueStatus`;
export const PATH_FORM14_GETISSUES = `${SERVER_HOST}/hota/form14/issues/%s`;
export const PATH_FORM14_ADDISSUE = `${SERVER_HOST}/hota/form14/issue`;
export const PATH_FORM14_ADDINSPECTIONPERMISSION = `${SERVER_HOST}/hota/form14/inspectionPermission`;
export const PATH_FORM14_ADDREGISTRATIONCERTIFICATE = `${SERVER_HOST}/hota/form14/registrationCertificate`;
export const PATH_FORM14_ADDDHSLETTER = `${SERVER_HOST}/hota/form14/dhsCertificate`;
export const PATH_FORM14_ADDINSPECTIONREPORT = `${SERVER_HOST}/hota/form14/inspectionReport`;

// legacyForm14 urls

export const PATH_LEGACYFORM14_GETSUMMARIES = `${SERVER_HOST}/hota/legacyForm14/legacyForm14Summaries`;
export const PATH_LEGACYFORM14_GETLEGACYFORM14 = `${SERVER_HOST}/hota/legacyForm14/legacyForm14/%s`;
export const PATH_LEGACYFORM14_SUBMITDRAFTLEGACYFORM14 = `${SERVER_HOST}/hota/legacyForm14/draftLegacyForm14`;
export const PATH_LEGACYFORM14_GETDRAFTLEGACYFORM14 = `${SERVER_HOST}/hota/legacyForm14/draftLegacyForm14/%s`;
export const PATH_LEGACYFORM14_SUBMITLEGACYFORM14EDITS = `${SERVER_HOST}/hota/legacyForm14/legacyForm14Edits`;
export const PATH_LEGACYFORM14_UPDATELEGACYFORM14STATUS = `${SERVER_HOST}/hota/legacyForm14/legacyForm14Status`;
export const PATH_LEGACYFORM14_REVERTLEGACYFORM14 = `${SERVER_HOST}/hota/legacyForm14/revertLegacyForm14`;
export const PATH_LEGACYFORM14_SUBMITSPECIALISTCHANGEREQUEST = `${SERVER_HOST}/hota/legacyForm14/specialistChangeRequests`;
export const PATH_LEGACYFORM14_UPDATESPECIALISTCHANGEREQUEST = `${SERVER_HOST}/hota/legacyForm14/specialistChangeRequests/%s`;
export const PATH_LEGACYFORM14_GETSPECIALISTCHANGEREQUEST = `${SERVER_HOST}/hota/legacyForm14/specialistChangeRequests/%s`;
export const PATH_LEGACYFORM14_ADDISSUE = `${SERVER_HOST}/hota/legacyForm14/issue`;
export const PATH_LEGACYFORM14_UPDATEISSUESTATUS = `${SERVER_HOST}/hota/legacyForm14/issueStatus`;
export const PATH_LEGACYFORM14_GETISSUES = `${SERVER_HOST}/hota/legacyForm14/issues/%s`;
export const PATH_LEGACYFORM14_APPROVELEGACYFORM14 = `${SERVER_HOST}/hota/legacyForm14/approval/legacyForm14`;
export const PATH_LEGACYFORM14_DELETEDRAFTLEGACYFORM14 = `${SERVER_HOST}/hota/legacyForm14/draftLegacyForm14`;
/*
export const PATH_LEGACYFORM14_DELETEDRAFTLEGACYFORM14 = `${SERVER_HOST}/hota/legacyForm14/draftLegacyForm14`;
export const PATH_LEGACYFORM14_GETAPPROVAL = `${SERVER_HOST}/hota/legacyForm14/approval/%s`;
*/

// core urls

export const PATH_GETDASHBOARDCONTENTS = `${SERVER_HOST}/core/dashboardContents`;
export const PATH_SENDBROADCASTNOTIFICATION = `${SERVER_HOST}/core/notification`;
export const PATH_REGISTERREQUESTEDHOSPITAL = `${SERVER_HOST}/request/hospitalRequest`;

export const PATH_EXP_REGISTERREQUESTROTTOSOTTO = `${SERVER_HOST}/request/rottoSottoRequest`;

export const PATH_EXP_REGISTERREQUESTDMER = `${SERVER_HOST}/request/dmerRequest`;

export const PATH_EXP_REGISTERREQUESTZTCC = `${SERVER_HOST}/request/ztccRequest`;

export const PATH_EXP_GETHOSPITALS = `${SERVER_HOST}/core/hospitals`;

export const PATH_EXP_GETHOSPITALDATA = `${SERVER_HOST}/core/hospitalsData`;

export const PATH_EXP_GETREJECTEDHOSPITALS = `${SERVER_HOST}/core/rejectedHospitals`;

export const PATH_EXP_GETINSTITUTES = `${SERVER_HOST}/core/rottoSottoRequests`;

export const PATH_EXP_GETZTCC = `${SERVER_HOST}/core/ztccRequests`;

export const PATH_EXP_GETREQUESTEDHOSPITALS = `${SERVER_HOST}/core/requestedHospitals`;

export const PATH_EXP_GETHOSPITALREQUEST = `${SERVER_HOST}/core/hospitalRequest/%s`;

export const PATH_EXP_GETROTTOSOTTOREQUEST = `${SERVER_HOST}/core/rottoSottoRequest/%s`;

export const PATH_EXP_GETUSERHOSPITALS = `${SERVER_HOST}/core/userHospitals`;

export const PATH_EXP_GETHOSPITAL = `${SERVER_HOST}/core/hospital`;

export const PATH_EXP_GETROTTOPROFILEDETAILS = `${SERVER_HOST}/core/rottoProfileDetails`;

export const PATH_EXP_UPDATEHOSPITAL = `${SERVER_HOST}/core/hospitalEdits`;

export const PATH_EXP_GETINDEXHOSPITAL = `${SERVER_HOST}/core/indexHospital`;

export const PATH_EXP_SUBMITINCHARGES = `${SERVER_HOST}/core/incharges`;

export const PATH_EXP_DISABLEINCHARGE = `${SERVER_HOST}/core/incharge`;

export const PATH_EXP_DISABLEZTCCUSER = `${SERVER_HOST}/core/disable/ztccUser`;

export const PATH_EXP_DISABLEZTCCMEMBER = `${SERVER_HOST}/core/disable/ztccMember`;

export const PATH_EXP_DISABLEMEDICALINCHARGE = `${SERVER_HOST}/core/disable/medicalIncharge`;

export const PATH_EXP_SUBMITHOSPITALMEDICALINCHARGE = `${SERVER_HOST}/core/medicalIncharge`;

export const PATH_EXP_UPDATEMEDICALINCHARGE = `${SERVER_HOST}/core/medicalIncharge`;

export const PATH_EXP_GETMEDICALINCHARGES = `${SERVER_HOST}/core/medicalIncharges`;

export const PATH_EXP_GETMEDICALINCHARGE = `${SERVER_HOST}/core/medicalIncharge/%s`;

export const PATH_EXP_UPDATEHOSPITALINCHARGE = `${SERVER_HOST}/core/hospitalIncharge`;

export const PATH_EXP_SUBMITSPECIALIST = `${SERVER_HOST}/core/specialist`;

export const PATH_EXP_GETSPECIALISTS = `${SERVER_HOST}/core/specialists`;

export const PATH_EXP_GETSPECIALISTBYID = `${SERVER_HOST}/core/specialistById`;

export const PATH_EXP_GETSPECIALISTBYPARTIALNAME = `${SERVER_HOST}/core/specialistByPartialName`;

export const PATH_EXP_GETSPECIALISTBYHOSPITALID = `${SERVER_HOST}/core/specialistsByHospitalId`;

export const PATH_EXP_GETSPECIALIST = `${SERVER_HOST}/core/specialist/%s`;

export const PATH_EXP_UPDATESPECIALIST = `${SERVER_HOST}/core/specialist`;

export const PATH_EXP_SUBMITTRANSPLANTCOORDINATOR = `${SERVER_HOST}/core/transplantCoordinator`;

export const PATH_EXP_SUBMITTRANSPLANTCOORDINATORAPPROVAL = `${SERVER_HOST}/core/transplantCoordinatorApprovalStatus`;

export const PATH_EXP_GETTRANSPLANTCOORDINATORS = `${SERVER_HOST}/core/transplantCoordinators`;

export const PATH_EXP_GETFORM12TRANSPLANTCOORDINATORS = `${SERVER_HOST}/core/form12TransplantCoordinators`;

export const PATH_EXP_GETTRANSPLANTCOORDINATOR = `${SERVER_HOST}/core/transplantCoordinator/%s`;

export const PATH_EXP_DELETESPECIALIST = `${SERVER_HOST}/core/specialist/isDeleted`;

export const PATH_EXP_DELETEHOSPITAL = `${SERVER_HOST}/core/hospital/isDeleted`;

export const PATH_EXP_DELETETRANSPLANTCOORD = `${SERVER_HOST}/core/transplantCoordinator/isDeleted`;

export const PATH_EXP_DELETEBSDDONOR = `${SERVER_HOST}/core/bsddonor/isDeleted`;

export const PATH_EXP_APPROVESPECIALIST = `${SERVER_HOST}/core/approveSpecialist`;

export const PATH_EXP_SETUSERTOC = `${SERVER_HOST}/core/userToc`;

export const PATH_EXP_GETDOCTORDETAILS = `${SERVER_HOST}/resources/doctorDetails`;

export const PATH_EXP_ADDROTTOUSERS = `${SERVER_HOST}/core/rottoUsers`;

export const PATH_EXP_ADDREGULATORS = `${SERVER_HOST}/core/regulators`;

export const PATH_EXP_GETREGULATORS = `${SERVER_HOST}/core/regulators`;

export const PATH_EXP_SUBMITHOSPITALAPPROVAL = `${SERVER_HOST}/core/hospitalRequestApproval`;

export const PATH_EXP_SUBMITHOSPITALREJECTION = `${SERVER_HOST}/core/hospitalRequestReject`;

export const PATH_EXP_SUBMITROTTOSOTTOAPPROVAL = `${SERVER_HOST}/core/rottoSottoRequestApproval`;

export const PATH_EXP_SUBMITROTTOSOTTOREJECTION = `${SERVER_HOST}/core/rottoSottoRequestReject`;

export const PATH_EXP_SUBMITZTCCAPPROVAL = `${SERVER_HOST}/core/ztccRequestApproval`;

export const PATH_EXP_SUBMITZTCCREJECTION = `${SERVER_HOST}/core/ztccRequestReject`;

export const PATH_EXP_ADDDMERINCHARGES = `${SERVER_HOST}/core/dmerIncharges`;

export const PATH_EXP_RESOURCE_POSTREACTFEEDBACK = `${SERVER_HOST}/resources/feedback/reactError`;

export const PATH_EXP_RESOURCE_POSTFEEDBACK = `${SERVER_HOST}/resources/feedback/slackFeedback`;

export const PATH_EXP_GETPLEDGEDDONORS = `${SERVER_HOST}/core/pledgedDonors`;

export const PATH_EXP_GETPLEDGEDDONOR = `${SERVER_HOST}/core/pledgedDonor`;

export const PATH_EXP_GETZTCCUSERS = `${SERVER_HOST}/core/ztccUsers`;

export const PATH_EXP_SUBMITZTCCUSERS = `${SERVER_HOST}/core/ztccUsers`;

export const PATH_EXP_UPDATEZTCCUSER = `${SERVER_HOST}/core/ztccUser`;

export const PATH_EXP_GETZTCCSUBCOMMITTEE = `${SERVER_HOST}/core/ztccSubCommittee`;

export const PATH_EXP_SUBMITZTCCSUBCOMMITTEEMEMBER = `${SERVER_HOST}/core/ztccSubCommitteeMember`;

export const PATH_EXP_GETROTTOSUBCOMMITTEE = `${SERVER_HOST}/core/rottoSubCommittee`;

export const PATH_EXP_SUBMITROTTOSUBCOMMITTEEMEMBER = `${SERVER_HOST}/core/rottoSubCommitteeMember`;

export const PATH_EXP_DELETEROTTOSUBCOMMITTEEMEMBER = `${SERVER_HOST}/core/deleteRottoSubCommitteeMember`;

export const PATH_EXP_SUBMITROTTOUSERS = `${SERVER_HOST}/core/rottoUsers`;

export const PATH_EXP_DISABLEROTTOUSER = `${SERVER_HOST}/core/disable/rottoUser`;

export const PATH_EXP_GETROTTOUSERS = `${SERVER_HOST}/core/rottoUsers`;

export const PATH_EXP_UPDATEZTCCZONE = `${SERVER_HOST}/core/ztccZone`;

export const PATH_EXP_UPDATECENTRALGOVHOSPITAL = `${SERVER_HOST}/core/centralGovHospital`;

// legacyForm15Ctc urls

export const PATH_EXP_LEGACYFORM15CTC_GETSUMMARIES = `${SERVER_HOST}/hota/legacyForm15Ctc/legacyForm15CtcSummaries`;

export const PATH_EXP_LEGACYFORM15CTC_GETLEGACYFORM15CTC = `${SERVER_HOST}/hota/legacyForm15Ctc/legacyForm15Ctc/%s`;

export const PATH_EXP_LEGACYFORM15CTC_SUBMITDRAFTLEGACYFORM15CTC = `${SERVER_HOST}/hota/legacyForm15Ctc/draftLegacyForm15Ctc`;

export const PATH_EXP_LEGACYFORM15CTC_GETDRAFTLEGACYFORM15CTC = `${SERVER_HOST}/hota/legacyForm15Ctc/draftLegacyForm15Ctc/%s`;

export const PATH_EXP_LEGACYFORM15CTC_DELETEDRAFTLEGACYFORM15CTC = `${SERVER_HOST}/hota/legacyForm15Ctc/draftLegacyForm15Ctc`;

export const PATH_EXP_LEGACYFORM15CTC_SUBMITLEGACYFORM15CTCEDITS = `${SERVER_HOST}/hota/legacyForm15Ctc/legacyForm15CtcEdits`;

export const PATH_EXP_LEGACYFORM15CTC_UPDATELEGACYFORM15CTCSTATUS = `${SERVER_HOST}/hota/legacyForm15Ctc/legacyForm15CtcStatus`;

export const PATH_EXP_LEGACYFORM15CTC_REVERTLEGACYFORM15CTC = `${SERVER_HOST}/hota/legacyForm15Ctc/revertLegacyForm15Ctc`;

export const PATH_EXP_LEGACYFORM15CTC_SUBMITSPECIALISTCHANGEREQUEST = `${SERVER_HOST}/hota/legacyForm15Ctc/specialistChangeRequests`;

export const PATH_EXP_LEGACYFORM15CTC_UPDATESPECIALISTCHANGEREQUEST = `${SERVER_HOST}/hota/legacyForm15Ctc/specialistChangeRequests/%s`;

export const PATH_EXP_LEGACYFORM15CTC_GETSPECIALISTCHANGEREQUEST = `${SERVER_HOST}/hota/legacyForm15Ctc/specialistChangeRequests/%s`;

export const PATH_EXP_LEGACYFORM15CTC_ADDISSUE = `${SERVER_HOST}/hota/legacyForm15Ctc/issue`;

export const PATH_EXP_LEGACYFORM15CTC_UPDATEISSUESTATUS = `${SERVER_HOST}/hota/legacyForm15Ctc/issueStatus`;

export const PATH_EXP_LEGACYFORM15CTC_GETISSUES = `${SERVER_HOST}/hota/legacyForm15Ctc/issues/%s`;

export const PATH_EXP_LEGACYFORM15CTC_APPROVELEGACYFORM15CTC = `${SERVER_HOST}/hota/legacyForm15Ctc/approval/legacyForm15Ctc`;

export const PATH_EXP_LEGACYFORM15CTC_GETAPPROVAL = `${SERVER_HOST}/hota/legacyForm15Ctc/approval/%s`;

// legacyForm15Eb urls

export const PATH_EXP_LEGACYFORM15EB_GETSUMMARIES = `${SERVER_HOST}/hota/legacyForm15Eb/legacyForm15EbSummaries`;

export const PATH_EXP_LEGACYFORM15EB_GETLEGACYFORM15EB = `${SERVER_HOST}/hota/legacyForm15Eb/legacyForm15Eb/%s`;

export const PATH_EXP_LEGACYFORM15EB_SUBMITDRAFTLEGACYFORM15EB = `${SERVER_HOST}/hota/legacyForm15Eb/draftLegacyForm15Eb`;

export const PATH_EXP_LEGACYFORM15EB_GETDRAFTLEGACYFORM15EB = `${SERVER_HOST}/hota/legacyForm15Eb/draftLegacyForm15Eb/%s`;

export const PATH_EXP_LEGACYFORM15EB_DELETEDRAFTLEGACYFORM15EB = `${SERVER_HOST}/hota/legacyForm15Eb/draftLegacyForm15Eb`;

export const PATH_EXP_LEGACYFORM15EB_SUBMITLEGACYFORM15EBEDITS = `${SERVER_HOST}/hota/legacyForm15Eb/legacyForm15EbEdits`;

export const PATH_EXP_LEGACYFORM15EB_UPDATELEGACYFORM15EBSTATUS = `${SERVER_HOST}/hota/legacyForm15Eb/legacyForm15EbStatus`;

export const PATH_EXP_LEGACYFORM15EB_REVERTLEGACYFORM15EB = `${SERVER_HOST}/hota/legacyForm15Eb/revertLegacyForm15Eb`;

export const PATH_EXP_LEGACYFORM15EB_SUBMITSPECIALISTCHANGEREQUEST = `${SERVER_HOST}/hota/legacyForm15Eb/specialistChangeRequests`;

export const PATH_EXP_LEGACYFORM15EB_UPDATESPECIALISTCHANGEREQUEST = `${SERVER_HOST}/hota/legacyForm15Eb/specialistChangeRequests/%s`;

export const PATH_EXP_LEGACYFORM15EB_GETSPECIALISTCHANGEREQUEST = `${SERVER_HOST}/hota/legacyForm15Eb/specialistChangeRequests/%s`;

export const PATH_EXP_LEGACYFORM15EB_ADDISSUE = `${SERVER_HOST}/hota/legacyForm15Eb/issue`;

export const PATH_EXP_LEGACYFORM15EB_UPDATEISSUESTATUS = `${SERVER_HOST}/hota/legacyForm15Eb/issueStatus`;

export const PATH_EXP_LEGACYFORM15EB_GETISSUES = `${SERVER_HOST}/hota/legacyForm15Eb/issues/%s`;

export const PATH_EXP_LEGACYFORM15EB_APPROVELEGACYFORM15EB = `${SERVER_HOST}/hota/legacyForm15Eb/approval/legacyForm15Eb`;

export const PATH_EXP_LEGACYFORM15EB_GETAPPROVAL = `${SERVER_HOST}/hota/legacyForm15Eb/approval/%s`;

// legacyForm15Erc urls

export const PATH_EXP_LEGACYFORM15ERC_GETSUMMARIES = `${SERVER_HOST}/hota/legacyForm15Erc/legacyForm15ErcSummaries`;

export const PATH_EXP_LEGACYFORM15ERC_GETLEGACYFORM15ERC = `${SERVER_HOST}/hota/legacyForm15Erc/legacyForm15Erc/%s`;

export const PATH_EXP_LEGACYFORM15ERC_SUBMITDRAFTLEGACYFORM15ERC = `${SERVER_HOST}/hota/legacyForm15Erc/draftLegacyForm15Erc`;

export const PATH_EXP_LEGACYFORM15ERC_GETDRAFTLEGACYFORM15ERC = `${SERVER_HOST}/hota/legacyForm15Erc/draftLegacyForm15Erc/%s`;

export const PATH_EXP_LEGACYFORM15ERC_DELETEDRAFTLEGACYFORM15ERC = `${SERVER_HOST}/hota/legacyForm15Erc/draftLegacyForm15Erc`;

export const PATH_EXP_LEGACYFORM15ERC_SUBMITLEGACYFORM15ERCEDITS = `${SERVER_HOST}/hota/legacyForm15Erc/legacyForm15ErcEdits`;

export const PATH_EXP_LEGACYFORM15ERC_UPDATELEGACYFORM15ERCSTATUS = `${SERVER_HOST}/hota/legacyForm15Erc/legacyForm15ErcStatus`;

export const PATH_EXP_LEGACYFORM15ERC_REVERTLEGACYFORM15ERC = `${SERVER_HOST}/hota/legacyForm15Erc/revertLegacyForm15Erc`;

export const PATH_EXP_LEGACYFORM15ERC_SUBMITSPECIALISTCHANGEREQUEST = `${SERVER_HOST}/hota/legacyForm15Erc/specialistChangeRequests`;

export const PATH_EXP_LEGACYFORM15ERC_UPDATESPECIALISTCHANGEREQUEST = `${SERVER_HOST}/hota/legacyForm15Erc/specialistChangeRequests/%s`;

export const PATH_EXP_LEGACYFORM15ERC_GETSPECIALISTCHANGEREQUEST = `${SERVER_HOST}/hota/legacyForm15Erc/specialistChangeRequests/%s`;

export const PATH_EXP_LEGACYFORM15ERC_ADDISSUE = `${SERVER_HOST}/hota/legacyForm15Erc/issue`;

export const PATH_EXP_LEGACYFORM15ERC_UPDATEISSUESTATUS = `${SERVER_HOST}/hota/legacyForm15Erc/issueStatus`;

export const PATH_EXP_LEGACYFORM15ERC_GETISSUES = `${SERVER_HOST}/hota/legacyForm15Erc/issues/%s`;

export const PATH_EXP_LEGACYFORM15ERC_APPROVELEGACYFORM15ERC = `${SERVER_HOST}/hota/legacyForm15Erc/approval/legacyForm15Erc`;

export const PATH_EXP_LEGACYFORM15ERC_GETAPPROVAL = `${SERVER_HOST}/hota/legacyForm15Erc/approval/%s`;

// Bsd

export const PATH_BSD_SUBMITDRAFTORGANDONOR = `${SERVER_HOST}/tx/bsd/draftOrganDonor`;

export const PATH_BSD_SUBMITORGANDONOREDITS = `${SERVER_HOST}/tx/bsd/organDonorEdits`;

export const PATH_BSD_GETDRAFTORGANDONOR = `${SERVER_HOST}/tx/bsd/draftOrganDonor`;

export const PATH_BSD_GETORGANDONOR = `${SERVER_HOST}/tx/bsd/organDonor`;

export const PATH_BSD_UPDATEORGANDONORSTATUS = `${SERVER_HOST}/tx/bsd/organDonorStatus`;

export const PATH_BSD_GETORGANDONORSUMMARIES = `${SERVER_HOST}/tx/bsd/organDonorSummaries`;

// Ztcc

export const PATH_EXP_GETALLZTCCHOSPITALS = `${SERVER_HOST}/ztcc/ztccHospitals`;

export const PATH_EXP_SUBMITZTCCCONNECTIONREQUEST = `${SERVER_HOST}/ztcc/ztccConnectionRequest`;

export const PATH_EXP_GETZTCCCONNECTIONREQUESTS = `${SERVER_HOST}/ztcc/ztccConnectionRequests`;

export const PATH_EXP_UPDATEZTCCCONNECTIONREQUESTSTATUS = `${SERVER_HOST}/ztcc/ztccConnectionRequestStatus`;

export const PATH_EXP_GETDONORSUMMARIES = `${SERVER_HOST}/allocation/organDonor/organDonorSummaries`;

export const PATH_EXP_GETDONOR = `${SERVER_HOST}/allocation/organDonor/organDonor/%s`;

// Allocation

export const PATH_EXP_SUBMITDRAFTORGANDONOR = `${SERVER_HOST}/allocation/organDonor/draftOrganDonor`;

export const PATH_EXP_SUBMITORGANDONOREDITS = `${SERVER_HOST}/allocation/organDonor/organDonorEdits`;

export const PATH_EXP_GETDRAFTORGANDONOR = `${SERVER_HOST}/allocation/organDonor/draftOrganDonor`;

export const PATH_EXP_GETORGANDONOR = `${SERVER_HOST}/allocation/organDonor/organDonor/%s`;

export const PATH_EXP_UPDATEORGANDONORSTATUS = `${SERVER_HOST}/allocation/organDonor/organDonorStatus`;

export const PATH_EXP_GETORGANDONORSUMMARIES = `${SERVER_HOST}/allocation/organDonor/organDonorSummaries`;

export const PATH_EXP_SUBMITORGANRECIPIENT = `${SERVER_HOST}/allocation/organRecipient/organRecipient`;

export const PATH_EXP_GETORGANRECIPIENT = `${SERVER_HOST}/allocation/organRecipient/organRecipient/%s`;

export const PATH_EXP_GETORGANRECIPIENTSUMMARIES = `${SERVER_HOST}/allocation/organRecipient/organRecipientSummaries`;

// HeartOrganCall

export const PATH_EXP_GETORGANCALLSUMMARIESBYDONORID = `${SERVER_HOST}/allocation/organCall/organCallSummariesByDonorId`;

export const PATH_EXP_GETORGANCALLSUMMARIES = `${SERVER_HOST}/allocation/organCall/organCallSummaries`;

export const PATH_EXP_GETORGANCALL = `${SERVER_HOST}/allocation/organCall/organCallRecord/%s`;

export const PATH_EXP_UPDATEORGANCALLRECIPIENTSTATUS = `${SERVER_HOST}/allocation/organCall/organCallRecipientStatus`;

export const PATH_EXP_GETHEARTORGANCALLSUMMARIES = `${SERVER_HOST}/allocation/organCall/heartOrganCallSummaries`;

export const PATH_EXP_GETHEARTORGANCALL = `${SERVER_HOST}/allocation/organCall/heartOrganCall/%s`;

export const PATH_EXP_UPDATEHEARTORGANCALLSTATUS = `${SERVER_HOST}/allocation/organCall/heartOrganCallStatus`;

export const PATH_EXP_UPDATEHEARTORGANCALLRECIPIENTSTATUS = `${SERVER_HOST}/allocation/organCall/heartOrganCallRecipientStatus`;

export const PATH_EXP_UPDATEHEARTORGANCALLTRANSPLANTSTATUS = `${SERVER_HOST}/allocation/organCall/heartOrganCallTransplantStatus`;

export const PATH_EXP_SUBMITHEARTORGANCALL = `${SERVER_HOST}/allocation/organDonor/heartOrganCall`;

export const PATH_EXP_SUBMITORGANCALL = `${SERVER_HOST}/allocation/organDonor/organCall`;

export const PATH_EXP_SUBMITHEARTORGANCALLCROSSMATCHING = `${SERVER_HOST}/allocation/organCall/heartOrganCallCrossMatching`;

export const PATH_EXP_SUBMITHEARTORGANCALLMATCHING = `${SERVER_HOST}/allocation/organCall/heartOrganCallMatching`;

export const PATH_EXP_HEARTORGANCALLAPPROVETRANSPLANT = `${SERVER_HOST}/allocation/organCall/heartOrganCallApproveTransplant`;

export const PATH_EXP_GETDONORHOSPITALTOPRECIPIENTHEARTSUMMARIES = `${SERVER_HOST}/allocation/organRecipient/donorHospitalTopRecipientHeartSummaries`;

export const PATH_EXP_GETHEARTORGANCALLEVENTS = `${SERVER_HOST}/allocation/organDonor/heartOrganCallEvents`;

export const PATH_EXP_GETTOPRECIPIENTHEARTSUMMARIES = `${SERVER_HOST}/allocation/organRecipient/topRecipientHeartSummaries`;

export const PATH_EXP_GETRECIPIENTHEARTSUMMARIES = `${SERVER_HOST}/allocation/organRecipient/recipientHeartSummaries`;

export const PATH_EXP_GETDONORHEART = `${SERVER_HOST}/allocation/organDonor/donorHeart`;

export const PATH_EXP_SUBMITDONORHEART = `${SERVER_HOST}/allocation/organDonor/donorHeartStatus`;

export const PATH_EXP_SUBMITDONORHEARTEDITS = `${SERVER_HOST}/allocation/organDonor/donorHeartEdits`;

export const PATH_EXP_GETDONORHEARTSUMMARIES = `${SERVER_HOST}/allocation/organDonor/donorHeartSummaries`;

export const PATH_EXP_GETRECIPIENTHEART = `${SERVER_HOST}/allocation/organRecipient/recipientHeart`;

export const PATH_EXP_SUBMITRECIPIENTHEARTEDITS = `${SERVER_HOST}/allocation/organRecipient/recipientHeartEdits`;

export const PATH_EXP_SUBMITRECIPIENTHEART = `${SERVER_HOST}/allocation/organRecipient/recipientHeartStatus`;

export const PATH_EXP_DUPLICATEALLOCATIONRECIPIENTLISTINGCHECK = `${SERVER_HOST}/allocation/recipientListing/duplicateRegistrationCheck`;

export const PATH_EXP_SUBMITDRAFTALLOCATIONRECIPIENTLISTING = `${SERVER_HOST}/allocation/recipientListing/draftAllocationListing`;

export const PATH_EXP_SUBMITALLOCATIONRECIPIENTLISTINGEDITS = `${SERVER_HOST}/allocation/recipientListing/recipientListingEdits`;

export const PATH_EXP_UPDATEALLOCATIONRECIPIENTLISTINGFORMSTATUS = `${SERVER_HOST}/allocation/recipientListing/allocationRecipientFormStatus`;

export const PATH_EXP_SUBMITALLOCATIONRECIPIENTLISTING = `${SERVER_HOST}/allocation/recipientListing/allocationListing`;

export const PATH_EXP_SUBMITRECIPIENTLIVERDETAILSUPDATE = `${SERVER_HOST}/allocation/recipientListing/liverDetails`;

export const PATH_EXP_GETORGANRECIPIENTORGANHISTORY = `${SERVER_HOST}/allocation/recipientListing/organHistory/%s`;

export const PATH_EXP_GETALLOCATIONRECIPIENTLISTINGSUMMARIES = `${SERVER_HOST}/allocation/allocationWaitingList/waitingList`;

export const PATH_EXP_SUBMITALLOCATIONDONOR = `${SERVER_HOST}/allocation/allocationDonor/donor`;

export const PATH_EXP_SUBMITDRAFTALLOCATIONDONOR = `${SERVER_HOST}/allocation/allocationDonor/draftDonor`;

export const PATH_EXP_SUBMITALLOCATIONDONORLISTINGEDITS = `${SERVER_HOST}/allocation/allocationDonor/donorListingEdits`;

export const PATH_EXP_ECDDCDINFO = `${SERVER_HOST}/allocation/allocationDonor/ecdDcdInfo`;

export const PATH_EXP_GETALLOCATIONDONOR = `${SERVER_HOST}/allocation/allocationDonor/donor/%s`;

export const PATH_EXP_GETALLOCATIONRECIPIENTLISTING = `${SERVER_HOST}/allocation/recipientListing/allocationListing/%s`;

export const PATH_EXP_GETALLOCATIONDONORSUMMARIES = `${SERVER_HOST}/allocation/allocationDonor/allocationDonorSummaries`;

export const PATH_EXP_GETALLOCATIONRECIPIENTMLASDETAILS = `${SERVER_HOST}/allocation/recipientListing/allocationRecipientMlasDetails/%s`;

export const PATH_EXP_UPDATEMLASREPORTAPPROVAL = `${SERVER_HOST}/allocation/recipientListing/allocationRecipientMlasReportApproval`;

export const PATH_GETHOSPITALRECIPIENTLISTING = `${SERVER_HOST}/allocation/recipientListing/recipientListingSummaries`;

export const PATH_GETHOSPITALRECIPIENTSUMMARIES = `${SERVER_HOST}/allocation/recipientListing/recipientSummaries`;

export const PATH_DELETEDRAFTRECIPIENT = `${SERVER_HOST}/allocation/recipientListing/recipientSummaries`;

export const PATH_EXP_UPDATERECIPIENTWAITINGLISTAPPROVAL = `${SERVER_HOST}/allocation/recipientListing/allocationListingWaitingApproval`;

export const PATH_EXP_GETRECIPIENTWAITINGLISTAPPROVAL = `${SERVER_HOST}/allocation/recipientListing/allocationListingWaitingApproval/%s`;

export const PATH_EXP_UPDATERECIPIENTWAITINGSTATUS = `${SERVER_HOST}/allocation/recipientListing/allocationListingWaitingStatus`;

export const PATH_EXP_ADDTRANSFERREGISTRATION = `${SERVER_HOST}/allocation/recipientListing/transferRegistration`;

export const PATH_EXP_UPDATETRANSFERREGISTRATIONSTATUS = `${SERVER_HOST}/allocation/recipientListing/transferRegistrationStatus`;

export const PATH_EXP_GETTRANSFERREGISTRATIONSUMMARIES = `${SERVER_HOST}/allocation/recipientListing/transferRegistrationSummaries`;

export const PATH_EXP_GETTRANSFEROUTREGISTRATIONSUMMARIES = `${SERVER_HOST}/allocation/recipientListing/transferOutRegistrationSummaries`;

export const PATH_EXP_UPDATETRANSFEROUTREGISTRATIONSTATUS = `${SERVER_HOST}/allocation/recipientListing/transferOutRegistrationStatus`;

export const PATH_EXP_GETALLOCATIONSUMMARIES = `${SERVER_HOST}/allocation/allocationProcess/allocationSummaries`;

export const PATH_EXP_SUBMITALLOCATIONPROCESSDETAILS = `${SERVER_HOST}/allocation/allocationProcess/allocation`;

export const PATH_EXP_TRANSFERALLOCATIONPROCESSDETAILS = `${SERVER_HOST}/allocation/allocationProcess/transfer/%s`;

export const PATH_EXP_ALLOCATERECIPIENTSDETAILS = `${SERVER_HOST}/allocation/allocationProcess/allocateRecipients/%s`;

export const PATH_EXP_NOTIFYADDITIONALRECIPIENTS = `${SERVER_HOST}/allocation/allocationProcess/notifyAdditionalRecipients`;

export const PATH_EXP_ADDROTTOSOTTORECIPIENTS = `${SERVER_HOST}/allocation/allocationProcess/newRottoSottoLevelRecipientsToAllocation`;

export const PATH_EXP_GETALLOCATION = `${SERVER_HOST}/allocation/allocationProcess/allocation/%s`;

export const PATH_EXP_SUBMITALLOCATIONRECIPIENTHOSPITALREMARKS = `${SERVER_HOST}/allocation/allocationProcess/recipientHospitalRemarks`;

export const PATH_EXP_ALLOCATEORGAN = `${SERVER_HOST}/allocation/allocationProcess/allocateOrgan`;

export const PATH_EXP_UPDATETRANSPLANTSTATUS = `${SERVER_HOST}/allocation/allocationProcess/transplantStatus`;

// KidneyOrganCall

export const PATH_EXP_GETKIDNEYORGANCALLSUMMARIES = `${SERVER_HOST}/allocation/organCall/kidneyOrganCallSummaries`;

export const PATH_EXP_GETKIDNEYORGANCALL = `${SERVER_HOST}/allocation/organCall/kidneyOrganCall/%s`;

export const PATH_EXP_UPDATEKIDNEYORGANCALLSTATUS = `${SERVER_HOST}/allocation/organCall/kidneyOrganCallStatus`;

export const PATH_EXP_UPDATEKIDNEYORGANCALLRECIPIENTSTATUS = `${SERVER_HOST}/allocation/organCall/kidneyOrganCallRecipientStatus`;

export const PATH_EXP_UPDATEKIDNEYORGANCALLTRANSPLANTSTATUS = `${SERVER_HOST}/allocation/organCall/kidneyOrganCallTransplantStatus`;

export const PATH_EXP_SUBMITKIDNEYORGANCALL = `${SERVER_HOST}/allocation/organDonor/kidneyOrganCall`;

export const PATH_EXP_SUBMITKIDNEYORGANCALLCROSSMATCHING = `${SERVER_HOST}/allocation/organCall/kidneyOrganCallCrossMatching`;

export const PATH_EXP_SUBMITKIDNEYORGANCALLMATCHING = `${SERVER_HOST}/allocation/organCall/kidneyOrganCallMatching`;

export const PATH_EXP_KIDNEYORGANCALLAPPROVETRANSPLANT = `${SERVER_HOST}/allocation/organCall/kidneyOrganCallApproveTransplant`;

export const PATH_EXP_GETDONORHOSPITALTOPRECIPIENTKIDNEYSUMMARIES = `${SERVER_HOST}/allocation/organRecipient/donorHospitalTopRecipientKidneySummaries`;

export const PATH_EXP_GETTOPRECIPIENTKIDNEYSUMMARIES = `${SERVER_HOST}/allocation/organRecipient/topRecipientKidneySummaries`;

export const PATH_EXP_GETRECIPIENTKIDNEYSUMMARIES = `${SERVER_HOST}/allocation/organRecipient/recipientKidneySummaries`;

export const PATH_EXP_GETDONORKIDNEY = `${SERVER_HOST}/allocation/organDonor/donorKidney`;

export const PATH_EXP_SUBMITDONORKIDNEY = `${SERVER_HOST}/allocation/organDonor/donorKidneyStatus`;

export const PATH_EXP_SUBMITDONORKIDNEYEDITS = `${SERVER_HOST}/allocation/organDonor/donorKidneyEdits`;

export const PATH_EXP_GETDONORKIDNEYSUMMARIES = `${SERVER_HOST}/allocation/organDonor/donorKidneySummaries`;

export const PATH_EXP_GETRECIPIENTKIDNEY = `${SERVER_HOST}/allocation/organRecipient/recipientKidney`;

export const PATH_EXP_SUBMITRECIPIENTKIDNEYEDITS = `${SERVER_HOST}/allocation/organRecipient/recipientKidneyEdits`;

export const PATH_EXP_SUBMITRECIPIENTKIDNEY = `${SERVER_HOST}/allocation/organRecipient/recipientKidneyStatus`;

// LiverOrganCall

export const PATH_EXP_GETLIVERORGANCALLSUMMARIES = `${SERVER_HOST}/allocation/organCall/liverOrganCallSummaries`;

export const PATH_EXP_GETLIVERORGANCALL = `${SERVER_HOST}/allocation/organCall/liverOrganCall/%s`;

export const PATH_EXP_UPDATELIVERORGANCALLSTATUS = `${SERVER_HOST}/allocation/organCall/liverOrganCallStatus`;

export const PATH_EXP_UPDATELIVERORGANCALLRECIPIENTSTATUS = `${SERVER_HOST}/allocation/organCall/liverOrganCallRecipientStatus`;

export const PATH_EXP_UPDATELIVERORGANCALLTRANSPLANTSTATUS = `${SERVER_HOST}/allocation/organCall/liverOrganCallTransplantStatus`;

export const PATH_EXP_SUBMITLIVERORGANCALL = `${SERVER_HOST}/allocation/organDonor/liverOrganCall`;

export const PATH_EXP_SUBMITLIVERORGANCALLCROSSMATCHING = `${SERVER_HOST}/allocation/organCall/liverOrganCallCrossMatching`;

export const PATH_EXP_SUBMITLIVERORGANCALLMATCHING = `${SERVER_HOST}/allocation/organCall/liverOrganCallMatching`;

export const PATH_EXP_LIVERORGANCALLAPPROVETRANSPLANT = `${SERVER_HOST}/allocation/organCall/liverOrganCallApproveTransplant`;

export const PATH_EXP_GETDONORHOSPITALTOPRECIPIENTLIVERSUMMARIES = `${SERVER_HOST}/allocation/organRecipient/donorHospitalTopRecipientLiverSummaries`;

export const PATH_EXP_GETTOPRECIPIENTLIVERSUMMARIES = `${SERVER_HOST}/allocation/organRecipient/topRecipientLiverSummaries`;

export const PATH_EXP_GETRECIPIENTLIVERSUMMARIES = `${SERVER_HOST}/allocation/organRecipient/recipientLiverSummaries`;

export const PATH_EXP_GETDONORLIVER = `${SERVER_HOST}/allocation/organDonor/donorLiver`;

export const PATH_EXP_SUBMITDONORLIVER = `${SERVER_HOST}/allocation/organDonor/donorLiverStatus`;

export const PATH_EXP_SUBMITDONORLIVEREDITS = `${SERVER_HOST}/allocation/organDonor/donorLiverEdits`;

export const PATH_EXP_GETDONORLIVERSUMMARIES = `${SERVER_HOST}/allocation/organDonor/donorLiverSummaries`;

export const PATH_EXP_GETRECIPIENTLIVER = `${SERVER_HOST}/allocation/organRecipient/recipientLiver`;

export const PATH_EXP_SUBMITRECIPIENTLIVEREDITS = `${SERVER_HOST}/allocation/organRecipient/recipientLiverEdits`;

export const PATH_EXP_SUBMITRECIPIENTLIVER = `${SERVER_HOST}/allocation/organRecipient/recipientLiverStatus`;

// LungsOrganCall

export const PATH_EXP_GETLUNGSORGANCALLSUMMARIES = `${SERVER_HOST}/allocation/organCall/lungsOrganCallSummaries`;

export const PATH_EXP_GETLUNGSORGANCALL = `${SERVER_HOST}/allocation/organCall/lungsOrganCall/%s`;

export const PATH_EXP_UPDATELUNGSORGANCALLSTATUS = `${SERVER_HOST}/allocation/organCall/lungsOrganCallStatus`;

export const PATH_EXP_UPDATELUNGSORGANCALLRECIPIENTSTATUS = `${SERVER_HOST}/allocation/organCall/lungsOrganCallRecipientStatus`;

export const PATH_EXP_UPDATELUNGSORGANCALLTRANSPLANTSTATUS = `${SERVER_HOST}/allocation/organCall/lungsOrganCallTransplantStatus`;

export const PATH_EXP_SUBMITLUNGSORGANCALL = `${SERVER_HOST}/allocation/organDonor/lungsOrganCall`;

export const PATH_EXP_SUBMITLUNGSORGANCALLCROSSMATCHING = `${SERVER_HOST}/allocation/organCall/lungsOrganCallCrossMatching`;

export const PATH_EXP_SUBMITLUNGSORGANCALLMATCHING = `${SERVER_HOST}/allocation/organCall/lungsOrganCallMatching`;

export const PATH_EXP_LUNGSORGANCALLAPPROVETRANSPLANT = `${SERVER_HOST}/allocation/organCall/lungsOrganCallApproveTransplant`;

export const PATH_EXP_GETDONORHOSPITALTOPRECIPIENTLUNGSSUMMARIES = `${SERVER_HOST}/allocation/organRecipient/donorHospitalTopRecipientLungsSummaries`;

export const PATH_EXP_GETTOPRECIPIENTLUNGSSUMMARIES = `${SERVER_HOST}/allocation/organRecipient/topRecipientLungsSummaries`;

export const PATH_EXP_GETRECIPIENTLUNGSSUMMARIES = `${SERVER_HOST}/allocation/organRecipient/recipientLungsSummaries`;

export const PATH_EXP_GETDONORLUNGS = `${SERVER_HOST}/allocation/organDonor/donorLungs`;

export const PATH_EXP_SUBMITDONORLUNGS = `${SERVER_HOST}/allocation/organDonor/donorLungsStatus`;

export const PATH_EXP_SUBMITDONORLUNGSEDITS = `${SERVER_HOST}/allocation/organDonor/donorLungsEdits`;

export const PATH_EXP_GETDONORLUNGSSUMMARIES = `${SERVER_HOST}/allocation/organDonor/donorLungsSummaries`;

export const PATH_EXP_GETRECIPIENTLUNGS = `${SERVER_HOST}/allocation/organRecipient/recipientLungs`;

export const PATH_EXP_SUBMITRECIPIENTLUNGSEDITS = `${SERVER_HOST}/allocation/organRecipient/recipientLungsEdits`;

export const PATH_EXP_SUBMITRECIPIENTLUNGS = `${SERVER_HOST}/allocation/organRecipient/recipientLungsStatus`;

// PancreasOrganCall

export const PATH_EXP_GETPANCREASORGANCALLSUMMARIES = `${SERVER_HOST}/allocation/organCall/pancreasOrganCallSummaries`;

export const PATH_EXP_GETPANCREASORGANCALL = `${SERVER_HOST}/allocation/organCall/pancreasOrganCall/%s`;

export const PATH_EXP_UPDATEPANCREASORGANCALLSTATUS = `${SERVER_HOST}/allocation/organCall/pancreasOrganCallStatus`;

export const PATH_EXP_UPDATEPANCREASORGANCALLRECIPIENTSTATUS = `${SERVER_HOST}/allocation/organCall/pancreasOrganCallRecipientStatus`;

export const PATH_EXP_UPDATEPANCREASORGANCALLTRANSPLANTSTATUS = `${SERVER_HOST}/allocation/organCall/pancreasOrganCallTransplantStatus`;

export const PATH_EXP_SUBMITPANCREASORGANCALL = `${SERVER_HOST}/allocation/organDonor/pancreasOrganCall`;

export const PATH_EXP_SUBMITPANCREASORGANCALLCROSSMATCHING = `${SERVER_HOST}/allocation/organCall/pancreasOrganCallCrossMatching`;

export const PATH_EXP_SUBMITPANCREASORGANCALLMATCHING = `${SERVER_HOST}/allocation/organCall/pancreasOrganCallMatching`;

export const PATH_EXP_PANCREASORGANCALLAPPROVETRANSPLANT = `${SERVER_HOST}/allocation/organCall/pancreasOrganCallApproveTransplant`;

export const PATH_EXP_GETDONORHOSPITALTOPRECIPIENTPANCREASSUMMARIES = `${SERVER_HOST}/allocation/organRecipient/donorHospitalTopRecipientPancreasSummaries`;

export const PATH_EXP_GETTOPRECIPIENTPANCREASSUMMARIES = `${SERVER_HOST}/allocation/organRecipient/topRecipientPancreasSummaries`;

export const PATH_EXP_GETRECIPIENTPANCREASSUMMARIES = `${SERVER_HOST}/allocation/organRecipient/recipientPancreasSummaries`;

export const PATH_EXP_GETDONORPANCREAS = `${SERVER_HOST}/allocation/organDonor/donorPancreas`;

export const PATH_EXP_SUBMITDONORPANCREAS = `${SERVER_HOST}/allocation/organDonor/donorPancreasStatus`;

export const PATH_EXP_SUBMITDONORPANCREASEDITS = `${SERVER_HOST}/allocation/organDonor/donorPancreasEdits`;

export const PATH_EXP_GETDONORPANCREASSUMMARIES = `${SERVER_HOST}/allocation/organDonor/donorPancreasSummaries`;

export const PATH_EXP_GETRECIPIENTPANCREAS = `${SERVER_HOST}/allocation/organRecipient/recipientPancreas`;

export const PATH_EXP_SUBMITRECIPIENTPANCREASEDITS = `${SERVER_HOST}/allocation/organRecipient/recipientPancreasEdits`;

export const PATH_EXP_SUBMITRECIPIENTPANCREAS = `${SERVER_HOST}/allocation/organRecipient/recipientPancreasStatus`;

// IntestineOrganCall

export const PATH_EXP_GETINTESTINEORGANCALLSUMMARIES = `${SERVER_HOST}/allocation/organCall/intestineOrganCallSummaries`;

export const PATH_EXP_GETINTESTINEORGANCALL = `${SERVER_HOST}/allocation/organCall/intestineOrganCall/%s`;

export const PATH_EXP_UPDATEINTESTINEORGANCALLSTATUS = `${SERVER_HOST}/allocation/organCall/intestineOrganCallStatus`;

export const PATH_EXP_UPDATEINTESTINEORGANCALLRECIPIENTSTATUS = `${SERVER_HOST}/allocation/organCall/intestineOrganCallRecipientStatus`;

export const PATH_EXP_UPDATEINTESTINEORGANCALLTRANSPLANTSTATUS = `${SERVER_HOST}/allocation/organCall/intestineOrganCallTransplantStatus`;

export const PATH_EXP_SUBMITINTESTINEORGANCALL = `${SERVER_HOST}/allocation/organDonor/intestineOrganCall`;

export const PATH_EXP_SUBMITINTESTINEORGANCALLCROSSMATCHING = `${SERVER_HOST}/allocation/organCall/intestineOrganCallCrossMatching`;

export const PATH_EXP_SUBMITINTESTINEORGANCALLMATCHING = `${SERVER_HOST}/allocation/organCall/intestineOrganCallMatching`;

export const PATH_EXP_INTESTINEORGANCALLAPPROVETRANSPLANT = `${SERVER_HOST}/allocation/organCall/intestineOrganCallApproveTransplant`;

export const PATH_EXP_GETDONORHOSPITALTOPRECIPIENTINTESTINESUMMARIES = `${SERVER_HOST}/allocation/organRecipient/donorHospitalTopRecipientIntestineSummaries`;

export const PATH_EXP_GETTOPRECIPIENTINTESTINESUMMARIES = `${SERVER_HOST}/allocation/organRecipient/topRecipientIntestineSummaries`;

export const PATH_EXP_GETRECIPIENTINTESTINESUMMARIES = `${SERVER_HOST}/allocation/organRecipient/recipientIntestineSummaries`;

export const PATH_EXP_GETDONORINTESTINE = `${SERVER_HOST}/allocation/organDonor/donorIntestine`;

export const PATH_EXP_SUBMITDONORINTESTINE = `${SERVER_HOST}/allocation/organDonor/donorIntestineStatus`;

export const PATH_EXP_SUBMITDONORINTESTINEEDITS = `${SERVER_HOST}/allocation/organDonor/donorIntestineEdits`;

export const PATH_EXP_GETDONORINTESTINESUMMARIES = `${SERVER_HOST}/allocation/organDonor/donorIntestineSummaries`;

export const PATH_EXP_GETRECIPIENTINTESTINE = `${SERVER_HOST}/allocation/organRecipient/recipientIntestine`;

export const PATH_EXP_SUBMITRECIPIENTINTESTINEEDITS = `${SERVER_HOST}/allocation/organRecipient/recipientIntestineEdits`;

export const PATH_EXP_SUBMITRECIPIENTINTESTINE = `${SERVER_HOST}/allocation/organRecipient/recipientIntestineStatus`;

// HeartLungsOrganCall

export const PATH_EXP_GETHEARTLUNGSORGANCALLSUMMARIES = `${SERVER_HOST}/allocation/organCall/heartLungsOrganCallSummaries`;

export const PATH_EXP_GETHEARTLUNGSORGANCALL = `${SERVER_HOST}/allocation/organCall/heartLungsOrganCall/%s`;

export const PATH_EXP_UPDATEHEARTLUNGSORGANCALLSTATUS = `${SERVER_HOST}/allocation/organCall/heartLungsOrganCallStatus`;

export const PATH_EXP_UPDATEHEARTLUNGSORGANCALLRECIPIENTSTATUS = `${SERVER_HOST}/allocation/organCall/heartLungsOrganCallRecipientStatus`;

export const PATH_EXP_UPDATEHEARTLUNGSORGANCALLTRANSPLANTSTATUS = `${SERVER_HOST}/allocation/organCall/heartLungsOrganCallTransplantStatus`;

export const PATH_EXP_SUBMITHEARTLUNGSORGANCALL = `${SERVER_HOST}/allocation/organDonor/heartLungsOrganCall`;

export const PATH_EXP_SUBMITHEARTLUNGSORGANCALLCROSSMATCHING = `${SERVER_HOST}/allocation/organCall/heartLungsOrganCallCrossMatching`;

export const PATH_EXP_SUBMITHEARTLUNGSORGANCALLMATCHING = `${SERVER_HOST}/allocation/organCall/heartLungsOrganCallMatching`;

export const PATH_EXP_HEARTLUNGSORGANCALLAPPROVETRANSPLANT = `${SERVER_HOST}/allocation/organCall/heartLungsOrganCallApproveTransplant`;

export const PATH_EXP_GETDONORHOSPITALTOPRECIPIENTHEARTLUNGSSUMMARIES = `${SERVER_HOST}/allocation/organRecipient/donorHospitalTopRecipientHeartLungsSummaries`;

export const PATH_EXP_GETTOPRECIPIENTHEARTLUNGSSUMMARIES = `${SERVER_HOST}/allocation/organRecipient/topRecipientHeartLungsSummaries`;

export const PATH_EXP_GETRECIPIENTHEARTLUNGSSUMMARIES = `${SERVER_HOST}/allocation/organRecipient/recipientHeartLungsSummaries`;

export const PATH_EXP_GETDONORHEARTLUNGS = `${SERVER_HOST}/allocation/organDonor/donorHeartLungs`;

export const PATH_EXP_SUBMITDONORHEARTLUNGS = `${SERVER_HOST}/allocation/organDonor/donorHeartLungsStatus`;

export const PATH_EXP_SUBMITDONORHEARTLUNGSEDITS = `${SERVER_HOST}/allocation/organDonor/donorHeartLungsEdits`;

export const PATH_EXP_GETDONORHEARTLUNGSSUMMARIES = `${SERVER_HOST}/allocation/organDonor/donorHeartLungsSummaries`;

export const PATH_EXP_GETRECIPIENTHEARTLUNGS = `${SERVER_HOST}/allocation/organRecipient/recipientHeartLungs`;

export const PATH_EXP_SUBMITRECIPIENTHEARTLUNGSEDITS = `${SERVER_HOST}/allocation/organRecipient/recipientHeartLungsEdits`;

export const PATH_EXP_SUBMITRECIPIENTHEARTLUNGS = `${SERVER_HOST}/allocation/organRecipient/recipientHeartLungsStatus`;

// HandOrganCall

export const PATH_EXP_GETHANDORGANCALLSUMMARIES = `${SERVER_HOST}/allocation/organCall/handOrganCallSummaries`;

export const PATH_EXP_GETHANDORGANCALL = `${SERVER_HOST}/allocation/organCall/handOrganCall/%s`;

export const PATH_EXP_UPDATEHANDORGANCALLSTATUS = `${SERVER_HOST}/allocation/organCall/handOrganCallStatus`;

export const PATH_EXP_UPDATEHANDORGANCALLRECIPIENTSTATUS = `${SERVER_HOST}/allocation/organCall/handOrganCallRecipientStatus`;

export const PATH_EXP_UPDATEHANDORGANCALLTRANSPLANTSTATUS = `${SERVER_HOST}/allocation/organCall/handOrganCallTransplantStatus`;

export const PATH_EXP_SUBMITHANDORGANCALL = `${SERVER_HOST}/allocation/organDonor/handOrganCall`;

export const PATH_EXP_SUBMITHANDORGANCALLCROSSMATCHING = `${SERVER_HOST}/allocation/organCall/handOrganCallCrossMatching`;

export const PATH_EXP_SUBMITHANDORGANCALLMATCHING = `${SERVER_HOST}/allocation/organCall/handOrganCallMatching`;

export const PATH_EXP_HANDORGANCALLAPPROVETRANSPLANT = `${SERVER_HOST}/allocation/organCall/handOrganCallApproveTransplant`;

export const PATH_EXP_GETDONORHOSPITALTOPRECIPIENTHANDSUMMARIES = `${SERVER_HOST}/allocation/organRecipient/donorHospitalTopRecipientHandSummaries`;

export const PATH_EXP_GETTOPRECIPIENTHANDSUMMARIES = `${SERVER_HOST}/allocation/organRecipient/topRecipientHandSummaries`;

export const PATH_EXP_GETRECIPIENTHANDSUMMARIES = `${SERVER_HOST}/allocation/organRecipient/recipientHandSummaries`;

export const PATH_EXP_GETDONORHAND = `${SERVER_HOST}/allocation/organDonor/donorHand`;

export const PATH_EXP_SUBMITDONORHAND = `${SERVER_HOST}/allocation/organDonor/donorHandStatus`;

export const PATH_EXP_SUBMITDONORHANDEDITS = `${SERVER_HOST}/allocation/organDonor/donorHandEdits`;

export const PATH_EXP_GETDONORHANDSUMMARIES = `${SERVER_HOST}/allocation/organDonor/donorHandSummaries`;

export const PATH_EXP_GETRECIPIENTHAND = `${SERVER_HOST}/allocation/organRecipient/recipientHand`;

export const PATH_EXP_SUBMITRECIPIENTHANDEDITS = `${SERVER_HOST}/allocation/organRecipient/recipientHandEdits`;

export const PATH_EXP_SUBMITRECIPIENTHAND = `${SERVER_HOST}/allocation/organRecipient/recipientHandStatus`;

// form15Ctc urls

export const PATH_EXP_FORM15CTC_GETSUMMARIES = `${SERVER_HOST}/hota/form15Ctc/form15CtcSummaries`;

export const PATH_EXP_FORM15CTC_SUBMITDRAFTFORM15CTC = `${SERVER_HOST}/hota/form15Ctc/draftForm15Ctc`;

export const PATH_EXP_FORM15CTC_GETDRAFTFORM15CTC = `${SERVER_HOST}/hota/form15Ctc/draftForm15Ctc/%s`;

export const PATH_EXP_FORM15CTC_DELETEDRAFTFORM15CTC = `${SERVER_HOST}/hota/form15Ctc/draftForm15Ctc`;

export const PATH_EXP_FORM15CTC_SUBMITFORM15CTCEDITS = `${SERVER_HOST}/hota/form15Ctc/form15CtcEdits`;

export const PATH_EXP_FORM15CTC_UPDATEFORM15CTCSTATUS = `${SERVER_HOST}/hota/form15Ctc/form15CtcStatus`;

export const PATH_EXP_FORM15CTC_SUBMITUNDERTAKING = `${SERVER_HOST}/hota/form15Ctc/form15CtcUndertaking`;

export const PATH_EXP_FORM15CTC_UPDATEFORM15CTCUDERTAKING = `${SERVER_HOST}/hota/form15Ctc/form15CtcUndertaking`;

export const PATH_EXP_FORM15CTC_GETFORM15CTC = `${SERVER_HOST}/hota/form15Ctc/form15Ctc/%s`;

export const PATH_EXP_FORM15CTC_APPROVEFORM15CTC = `${SERVER_HOST}/hota/form15Ctc/approval/form15Ctc`;

export const PATH_EXP_FORM15CTC_GETAPPROVAL = `${SERVER_HOST}/hota/form15Ctc/approval/%s`;

export const PATH_EXP_FORM15CTC_SUBMITSPECIALISTCHANGEREQUEST = `${SERVER_HOST}/hota/form15Ctc/specialistChangeRequests`;

export const PATH_EXP_FORM15CTC_UPDATESPECIALISTCHANGEREQUEST = `${SERVER_HOST}/hota/form15Ctc/specialistChangeRequests/%s`;

export const PATH_EXP_FORM15CTC_GETSPECIALISTCHANGEREQUEST = `${SERVER_HOST}/hota/form15Ctc/specialistChangeRequests/%s`;

export const PATH_EXP_FORM15CTC_ADDINSPECTIONCOMMITTEE = `${SERVER_HOST}/hota/form15Ctc/inspectionCommittee`;

export const PATH_EXP_FORM15CTC_SIGNFORM15CTC = `${SERVER_HOST}/hota/form15Ctc/signature/form15Ctc`;

export const PATH_EXP_FORM15CTC_ADDNOTING = `${SERVER_HOST}/hota/form15Ctc/noting`;

export const PATH_EXP_FORM15CTC_ADDCERTIFICATE = `${SERVER_HOST}/hota/form15Ctc/certificate`;

export const PATH_EXP_FORM15CTC_GETINSPECTION = `${SERVER_HOST}/hota/form15Ctc/inspection/%s`;

export const PATH_EXP_FORM15CTC_REVERTFORM15CTC = `${SERVER_HOST}/hota/form15Ctc/revertForm15Ctc`;

export const PATH_EXP_FORM15CTC_ADDISSUE = `${SERVER_HOST}/hota/form15Ctc/issue`;

export const PATH_EXP_FORM15CTC_UPDATEISSUESTATUS = `${SERVER_HOST}/hota/form15Ctc/issueStatus`;

export const PATH_EXP_FORM15CTC_GETISSUES = `${SERVER_HOST}/hota/form15Ctc/issues/%s`;

export const PATH_FORM15CTC_ADDINSPECTIONPERMISSION = `${SERVER_HOST}/hota/form15Ctc/inspectionPermission`;
export const PATH_FORM15CTC_ADDREGISTRATIONCERTIFICATE = `${SERVER_HOST}/hota/form15Ctc/registrationCertificate`;
export const PATH_FORM15CTC_ADDDHSLETTER = `${SERVER_HOST}/hota/form15Ctc/dhsCertificate`;
export const PATH_FORM15CTC_ADDINSPECTIONREPORT = `${SERVER_HOST}/hota/form15Ctc/inspectionReport`;

// form15Eb urls

export const PATH_EXP_FORM15EB_GETSUMMARIES = `${SERVER_HOST}/hota/form15Eb/form15EbSummaries`;

export const PATH_EXP_FORM15EB_SUBMITDRAFTFORM15EB = `${SERVER_HOST}/hota/form15Eb/draftForm15Eb`;

export const PATH_EXP_FORM15EB_GETDRAFTFORM15EB = `${SERVER_HOST}/hota/form15Eb/draftForm15Eb/%s`;

export const PATH_EXP_FORM15EB_DELETEDRAFTFORM15EB = `${SERVER_HOST}/hota/form15Eb/draftForm15Eb`;

export const PATH_EXP_FORM15EB_SUBMITFORM15EBEDITS = `${SERVER_HOST}/hota/form15Eb/form15EbEdits`;

export const PATH_EXP_FORM15EB_UPDATEFORM15EBSTATUS = `${SERVER_HOST}/hota/form15Eb/form15EbStatus`;

export const PATH_EXP_FORM15EB_SUBMITUNDERTAKING = `${SERVER_HOST}/hota/form15Eb/form15EbUndertaking`;

export const PATH_EXP_FORM15EB_UPDATEFORM15EBUDERTAKING = `${SERVER_HOST}/hota/form15Eb/form15EbUndertaking`;

export const PATH_EXP_FORM15EB_GETFORM15EB = `${SERVER_HOST}/hota/form15Eb/form15Eb/%s`;

export const PATH_EXP_FORM15EB_APPROVEFORM15EB = `${SERVER_HOST}/hota/form15Eb/approval/form15Eb`;

export const PATH_EXP_FORM15EB_GETAPPROVAL = `${SERVER_HOST}/hota/form15Eb/approval/%s`;

export const PATH_EXP_FORM15EB_SUBMITSPECIALISTCHANGEREQUEST = `${SERVER_HOST}/hota/form15Eb/specialistChangeRequests`;

export const PATH_EXP_FORM15EB_UPDATESPECIALISTCHANGEREQUEST = `${SERVER_HOST}/hota/form15Eb/specialistChangeRequests/%s`;

export const PATH_EXP_FORM15EB_GETSPECIALISTCHANGEREQUEST = `${SERVER_HOST}/hota/form15Eb/specialistChangeRequests/%s`;

export const PATH_EXP_FORM15EB_ADDINSPECTIONCOMMITTEE = `${SERVER_HOST}/hota/form15Eb/inspectionCommittee`;

export const PATH_EXP_FORM15EB_SIGNFORM15EB = `${SERVER_HOST}/hota/form15Eb/signature/form15Eb`;

export const PATH_EXP_FORM15EB_ADDNOTING = `${SERVER_HOST}/hota/form15Eb/noting`;

export const PATH_EXP_FORM15EB_ADDCERTIFICATE = `${SERVER_HOST}/hota/form15Eb/certificate`;

export const PATH_EXP_FORM15EB_GETINSPECTION = `${SERVER_HOST}/hota/form15Eb/inspection/%s`;

export const PATH_EXP_FORM15EB_REVERTFORM15EB = `${SERVER_HOST}/hota/form15Eb/revertForm15Eb`;

export const PATH_EXP_FORM15EB_ADDISSUE = `${SERVER_HOST}/hota/form15Eb/issue`;

export const PATH_EXP_FORM15EB_UPDATEISSUESTATUS = `${SERVER_HOST}/hota/form15Eb/issueStatus`;

export const PATH_EXP_FORM15EB_GETISSUES = `${SERVER_HOST}/hota/form15Eb/issues/%s`;

export const PATH_FORM15EB_ADDINSPECTIONPERMISSION = `${SERVER_HOST}/hota/form15Eb/inspectionPermission`;
export const PATH_FORM15EB_ADDREGISTRATIONCERTIFICATE = `${SERVER_HOST}/hota/form15Eb/registrationCertificate`;
export const PATH_FORM15EB_ADDDHSLETTER = `${SERVER_HOST}/hota/form15Eb/dhsCertificate`;
export const PATH_FORM15EB_ADDINSPECTIONREPORT = `${SERVER_HOST}/hota/form15Eb/inspectionReport`;

// form15Erc urls

export const PATH_EXP_FORM15ERC_GETSUMMARIES = `${SERVER_HOST}/hota/form15Erc/form15ErcSummaries`;

export const PATH_EXP_FORM15ERC_SUBMITDRAFTFORM15ERC = `${SERVER_HOST}/hota/form15Erc/draftForm15Erc`;

export const PATH_EXP_FORM15ERC_GETDRAFTFORM15ERC = `${SERVER_HOST}/hota/form15Erc/draftForm15Erc/%s`;

export const PATH_EXP_FORM15ERC_DELETEDRAFTFORM15ERC = `${SERVER_HOST}/hota/form15Erc/draftForm15Erc`;

export const PATH_EXP_FORM15ERC_SUBMITFORM15ERCEDITS = `${SERVER_HOST}/hota/form15Erc/form15ErcEdits`;

export const PATH_EXP_FORM15ERC_UPDATEFORM15ERCSTATUS = `${SERVER_HOST}/hota/form15Erc/form15ErcStatus`;

export const PATH_EXP_FORM15ERC_SUBMITUNDERTAKING = `${SERVER_HOST}/hota/form15Erc/form15ErcUndertaking`;

export const PATH_EXP_FORM15ERC_UPDATEFORM15ERCUDERTAKING = `${SERVER_HOST}/hota/form15Erc/form15ErcUndertaking`;

export const PATH_EXP_FORM15ERC_GETFORM15ERC = `${SERVER_HOST}/hota/form15Erc//form15Erc/%s`;

export const PATH_EXP_FORM15ERC_APPROVEFORM15ERC = `${SERVER_HOST}/hota/form15Erc/approval/form15Erc`;

export const PATH_EXP_FORM15ERC_GETAPPROVAL = `${SERVER_HOST}/hota/form15Erc/approval/%s`;

export const PATH_EXP_FORM15ERC_SUBMITSPECIALISTCHANGEREQUEST = `${SERVER_HOST}/hota/form15Erc/specialistChangeRequests`;

export const PATH_EXP_FORM15ERC_UPDATESPECIALISTCHANGEREQUEST = `${SERVER_HOST}/hota/form15Erc/specialistChangeRequests/%s`;

export const PATH_EXP_FORM15ERC_GETSPECIALISTCHANGEREQUEST = `${SERVER_HOST}/hota/form15Erc/specialistChangeRequests/%s`;

export const PATH_EXP_FORM15ERC_ADDINSPECTIONCOMMITTEE = `${SERVER_HOST}/hota/form15Erc/inspectionCommittee`;

export const PATH_EXP_FORM15ERC_SIGNFORM15ERC = `${SERVER_HOST}/hota/form15Erc/signature/form15Erc`;

export const PATH_EXP_FORM15ERC_ADDNOTING = `${SERVER_HOST}/hota/form15Erc/noting`;

export const PATH_EXP_FORM15ERC_ADDCERTIFICATE = `${SERVER_HOST}/hota/form15Erc/certificate`;

export const PATH_EXP_FORM15ERC_GETINSPECTION = `${SERVER_HOST}/hota/form15Erc/inspection/%s`;

export const PATH_EXP_FORM15ERC_REVERTFORM15ERC = `${SERVER_HOST}/hota/form15Erc/revertForm15Erc`;

export const PATH_EXP_FORM15ERC_ADDISSUE = `${SERVER_HOST}/hota/form15Erc/issue`;

export const PATH_EXP_FORM15ERC_UPDATEISSUESTATUS = `${SERVER_HOST}/hota/form15Erc/issueStatus`;

export const PATH_EXP_FORM15ERC_GETISSUES = `${SERVER_HOST}/hota/form15Erc/issues/%s`;

export const PATH_FORM15ERC_ADDINSPECTIONPERMISSION = `${SERVER_HOST}/hota/form15Erc/inspectionPermission`;
export const PATH_FORM15ERC_ADDREGISTRATIONCERTIFICATE = `${SERVER_HOST}/hota/form15Erc/registrationCertificate`;
export const PATH_FORM15ERC_ADDDHSLETTER = `${SERVER_HOST}/hota/form15Erc/dhsCertificate`;
export const PATH_FORM15ERC_ADDINSPECTIONREPORT = `${SERVER_HOST}/hota/form15Erc/inspectionReport`;

// eyeTransplant

export const PATH_EXP_EYETRANSPLANT_GETEYEDONORSUMMARIES = `${SERVER_HOST}/eyeTx/eyeDonorSummaries`;

export const PATH_EXP_EYETRANSPLANT_GETEYERECIPIENTSUMMARIES = `${SERVER_HOST}/eyeTx/eyeRecipientSummaries`;

export const PATH_EXP_EYETRANSPLANT_GETDRAFTEYEDONOR = `${SERVER_HOST}/eyeTx/draftEyeDonor`;

export const PATH_EXP_EYETRANSPLANT_GETEYEDONOR = `${SERVER_HOST}/eyeTx/eyeDonor`;

export const PATH_EXP_EYETRANSPLANT_GETEYEDONORS = `${SERVER_HOST}/eyeTx/eyeDonors`;

export const PATH_EXP_EYETRANSPLANT_GETDRAFTEYERECIPIENT = `${SERVER_HOST}/eyeTx/draftEyeRecipient`;

export const PATH_EXP_EYETRANSPLANT_GETEYERECIPIENT = `${SERVER_HOST}/eyeTx/eyeRecipient`;

export const PATH_EXP_EYETRANSPLANT_GETEYERECIPIENTS = `${SERVER_HOST}/eyeTx/eyeRecipients`;

export const PATH_EXP_EYETRANSPLANT_SUBMITDRAFTEYEDONOR = `${SERVER_HOST}/eyeTx/draftEyeDonor`;

export const PATH_EXP_EYETRANSPLANT_SUBMITEYEDONOREDITS = `${SERVER_HOST}/eyeTx/eyeDonorEdits`;

export const PATH_EXP_EYETRANSPLANT_SUBMITEYEDONOR = `${SERVER_HOST}/eyeTx/eyeDonor`;

export const PATH_EXP_EYETRANSPLANT_SUBMITDRAFTEYERECIPIENT = `${SERVER_HOST}/eyeTx/draftEyeRecipient`;

export const PATH_EXP_EYETRANSPLANT_SUBMITEYERECIPIENTEDITS = `${SERVER_HOST}/eyeTx/eyeRecipientEdits`;

export const PATH_EXP_EYETRANSPLANT_SUBMITEYERECIPIENT = `${SERVER_HOST}/eyeTx/eyeRecipient`;

export const PATH_EXP_EYETRANSPLANT_SUBMITDONOREVALUATION = `${SERVER_HOST}/eyeTx/donorEvaluation`;

export const PATH_EXP_EYETRANSPLANT_SUBMITDONOREVALUATIONEDITS = `${SERVER_HOST}/eyeTx/donorEvaluationEdits`;

export const PATH_EXP_EYETRANSPLANT_GETDONOREVALUATION = `${SERVER_HOST}/eyeTx/donorEvaluation`;

export const PATH_EXP_EYETRANSPLANT_SUBMITKERATOPLASTYREPORT = `${SERVER_HOST}/eyeTx/keratoplastyReport`;

export const PATH_EXP_EYETRANSPLANT_SUBMITKERATOPLASTYREPORTEDITS = `${SERVER_HOST}/eyeTx/keratoplastyReportEdits`;

export const PATH_EXP_EYETRANSPLANT_GETKERATOPLASTYREPORT = `${SERVER_HOST}/eyeTx/keratoplastyReport`;

export const PATH_EXP_EYETRANSPLANT_GETMATCHING = `${SERVER_HOST}/eyeTx/matching`;

export const PATH_EXP_EYETRANSPLANT_GETMATCHINGSUMMARIES = `${SERVER_HOST}/eyeTx/matchingSummaries`;

export const PATH_EXP_EYETRANSPLANT_SUBMITMATCHING = `${SERVER_HOST}/eyeTx/matching`;

export const PATH_EXP_EYETRANSPLANT_UPDATEMATCHING = `${SERVER_HOST}/eyeTx/matching`;

export const PATH_EXP_EYETRANSPLANT_SUBMITMATCHINGCOMMENT = `${SERVER_HOST}/eyeTx/matchingComment`;

export const PATH_EXP_EYETRANSPLANT_GETRECIPIENTDETAILSANDKERATOPLASTYREPORT = `${SERVER_HOST}/eyeTx/recipientDetailsAndKeratoplastyReport`;

export const PATH_EXP_EYETRANSPLANT_EBRPTGETSURGRPTKRTPLSTYCNTRWISEDATA = `${SERVER_HOST}/eyeTx/ebRptGetSurgRptKrtplstyCntrwiseData`;

export const PATH_EXP_EYETRANSPLANT_EBRPTINSTHOTREGNRPTDATA = `${SERVER_HOST}/eyeTx/ebRptInstHOTSRegnRptData`;

export const PATH_EXP_EYETRANSPLANT_EBRPTGETCORNEAUTILIZATIONREPORTDATA = `${SERVER_HOST}/eyeTx/ebRptGetCorneaUtilizationReportData`;

export const PATH_EXP_EYETRANSPLANT_EBRPTGETSURPLUSUTILIZATIONREPORTDATA = `${SERVER_HOST}/eyeTx/ebRptGetSurplusUtilizationReportData`;

export const PATH_EXP_EYETRANSPLANT_EBRPTGETREFUSALFORTREATMENTDATA = `${SERVER_HOST}/eyeTx/ebRptGetRefusalForTreatmentData`;

export const PATH_EXP_EYETRANSPLANT_EBRPTGETRECIPIENTWAITINGLISTDATA = `${SERVER_HOST}/eyeTx/ebRptGetRecipientWaitingListData`;

export const PATH_EXP_EYETRANSPLANT_CHANGEEYERECIPIENTSTATUS = `${SERVER_HOST}/eyeTx/changeEyeRecipientStatus`;

export const PATH_EXP_EYETRANSPLANT_GETEYERETRIEVALCENTERS = `${SERVER_HOST}/eyeTx/eyeRetrievalCenters`;

const CONTEXT_OTP = '/otp';
export const PATH_OTP_REQUESTOTP = `${SERVER_HOST}${CONTEXT_OTP}/requestOtp`;
export const PATH_OTP_REQUESTOTPFORORGANALLOCATION = `${SERVER_HOST}${CONTEXT_OTP}/requestOtpForOrganAllocation`;
export const PATH_OTP_REQUESTEMAILOTP = `${SERVER_HOST}${CONTEXT_OTP}/requestEmailOtp`;
export const PATH_OTP_VERIFYOTP = `${SERVER_HOST}${CONTEXT_OTP}`;

// KYP endpoints

export const PATH_EXP_GETKYPSUMMARIES = `${SERVER_HOST}/kyp/kypSummaries`;

export const PATH_EXP_SUBMIT_KYPDETAILS = `${SERVER_HOST}/kyp/kypDetails`;

export const PATH_EXP_GET_KYPRECORD = `${SERVER_HOST}/kyp/kypRecord/%s`;

export const PATH_EXP_SUBMIT_KYPREPORT = `${SERVER_HOST}/kyp/kypReport`;

export const PATH_EXP_GETLIVINGORGANTRANSPLANTSUMMARIES = `${SERVER_HOST}/livingOrganTransplant/livingOrganTransplantSummaries`;

export const PATH_EXP_GETLIVINGORGANTRANSPLANTRECORD = `${SERVER_HOST}/livingOrganTransplant/livingOrganTransplantRecord/%s`;

export const PATH_EXP_SUBMIT_LIVINGORGANTRANSPLANTDETAILS = `${SERVER_HOST}/livingOrganTransplant/livingOrganTransplantDetails`;

export const PATH_EXP_UPDATELIVINGORGANTRANSPLANT = `${SERVER_HOST}/livingOrganTransplant/livingOrganTransplantDetails`;

export const PATH_EXP_SUBMIT_COMMITTEEMEMBERDETAILS = `${SERVER_HOST}/livingOrganTransplant/committeeMemberDetails`;

export const PATH_EXP_GETLOTCOMMITTEEMEMBER = `${SERVER_HOST}/core/lotCommitteeMember/%s`;

export const PATH_EXP_SUBMITLOTCOMMITTEEMEMBER = `${SERVER_HOST}/core/lotCommitteeMember`;

export const PATH_EXP_GETLOTCOMMITTEEMEMBERS = `${SERVER_HOST}/core/lotCommitteeMembers`;

export const PATH_EXP_GENERATE_LIVINGORGANTRANSPLANTFORMPDFBUFFER = `${SERVER_HOST}/livingOrganTransplant/generateLotFormPdfBuffer`;

export const PATH_EXP_GENERATE_LIVINGORGANTRANSPLANTFORMPDFBUFFERFORDOWNLOAD = `${SERVER_HOST}/livingOrganTransplant/generateLotFormPdfBufferForDownload`;

export const PATH_EXP_SENDEMAIL = `${SERVER_HOST}/livingOrganTransplant/sendEmail`;

export const PATH_EXP_GETDDHSMEMBERS = `${SERVER_HOST}/core/ddhsMembers`;

export const PATH_EXP_GETDDHSMEMBER = `${SERVER_HOST}/core/ddhsMember/%s`;

export const PATH_EXP_SUBMITDDHSMEMBER = `${SERVER_HOST}/core/ddhsMember`;

export const PATH_EXP_GETSWAPTRANSPLANTSUMMARIES = `${SERVER_HOST}/swapTransplant/swapTransplantSummaries`;

export const PATH_EXP_SUBMIT_SWAPTRANSPLANT = `${SERVER_HOST}/swapTransplant/swapTransplantDetails`;

export const PATH_EXP_GET_SWAPTRANSPLANTRECORD = `${SERVER_HOST}/swapTransplant/swapTransplantRecord/%s`;

export const PATH_EXP_GENERATE_SWAPCIRCLE = `${SERVER_HOST}/swapTransplant/generateSwapCircle`;

export const PATH_EXP_UPDATE_SWAPTRANSPLANTFORMSTATUS = `${SERVER_HOST}/swapTransplant/swapTransplant`;

export const PATH_EXP_CREATE_SWAPANALYSISRECORD = `${SERVER_HOST}/swapTransplant/swapAnalysisRecord`;

export const PATH_EXP_GET_SWAPTANALYSISRECORDS = `${SERVER_HOST}/swapTransplant/swapAnalysisRecords`;

export const PATH_EXP_GET_SWAPANALYSISRECORD = `${SERVER_HOST}/swapTransplant/swapAnalysisRecord/%s`;

export const PATH_EXP_UPDATE_SWAPANALYSISRECIPIENTSTATUS = `${SERVER_HOST}/swapTransplant/swapAnalysisRecipientStatus`;

export const PATH_EXP_GETPATIENTACCESSSUMMARIES = `${SERVER_HOST}/core/patientAccessSummaries`;

export const PATH_EXP_GETPATIENTACCESSRECORD = `${SERVER_HOST}/core/patientRecord/%s`;

export const PATH_EXP_SUBMITPATIENTACCESS = `${SERVER_HOST}/core/patient`;

//Notifications Endpoints

export const PATH_EXP_CHANGENOTIFICATIONSTATUS = `${SERVER_HOST}/core/changeNotificationStatus`;

export const PATH_EXP_NOTIFICATIONS = `${SERVER_HOST}/core/notifications`;

export const PATH_EXP_MARKASREAD = `${SERVER_HOST}/core/notifications/markAsRead`;
